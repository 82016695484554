import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import { socialNetworkRequests } from "../../../api/Api"
import useStrapi4Params from "../../hooks/useStrapi4Params";


const useSocialNetworks = ({ params: initialParams } = {}) => {
  const { params, changeParams, resetParams } = useStrapi4Params(initialParams)
  const [socialNetworks, setSocialNetworks] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [params])
  
  const refresh = async () => {
    setLoading(true)
    return loadSocialNetworks(params)
      .then(([socialNetworks]) => {
        setSocialNetworks(socialNetworks)
        return socialNetworks
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    socialNetworks,
    refresh,
    isLoading,

    params,
    changeParams,
    resetParams
  }
}

export const loadSocialNetworks = async params => {
  try {
    return await socialNetworkRequests.getList(params)
  } catch (e) {
    const message = (e?.message || "SocialNetworks loading failed")
    notify.error(message)
    throw new Error(message)
  }
}


export default useSocialNetworks;