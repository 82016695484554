import { useState, useEffect } from "react";
// import { notify } from "@general-backoffice/core";

import { notify } from "@general-backoffice/core/index";

import { userRequests } from "../../../api/Api"

import handleError from "../../utils/handleError";
import {getFileId} from "../../hooks/useFileInput";


const useUser = (id, params) => {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(id) refresh(id, params).catch(() => null)
    // eslint-disable-next-line
  }, [id, !!params])


  const update = (newUser) => {
    if (!user || isLoading) return;
    setLoading(true)
    return updateUser(user)
      .finally(() =>
        setLoading(false)
      )
  }

  const refresh = async () => {
    setLoading(true)
    return loadUser(id, params)
      .then(user => {
        setUser(user)
        return user
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    user,
    setUser,
    refresh,
    update,
    isLoading
  }
}

export const loadUser = async ( id, params) => {
  try {
    return await userRequests.get(id, params)
  } catch (e) {
    const message = (e?.message || "User loading failed")
    notify.error(message)
    throw new Error(message)
  }
}


export const updateUser = async user => {
  const errMsg = `User update failed`
  if (!user) throw new Error(errMsg)
  try {

    const { id, interests, country, mediaKit, mediakitInfo, ...props } = user
    const { photos, ...mediakitProps } = mediakitInfo;

    delete props.role;
    delete props.socialNetworks;
    delete props.interests;

    const body = {
      country: country?.id || country,
      mediaKit: mediaKit && await getFileId(mediaKit),
      mediakitInfo: mediakitInfo && {
        ...mediakitProps,
        photos: photos && await Promise.all(photos.map(p => getFileId(p)))
      },
      ...props
    }

    const res = await userRequests.update(id, body)

    notify.success(`User updated correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}



export default useUser;