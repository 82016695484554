import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";

import useUsers from "./hooks/useUsers";
import { filtersPerRole } from "./utils/strapi4Params";

const normalize = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()

const UserSelect = ({ user, setUser, isMulti, role, ...props }) => {
  const { users, isLoading } = useUsers({ params: !!role && filtersPerRole[role] })

  const options = useMemo(() =>
    (users || []).map(user => ({
      value: user.id,
      label: user.name + " " + user.familyName,
      data: user
    }))
  , [users])

  const filterOptions = ({ label }, input) => normalize(label).includes(normalize(input))

  return (
    <FormInput.Select
      placeholder='Search user'
      {...props}
      onChange={isMulti ?
          options => setUser(options.map(h => h?.data))
        :
          option => setUser(option?.data || null)
      }
      value={isMulti ?
        (user && user.map(hotel => options.find(({value}) => `${value}` === `${user.id}`)))
      :
        (user && options.find(({value}) => `${value}` === `${user.id}`))
      }

      options={options}
      isDisabled={isLoading || props.isDisabled}
      filterOption={filterOptions}
      isClearable
      isMulti={isMulti}
    />

  );
}

export default UserSelect;