import { Modal } from "@general-backoffice/core";
import _ from "lodash";
import SortableFilesPreview from "../shared/SortableFilesPreview";


const SortHotelServicePhotosModal = ({ servicePhotos, setServicePhotos, submit, close, isLoading }) => {


  return (
    <Modal.Simple
      title="Sort hotel service photos"
      isOpen={!!servicePhotos}
      toggleOpen={close}
      closeText="Close"
      buttons={[{
        children: "Save order",
        color: "success",
        disabled: isLoading,
        onClick: submit
      }]}
      isLoading={isLoading}
    >
      <SortableFilesPreview
        files={(servicePhotos || []).map(({title, photo}) =>
          ({...photo, preview: photo?.url, name: title, size: photo.size*1000, type: photo.mime}))
        }
        onSort={(oldI, newI) =>
          setServicePhotos(sf => {
            const sPhotosCopy = _.cloneDeep(sf)
            sPhotosCopy.splice(newI, 0, sPhotosCopy.splice(oldI, 1)[0])
            return sPhotosCopy
          })
        }
        buttons={[
          {
            color: "primary",
            onClick: photo => window.open(photo?.url,'_newtab'),
            children: <i className="fas fa-link"/>
          }
        ]}
        disabled={isLoading}
      />

    </Modal.Simple>
  )
}

export default SortHotelServicePhotosModal