import moment from "moment";

const convertAvailabilitiesToFullCalendarEvents = (availabilities, asBackground) =>
  (availabilities || []).reduce((r, availability) => {
    const calendarEvent = convertAvailabilityToFullCalendarEvent(availability, asBackground)
    if (!!calendarEvent) r.push(calendarEvent)
    return r
  }, [])


const convertAvailabilityToFullCalendarEvent = (availability, asBackground) => {
  const { id, startDate, endDate, reservations, maxReservations } = availability
  const acceptedReservation = (reservations || []).filter(r => r.state === 'accepted').length
  const isFull = maxReservations && (acceptedReservation >= maxReservations)
  return {
    id: id,
    start: startDate,
    end: moment(endDate).add(1, 'd').format('YYYY-MM-DD'),
    extendedProps: {
      ...availability
    },
    ...(asBackground ?
        {
          display: 'background',
          className: `bg-${isFull ? "gray" : "primary"} rounded-0`,
        }
      :
        {
          title: `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`,
          className: `px-1 border-white border bg-${isFull ? "gray" : "primary"}`
        }
    ),
  }
}


const convertFullCalendarEventsToAvailabilities = (fullCalendarEvents = []) =>
  fullCalendarEvents.map(convertFullCalendarEventToAvailability)

const convertFullCalendarEventToAvailability = ({ extendedProps, start: startDate, end: endDate }) =>
  ({
    ...extendedProps,
    startDate,
    endDate: moment(endDate).subtract(1, 'd').format('YYYY-MM-DD')
  })



export {
  convertAvailabilitiesToFullCalendarEvents,
  convertAvailabilityToFullCalendarEvent,
  convertFullCalendarEventsToAvailabilities,
  convertFullCalendarEventToAvailability
}