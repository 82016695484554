import React from "react";
import { notify, Page } from "@general-backoffice/core";
import { useNavigate } from "react-router";
import {Navigate, useSearchParams} from "react-router-dom";

import { resetPassword } from "../../../api/Api";


const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()

  const token = searchParams.get('code')

  const onSubmit = async ({password, passwordConfirm}) =>
    resetPassword(token, password, passwordConfirm)
      .then(() => {
        notify.success("Password changed succesfully")
        navigate("/auth/login")
      })
      .catch(e => notify.error(e.message))



  if(!token) return <Navigate to="/" replace />
  return (
    <Page.ResetPassword
      title="Change password"
      lead="Please, input a new password"
      minLengthPassword={8}
      passwordPlaceholder="Password"
      passwordConfirmPlaceholder="Password confirmation"
      invalidPasswordFeedbackText="Input a valid password"
      invalidPasswordConfirmFeedbackText="Password confirmation doesn't match"
      submitText="Change password"
      onSubmit={onSubmit}
      // loginText="Inicia sesión"
      // onClickLogin={() => navigate("/auth/login")}
      // registerText=""
      // onClickRegister={() => navigate("/auth/register")}
    />
  );
}

export default ResetPassword;