import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import {Button, Col, Row} from "reactstrap";
import bytes from "bytes";


const SortableFilesPreview = ({ files, onSort, buttons, disabled }) => {
  return (
    <SortableList
      as="ul"
      onSortEnd={onSort}
      allowDrag={!disabled}
      lockAxis="y"
      className="dz-preview dz-preview-multiple list-group-lg list-group list-group-flush mb-3"
      draggedItemClassName="z-12k"
    >
      {files.map((file, i) =>
        <FilePreview
          key={i}
          index={i}
          preview={file?.type?.includes("image") && file?.preview}
          file={file}
          buttons={buttons}
          disabled={disabled}
        />
      )}
    </SortableList>
  )
}


const FilePreview = ({ preview, file, onClick, buttons, disabled, index }) => {
  return (
    <SortableItem>
      <li onClick={onClick} className={`item px-0 list-group-item dz-processing dz-image-preview ${onClick ? 'cursor-pointer' : ''}`}>
        <Row className="align-items-center">
          <Col xs="auto">
            <div className="avatar">
              {!!preview ?
                <img
                  alt={file.name}
                  className="avatar-img rounded"
                  data-dz-thumbnail="true"
                  src={preview}
                  style={{ height: "100%", objectFit: "cover" }}
                />
              :
                <i className="fas fa-file" />
              }
            </div>
          </Col>
          <Col className="ml--3 overflow-hidden">
            <h4 className="mb-1 overflow-hidden text-nowrap" data-dz-name="true" style={{textOverflow: 'ellipsis'}}>
              {file.name}
            </h4>
            <p className="small text-muted mb-0" data-dz-size="true">
              <strong>{bytes.format(file.size, { unitSeparator: ' ' })}</strong>
            </p>
          </Col>

          <Col xs="auto">
            {buttons.map((button, i) =>
              <Button
                key={i}
                size="sm"
                {...button}
                onClick={e => {
                  e.preventDefault()
                  button.onClick(file, index)
                }}
                disabled={disabled}
              >{button?.children}</Button>
            )}
          </Col>

        </Row>
      </li>
    </SortableItem>
  )
}


export default SortableFilesPreview;