import React, {useEffect, useMemo} from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

import {serviceRequests} from "../../../../api/Api";

import useServices from "../../../../components/services/hooks/useServices";
import useService from "../../../../components/services/hooks/useService";
import useStrapi4Pagination from "../../../../components/hooks/useStrapi4Pagination";
import useSort from "../../../../components/hooks/useSort";

// Components
import UpdateServiceModal from "../../../../components/services/UpdateServiceModal";
import DeleteServiceModal from "../../../../components/services/DeleteServiceModal";
import ServicesTableCard from "../../../../components/services/ServicesTableCard"



const Services = ({ hotel }) => {

  const {
    pagination,
    setPagination,
    goToPage,
    setPageSize,
    strapi4Params: paginationParams,
    resetPagination
  } = useStrapi4Pagination()

  const {
    sort,
    setSort,
    strapi4Params: sortParams,
    clearSort
  } = useSort()

  const defaultFilters = useMemo(() => ({
    populate: serviceRequests.defaultPopulate,
    ...(hotel && { 'filters[hotel][id][$eq]': hotel })
  }), [hotel])

  const baseFilters = { ...defaultFilters, ...paginationParams, ...sortParams }

  const {
    services,
    refresh: refreshServices,
    isLoading: isLoadingServices,
    params,
    changeParams,
    resetParams
  } = useServices({ params: baseFilters, setPagination })

  useEffect(() => {
    // This is in case the component isn't rerendered but window has changed
    resetPagination()
    clearSort()
    resetParams()
  }, [hotel])

  useEffect(() => changeParams(baseFilters), [defaultFilters, paginationParams, sortParams])


  const {
    service: serviceToUpdate,
    setService: setServiceToUpdate,
    update: updateService,
    isLoading: isUpdatingService
  } = useService()

  const {
    service: serviceToDelete,
    setService: setServiceToDelete,
    remove: deleteService,
    isLoading: isDeletingService
  } = useService()

  return (
    <React.Fragment>

      <UpdateServiceModal
        serviceToUpdate={serviceToUpdate}
        setServiceToUpdate={setServiceToUpdate}
        close={() => setServiceToUpdate(null)}
        update={() => {
          return updateService()
            .then(async () => {
              await refreshServices()
              setServiceToUpdate(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingServices || isUpdatingService}
      />

      <DeleteServiceModal
        serviceToDelete={serviceToDelete}
        close={() => setServiceToDelete(null)}
        remove={() => {
          deleteService()
            .then(async () => {
              await refreshServices()
              setServiceToDelete(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingServices || isDeletingService}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ServicesTableCard
              {...{
                pagination,
                goToPage,
                setPageSize,
                sort,
                setSort,
                params,
                changeParams
              }}
              services={services}
              setServiceToUpdate={setServiceToUpdate}
              setServiceToDelete={setServiceToDelete}
              isLoading={isLoadingServices}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Services;