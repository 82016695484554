import React, { useMemo } from "react";

import getCountries from "../utils/getCountries";
import socialNetworksIcons from "../utils/socialNetworksIcons";
import kFormatter from "../utils/kFormatter";

import logo from "../../media/branding/logo-dark-text.png";
import globe from "../../media/globe.png";
import gender from "../../media/gender.png";
import pie from "../../media/pie.png";
import moment from "moment";


const statsTexts = {
  totalFollowers: "Total Followers",
  monthlyReach: "Monthly Reach",
  monthlyImpressions: "Monthly Impressions",
  engagementRate: "Engagement Rate",
  storiesReach: "Stories Reach",
  postsReach: "Post Reach",
  videosReach: "Video/Reels Reach"
}


const Mediakit = ({ mediakit, user }) => {
  const {
    name,
    surnames,
    photos = [],
    details,
    comments
  } = mediakit || {}
  const {
    country: countryCode,
    region,
    category,
    travelCategories = [],
    socialNetworks = [],
    stats = {},
    demographic,
    // lastUpdate
  } = details || {}
  const {
    perCountry = [],
    perGender = [],
    perAge = []
  } = demographic || {}

  const country = useMemo(() =>
    countryCode && getCountries("en").find(({ name, shortCode }) => shortCode === countryCode)
  , [countryCode])

  const sortedCountries = useMemo(() =>
    perCountry.sort((a, b) => b?.percentage - a?.percentage)
  , [perCountry])
  const mainAudience = sortedCountries?.[0];

  const mainAge = useMemo(() =>
    Object.entries(perAge).reduce((r, [k, v]) => (!r || parseFloat(v) > parseFloat(perAge?.[r])) ? k : r, null)
  , [perCountry])


  return (
    <div className="text-default" style={{backgroundColor: '#ebebeb', font: 'Montserrat', letterSpacing: '.10rem', width: 1000}}>

      <div className="px-3 pt-4 d-flex align-items-start">
        <img src={logo} width={120} alt=""/>
        <div className="pt-5 mx-5 flex-fill d-flex justify-content-between text-center">
          <div className="w-50 text-uppercase text-nowrap" style={{fontSize: '3.5rem', lineHeight: 1.4 }}>
            <div>{name}</div>
            {surnames &&
              <div className="flex-center flex-wrap" style={{gap: "0 1rem"}}>
                {surnames.split(" ").map(s => <span>{s}</span>)}
              </div>
            }
          </div>
          <div className="w-50">
            <div className="text-uppercase" style={{fontSize: '1.5rem'}}>
              {region && `${region} - `}{country?.name}
            </div>
            <div className="text-uppercase mt-3" style={{fontSize: '1.3rem'}}>{category}</div>

            <div className="mt-3" style={{fontSize: '1.2rem'}}>
              {(socialNetworks || []).filter(sn => !!sn?.handle).map(({type, handle = ""}, i) =>
                <div key={i} className="mt-2">
                  <i className={`${socialNetworksIcons?.[type]} mr-2 text-dark`}
                     style={{fontSize: '1.35rem', verticalAlign: 'middle'}}/>
                  <b className="ff-serif">{handle}</b>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>

      <div className="flex-center flex-nowrap w-100 overflow-hidden my-4" style={{gap: 10}}>
        {(photos || []).map((p, i) => <img src={p?.url} style={{ height: 270 }} alt="" />)}
      </div>

      <div className="text-center px-9 my-5 text-uppercase">
        <div className="font-weight-bold text-underline mb-3 ff-serif" style={{ fontSize: "1.2rem" }}>
          TRAVEL CATEGORIES
        </div>
        <div>· {(travelCategories || []).join(" · ")} ·</div>
        <div>
          {(socialNetworks || []).map(sn => `${sn?.type}: ${kFormatter(sn?.followers)}`).join(" · ")}
        </div>
      </div>


      <div className="text-center my-5">
        <div className="font-weight-bold text-underline mb-3 ff-serif">
          SOCIAL MEDIA INSIGHT
        </div>
        <div className="borderBetweenChildren flex-center flex-wrap mx-auto" style={{gap: "2rem 0", width: 800}}>
          {Object.entries(stats || {}).map(([k, v], i) =>
            <div key={i} style={{width: 200}}>
              <div style={{fontSize: "2rem"}}>
                {k === "engagementRate" ?
                  v + "%"
                  :
                  kFormatter(v)
                }
              </div>
              <small>{statsTexts?.[k]}</small>
            </div>
          )}
          {mainAudience &&
            <div style={{width: 200}}>
              <div style={{fontSize: "2rem"}}>
                {mainAudience?.percentage}%
              </div>
              <small>
                Main audience: {getCountries("en").find(({name, shortCode}) => shortCode === mainAudience?.country)?.name}
              </small>
            </div>
          }
        </div>
      </div>

      {demographic &&
        <div className="px-5 my-5 d-flex align-items-center">
          <div className="font-weight-bold ff-serif mb-4" style={{fontSize: "1.2rem"}}>DEMOGRAPHICS</div>
          <div className="flex-fill ml-3 d-flex" style={{gap: "2rem"}}>
            <div className="flex-center">
            <img src={globe} width={100} className="mr-3" alt="globe"/>
              <div>
                {(sortedCountries || []).map((c, i) =>
                  <div key={i} className={!i && "font-weight-bold"} style={{fontSize: '.9rem'}}>
                    {c?.percentage}% {getCountries("en").find(({name, shortCode}) => shortCode === c?.country)?.name}
                  </div>
                )}
              </div>
            </div>
            <div className="flex-center">
              <img src={gender} width={40} className="mr-3" alt="gender"/>
              <div>
                {Object.entries(perGender || {}).map(([gender, p], i) =>
                  <div key={i} className={(p >= 50) && "font-weight-bold"} style={{fontSize: '.9rem'}}>
                    {p}% {gender === "male" ? "Men" : "Women"}
                  </div>
                )}
              </div>
            </div>
            <div className="flex-center">
              <img src={pie} width={40} className="mr-3" alt="pie-chart"/>
              <div>
                {Object.entries(perAge || {}).map(([age, p], i) =>
                  <div key={i} className={age === mainAge && "font-weight-bold"} style={{fontSize: '.9rem'}}>
                    <span style={{fontSize: ".7rem"}}>Ages {age}</span> {p}%
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }

      {comments &&
        <div className="px-5 my-5 d-flex">
          <div className="font-weight-bold ff-serif" style={{fontSize: "1.2rem"}}>COMMENTS</div>
          <div className="flex-fill pl-5" dangerouslySetInnerHTML={{__html: comments}}/>
        </div>
      }

      <div
        className="text-white px-5 py-2 flex-center justify-content-between"
        style={{backgroundColor: "#fad02c", fontWeight: 100}}
      >
        <div style={{fontSize: '1.7rem'}}>CONTACT</div>
        <a href="tel:+34613517800" className="text-white" style={{fontSize: '1.5rem', letterSpacing: '.1rem'}}>
          +34 613 51 78 00
        </a>
        <a href="m.monserrat@trip2post.com" className="text-white" style={{fontSize: '1.2rem'}}>
          <i className="fas fa-envelope text-default mr-2" style={{fontSize: '1.7rem', verticalAlign: 'middle'}}/>
          m.monserrat@trip2post.com
        </a>
      </div>
    </div>
  )
}

export default Mediakit;