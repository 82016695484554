import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import { servicePhotoRequests } from "../../../api/Api"

import handleError from "../../utils/handleError";
import { getFileId } from "../../hooks/useFileInput";
import {submitLocalizations} from "../../utils/localizations";


const useServicePhoto = id => {
  const [servicePhoto, setServicePhoto] = useState(null);
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    if(id) load(id).catch(() => null)
  }, [id])


  const load = async id => {
    setLoading(true)
    return loadServicePhoto(id)
      .then(([servicePhoto]) => {
        setServicePhoto(servicePhoto)
        return servicePhoto
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = () => {
    if (!servicePhoto || isLoading) return;
    setLoading(true)
    return submitServicePhoto(servicePhoto)
      .finally(() =>
        setLoading(false)
      )
  }

  const remove = async () => {
    if (!servicePhoto || isLoading) return;
    setLoading(true)
    return deleteServicePhoto(servicePhoto)
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    servicePhoto,
    setServicePhoto,
    load,
    update,
    remove,
    isLoading
  }
}


export const loadServicePhoto = async id => {
  try {
    return await servicePhotoRequests.get(id)
  } catch (e) {
    const message = (e?.message || "Service photo loading failed")
    notify.error(message)
    throw new Error(message)
  }
}


export const submitServicePhoto = async servicePhoto => {
  const isNew = !servicePhoto?.id

  const errMsg = `Service photo ${isNew ? 'creation' : 'modification'} failed`
  if (!servicePhoto) throw new Error(errMsg)
  try {
    const { id, hotel, photo, localizations, ...props } = servicePhoto

    if(!isNew) delete props.hotel

    const body = {
      hotel: hotel?.id || hotel,
      photo: await getFileId(photo),
      ...props
    }

    const [res] = isNew ?
      await servicePhotoRequests.create(body)
    :
      await servicePhotoRequests.update(id, body)

    // Update or create every localization needed
    const defaultId = isNew ? res.id : id
    await submitLocalizations(localizations, defaultId, servicePhotoRequests.update, servicePhotoRequests.localize)

    notify.success(`Service photo ${isNew ? 'created' : 'modified'} correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export const deleteServicePhoto = async servicePhoto => {
  const errMsg = "Error while deleting service photo"
  if (!servicePhoto?.id) throw new Error(errMsg)
  try {
    await servicePhotoRequests.delete(servicePhoto.id)
    notify.success("Service photo removed correctly")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}



export default useServicePhoto;