import React from "react";
import { Card } from "reactstrap";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import {useNavigate} from "react-router";

import followersRangeToString from "../reservations/utils/followersRangeToString";
import moment from "moment";

const getMainSN = sns => sns && (sns.find(sn => sn.main) || sns?.[0])


const UsersTableCard = ({ title, users = [], isLoading, role, pagination, goToPage, setPageSize, sort, setSort, params, changeParams }) => {
  const onlyInfluencer = role === 'influencer'
  const navigate = useNavigate();


  const columns = [
    // {
    //   formattedContent: ({ profilePhoto: photo, name }) => photo && <TableOutput.Image src={photo.url} alt={photo.name}/>,
    //   isTh: true
    // },
    {
      name: onlyInfluencer ? 'Influencer' : 'User',
      formattedContent: ({ name, familyName, socialNetworks }) => {
        const text = `${name}${familyName ? ` ${familyName}` : ''}`
        if(onlyInfluencer && socialNetworks) {
          const needsValidation = socialNetworks.find(sn => !(sn.verified === 'verified'))
          return <TableOutput.Status
            status={text}
            color={needsValidation ? "danger" : "white"}
          />
        } else return text
      },
      isTh: true,
      sort: d => setSort({'name': d, 'familyName': d}),
      sortStatus: sort?.name
    },
    {
      name: "Email",
      property: "email",
      sort: d => setSort('email', d),
      sortStatus: sort?.email
    },
    {
      name: "Phone`",
      property: "phone",
      sort: d => setSort('phone', d),
      sortStatus: sort?.phone
    },
    {
      name: "Registration",
      formattedContent: ({ createdAt }) => moment(createdAt).format('DD-MM-YYYY'),
      sort: d => setSort('createdAt', d),
      sortStatus: sort?.createdAt
    },
    ...(onlyInfluencer ? [
      {
        name: "Social Network",
        formattedContent: ({ socialNetworks }) => {
          const sn = getMainSN(socialNetworks);
          if(sn) {
            const snName = sn?.social_network?.name
            return <>
              {snName && <b>{snName}: </b>}
              {sn?.accountName}
            </>
          }
        }
      },
      {
        name: "Followers",
        formattedContent: ({ socialNetworks }) => {
          const range = getMainSN(socialNetworks)?.followers_range
          return range && followersRangeToString(range)
        }
      },
      {
        name: "Country",
        property: "country.name",
        sort: d => setSort('country.name', d),
        sortStatus: sort?.['country.name']
      }
    ] : [])
  ]


  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={title || "Users"}
        isLoading={isLoading}
        search={{
          value: params?.['filters[$or][0][name][$containsi]'],
          onChange: e => changeParams({
            'filters[$or][0][name][$containsi]': e.target.value,
            'filters[$or][1][familyName][$containsi]': e.target.value,
            'filters[$or][2][email][$containsi]': e.target.value,
            'filters[$or][3][socialNetworks][accountName][$containsi]': e.target.value,
            'filters[$or][4][phone][$containsi]': e.target.value
          }),
          msToChange: 800,
          placeholder: "Search..."
        }}
      />
      <Table
        columns={columns}
        rows={users}
        onRowClick={user => navigate(`/dashboard/${onlyInfluencer ? 'influencers' : 'users'}/${user.id}`)}
        isLoading={isLoading}
        noContentMessage="No users were found"
        rowHeight={81}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={setPageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default UsersTableCard;