import React from "react";
import { Modal } from "@general-backoffice/core";


const DeleteActivityModal = ({ activityToDelete, close, remove, isLoading }) => {
  return (
    <Modal.DeleteConfirm
      isOpen={!!activityToDelete}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="Delete activity"
      descriptionDelete={<div>Activity <span className="font-weight-bold">"{activityToDelete?.name}"</span> will be deleted.</div>}
      closeText="Cancel"
      buttonDelete={{ children: "Delete", onClick: remove }}
    />
  );
}

export default DeleteActivityModal;