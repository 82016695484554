import React from "react";
import { Card } from "reactstrap";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { useNavigate } from "react-router";
import _ from 'lodash'


const CountriesTableCard = ({ countries = [], setCountryToUpdate, isLoading, filters, changeFilters, pagination, goToPage, changePageSize, sort, changeSort }) => {
  const navigate = useNavigate()

  const columns = [
    {
      name: "Country",
      formattedContent: ({useAsFilter, name}) =>
        useAsFilter ? <TableOutput.Status status={name} color="primary" /> : name,
      isTh: true,
      // sort: d => changeSort('name', d),
      // sortStatus: sort?.name
    },
    {
      name: "Photo",
      formattedContent: ({name, photo}) => photo && <TableOutput.Image src={photo?.url} alt={name}/>
    },
    {
      name: "Continent",
      property: 'continent',
      sort: d => changeSort('continent', d),
      sortStatus: sort?.['continent']
    },
    {
      name: "",
      formattedContent: country =>
        <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setCountryToUpdate(_.cloneDeep(country)),
              description: "Edit country"
            },
            {
              icon: <i className="fas fa-sitemap"/>,
              onClick: () => navigate(`${country.id}/places`),
              description: "Places"
            }
          ]}
        />
    }
  ]

  return (
    <Card className="overflow-hidden position-relative">

      <TableCard.Header
        title="Countries"
        isLoading={isLoading}
        search={{
          value: filters?.['name_contains'],
          onChange: e => changeFilters('name_contains', e.target.value),
          msToChange: 800,
          placeholder: "Search..."
        }}
      />

      <Table
        columns={columns}
        rows={countries}
        onRowClick={country => setCountryToUpdate(_.cloneDeep(country))}
        isLoading={isLoading}
        noContentMessage="No countries were found"
        rowHeight={81}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default CountriesTableCard;