import { notify } from "@general-backoffice/core";
import { useStrapi4GetList } from "@libraries/strapi-4-utils";
import moment from "moment";

import { reservationRequests } from "../../../api/Api"

import reservationsStatesConfig from "../config/states";


const parseFilters = filters => {
  const parsedFiltersList = Object.entries(filters || {}).map(([key, val]) => {
    if(val === undefined) return {}
    switch(key) {
      case 'hotel':
        return { 'filters[hotel][id][$eq]': val }
      case 'status':
        return reservationsStatesConfig?.[val]?.filters
      case 'influencer':
        return { 'filters[user][id][$eq]': val }

      // Check in range filter
      case 'checkIn_from':
        return { 'filters[$and][0][check_in][$gte]': val }
      case 'checkIn_to':
        return { 'filters[$and][1][check_in][$lte]': val }
      // Check out range filter
      case 'checkOut_from':
        return { 'filters[$and][2][check_out][$gte]': val }
      case 'checkOut_to':
        return { 'filters[$and][3][check_out][$lte]': val }
      // Booked range filter
      case 'booked_from':
        return { 'filters[$and][4][createdAt][$gte]': moment(val).startOf('day').format() }
      case 'booked_to':
        return { 'filters[$and][4][createdAt][$lte]': moment(val).endOf('day').format() }

      // Companions filter
      case 'withCompanions':
        if(val === true)
          return { 'filters[companions][$ne]': 0 }
        else if(val === false)
          return {
            'filters[$or][0][companions][$lte]': 0,
            'filters[$or][1][companions][$null]': true
          }
        break;
      // Children filter
      case 'withChildren':
        if(val === true)
          return { 'filters[children][$gt]': 0 }
        else if(val === false)
          return {
            'filters[$or][2][children][$lte]': 0,
            'filters[$or][3][children][$null]': true
          }
        break;

      // sentMediakitToHotel  filter
      case 'sentMediakitToHotel':
        if(val === true)
          return { 'filters[sentMediakitToHotel][$eq]': true }
        else if(val === false)
          return {
            'filters[$or][4][sentMediakitToHotel][$ne]': true,
            'filters[$or][5][sentMediakitToHotel][$null]': true
          }
        break;
      // sentReportToHotel  filter
      case 'sentReportToHotel':
        if(val === true)
          return { 'filters[sentReportToHotel][$eq]': true }
        else if(val === false)
          return {
            'filters[$or][6][sentReportToHotel][$ne]': true,
            'filters[$or][7][sentReportToHotel][$null]': true
          }
        break;
      case 'appPhotosUpdated':
        if(val === true)
          return { 'filters[appPhotosUpdated][$eq]': true }
        else if(val === false)
          return {
            'filters[$or][6][appPhotosUpdated][$ne]': true,
            'filters[$or][7][appPhotosUpdated][$null]': true
          }
        break;

      default:
        return {[key]: val}
    }
  })
  return Object.assign({}, ...parsedFiltersList)
}

const useReservations = props => {
  const {
    items: reservations,
    setItems: setReservations,
    refresh,
    isLoading,
    ...other
  } = useStrapi4GetList({
    ...props,
    load: getReservations,
    parseFilters
  });

  return {
    reservations,
    refresh,
    isLoading,

    ...other
  }
}

export const getReservations = async params => {
  try {
    return await reservationRequests.getList(params)
  } catch (e) {
    const message = (e?.message || "Reservations loading failed")
    notify.error(message)
    throw new Error(message)
  }
}



export default useReservations;