import { useState } from "react";
import { notify } from "@general-backoffice/core";

import {advantageRequests, serviceRequests} from "../../../api/Api"

import handleError from "../../utils/handleError";
import { submitLocalizations } from "../../utils/localizations";
import {deleteAdvantage} from "../../advantages/hooks/useAdvantage";


const useService = () => {
  const [service, setService] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const update = () => {
    if (!service || isLoading) return;
    setLoading(true)
    return submitService(service)
      .finally(() =>
        setLoading(false)
      )
  }

  const remove = async () => {
    if (!service || isLoading) return;
    setLoading(true)
    return deleteService(service)
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    service,
    setService,
    update,
    remove,
    isLoading
  }
}


export const submitService = async (service) => {
  const isNew = !service?.id

  const errMsg = `Service ${isNew ? 'creation' : 'modification'} failed`
  if (!service) throw new Error(errMsg)
  try {

    const { id, localizations, ...props } = service

    // Remove fields that shouldn't be changed from here
    delete props.hotels

    const body = props

    const [res] = isNew ?
      await serviceRequests.create(body)
      :
      await serviceRequests.update(id, body)

    // Update or create every localization needed
    const defaultId = isNew ? res.id : id
    await submitLocalizations(localizations, defaultId, serviceRequests.update, serviceRequests.localize)

    notify.success(`Service ${isNew ? 'created' : 'modified'} correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const deleteService = async service => {
  const errMsg = "Error while deleting service"
  if (!service?.id) throw new Error(errMsg)
  try {
    await serviceRequests.delete(service.id)
    notify.success("Service removed correctly")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export default useService;