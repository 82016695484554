import moment from "moment";

export const stateFilterQuery = 'filters[state][$eq]'

const reservationsStatesConfig = {
  completed: {
    filters: { [stateFilterQuery]: 'completed' },
    color: 'success'
  },
  incomplete: {
    filters: { [stateFilterQuery]: 'incomplete' },
    color: 'warning',
    text: 'incomplete content'
  },
  content: {
    filters: {
      [stateFilterQuery]: 'accepted',
      'filters[check_out][$lte]': moment().format()
    },
    color: 'info',
    text: 'content upload'
  },
  accepted: {
    filters: {
      [stateFilterQuery]: 'accepted',
      'filters[check_out][$gt]': moment().format()
    },
    color: 'default'
  },
  pending: {
    filters: { [stateFilterQuery]: 'pending' },
    color: 'primary'
  },
  cancelled: {
    filters: { [stateFilterQuery]: 'cancelled' },
    color: 'danger'
  },
  denied: {
    filters: { [stateFilterQuery]: 'denied' },
    color: 'danger'
  }
}


export const getReservationState = ({ state, check_out }) => {
  if(state === 'accepted' && moment().isSameOrAfter(check_out)) return 'content'
  else return state
}

export default reservationsStatesConfig