import { saveAs } from 'file-saver';
import JSZip from "jszip";
import {getFileFromUrl} from "../hooks/useFileInput";

const downloadFilesAsZip = (files, fileName) => {
  if(!files.length) return

  let zip = new JSZip();
  files.forEach(({ name, file }) => zip.file(name, file))
  zip
    .generateAsync({type:"blob"})
    .then(content =>
      saveAs(content, `${fileName}.zip`)
    )
}


export const downloadStrapiFiles = async (strapiFiles, fileName) => {
  const files = []
  for(let {url, name} of strapiFiles)
    files.push({name, file: await getFileFromUrl(url, name)})
  downloadFilesAsZip(files, `${fileName}.zip`)
}

export default downloadFilesAsZip