import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

import { promotionRequests } from "../../../../api/Api";

import useReservation from "../../../../components/reservations/hooks/useReservation";
import useReservations from "../../../../components/reservations/hooks/useReservations";

// Components
import UpdateReservationModal from "../../../../components/reservations/UpdateReservationModal";
import ReservationsCalendarCard from "../../../../components/reservations/ReservationsCalendarCard";
import useAvailabilities from "../../../../components/availabilities/hooks/useAvailabilities";


const Reservations = ({ hotel }) => {
  const [shownPeriod, setShownPeriod] = useState()

  // const baseFilters = useMemo(() => ({
  //   populate: promotionRequests.defaultPopulate.concat([
  //     'user.socialNetworks.social_network',
  //     'user.socialNetworks.followers_range',
  //     'hotel'
  //   ]),
  //   'filters[state][$notIn]': ['cancelled', 'denied'],
  //   ...(hotel && { 'filters[hotel][id][$eq]': hotel?.id }),
  //   ...shownPeriod
  // }), [hotel])
  //
  // const {
  //   reservations,
  //   refresh: refreshReservations,
  //   isLoading: isLoadingReservations,
  //   changeParams,
  //   resetParams
  // } = useReservations({ params: baseFilters })
  //
  // // This is in case the component isn't rerendered but window has changed
  // useEffect(() => resetParams(), [hotel])
  //
  // useEffect(() => changeParams(baseFilters), [baseFilters])


  const {
    reservations,
    refresh: refreshReservations,
    isLoading: isLoadingReservations,

    // filters,
    // setFilters,
    // changeFilters,
    // goToPage,
    // changePageSize,
    // pagination,
    // sort,
    // changeSort
  } = useReservations({
    populate: promotionRequests.defaultPopulate.concat([
      'user.socialNetworks.social_network',
      'user.socialNetworks.followers_range',
      'hotel'
    ]),
    filters: {
      'filters[state][$notIn]': ['cancelled', 'denied'],
      ...(hotel && { 'filters[hotel][id][$eq]': hotel?.id }),
      ...shownPeriod
    },
    sort: { createdAt: 'down' },
    pagination: true,
    // params: baseFilters, setPagination
  })

  const {
    getDefaultReservation,
    reservation: reservationToUpdate,
    setReservation: setReservationToUpdate,
    update: updateReservation,
    validate: validateReservation,
    isLoading: isUpdatingReservation
  } = useReservation()


  const {
    availabilities,
    refresh: refreshAvailabilities,
    isLoading: isLoadingAvailabilities
  } = useAvailabilities(shownPeriod)




  return (
    <React.Fragment>

      <UpdateReservationModal
        reservationToUpdate={reservationToUpdate}
        availabilities={availabilities}
        setReservationToUpdate={setReservationToUpdate}
        validateReservation={state =>
          validateReservation(state).then(async () => {
            await refreshReservations()
            setReservationToUpdate(null)
          })
        }
        update={() =>
          updateReservation()
            .then(async () => {
              await refreshReservations()
              await refreshAvailabilities()
              await setReservationToUpdate(null)
            })
            .catch(() => null)
        }
        isLoading={isLoadingReservations || isLoadingAvailabilities || isUpdatingReservation}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ReservationsCalendarCard
              reservations={reservations}
              availabilities={availabilities}
              setReservationToUpdate={setReservationToUpdate}
              isLoading={isLoadingReservations || isLoadingAvailabilities}
              setShownPeriod={setShownPeriod}
              getDefaultReservation={getDefaultReservation}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Reservations;