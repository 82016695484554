import { useState } from "react";
import { notify } from "@general-backoffice/core";

import { notificationRequests } from "../../../api/Api"

import handleError from "../../utils/handleError";


const useNotification = () => {
  const [notification, setNotification] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const send = () => {
    if (!notification || isLoading) return;
    setLoading(true)
    return sendNotification(notification)
      .finally(() => {
        setLoading(false)
        setNotification(null)
      })
  }

  return {
    notification,
    setNotification,
    send,
    isLoading
  }
}


export const sendNotification = async notification => {
  const errMsg = `Notification sending failed`
  if (!notification) throw new Error(errMsg)
  try {
    const { notification: message, to } = notification
    if(!to?.[0] && !to)
      throw new Error('There must be atleast one receiver selected')
    if(!message.title || !message.body)
      throw new Error('Invalid notification format')

    const formattedTo = to.map(({id}) => id)

    const res = await notificationRequests.send({
      notification: message,
      to: formattedTo
    })
    notify.success(`Notification sent correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}



export default useNotification;