import React, {useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import {useNavigate, useParams} from "react-router";
import { Header } from "@general-backoffice/core/index";

import usePlaces from "../../../../components/places/hooks/usePlaces";
import usePlace from "../../../../components/places/hooks/usePlace";
import useCountry from "../../../../components/countries/hooks/useCountry";

// Components
import UpdatePlaceModal from "../../../../components/places/UpdatePlaceModal";
import DeletePlaceModal from "../../../../components/places/DeletePlaceModal";
import PlacesTableCard from "../../../../components/places/PlacesTableCard"



const Places = () => {
  const navigate = useNavigate()
  const { country: countryId, parent: parentId } = useParams();
  const { country, isLoading: isLoadingCountry } = useCountry(countryId)
  const { place: parentPlace, isLoading: isLoadingParentPlace } = usePlace(parentId)

  const {
    places,
    refresh: refreshPlaces,
    isLoading: isLoadingPlaces,
    filters,
    changeFilters,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = usePlaces({
    filters: { parent: parentId, country: countryId },
    sort: true,
    pagination: true,
    onLoadError: () => navigate('/dashboard', { replace: true }),
    depsToReset: [parentId, countryId]
  })

  const {
    place: placeToUpdate,
    setPlace: setPlaceToUpdate,
    update: updatePlace,
    isLoading: isUpdatingPlace
  } = usePlace()

  const {
    place: placeToDelete,
    setPlace: setPlaceToDelete,
    remove: deletePlace,
    isLoading: isDeletingPlace
  } = usePlace()

  if(!countryId && !parentId) navigate('/dashboard/countries', { replace: true })

  const areLoading = isLoadingCountry || isLoadingParentPlace || isLoadingPlaces || isUpdatingPlace || isDeletingPlace
  return (
    <React.Fragment>

      <UpdatePlaceModal
        placeToUpdate={placeToUpdate}
        setPlaceToUpdate={setPlaceToUpdate}
        close={() => setPlaceToUpdate(null)}
        update={() =>
          updatePlace()
            .then(async () => {
              await refreshPlaces()
              setPlaceToUpdate(null)
            })
            .catch(() => null)
        }
        isLoading={areLoading}
      />

      <DeletePlaceModal
        placeToDelete={placeToDelete}
        close={() => setPlaceToDelete(null)}
        remove={() =>
          deletePlace()
            .then(async () => {
              await refreshPlaces()
              setPlaceToDelete(null)
            })
            .catch(() => null)
        }
        isLoading={areLoading}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <PlacesTableCard
              {...{
                filters,
                changeFilters,
                pagination,
                goToPage,
                changePageSize,
                sort,
                changeSort
              }}
              places={places}
              parent={parentId ? parentPlace?.name : country?.name}
              setPlaceToUpdate={setPlaceToUpdate}
              setPlaceToDelete={setPlaceToDelete}
              isLoading={areLoading}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Places;