import React, {useEffect, useMemo} from "react";
import { Container, Row, Col } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { Header } from "@general-backoffice/core/index";

import { activityRequests } from "../../../../api/Api"
import useActivities from "../../../../components/activities/hooks/useActivities";
import useActivity from "../../../../components/activities/hooks/useActivity";
import useStrapi4Pagination from "../../../../components/hooks/useStrapi4Pagination";
import useSort from "../../../../components/hooks/useSort";

// Components
import UpdateActivityModal from "../../../../components/activities/UpdateActivityModal";
import DeleteActivityModal from "../../../../components/activities/DeleteActivityModal";
import ActivitiesTableCard from "../../../../components/activities/ActivitiesTableCard"



const Activities = ({ hotel }) => {
  // const [filters, setFilters] = useState(useSearchParams()[0])
  const {
    pagination,
    setPagination,
    goToPage,
    setPageSize,
    strapi4Params: paginationParams,
    resetPagination
  } = useStrapi4Pagination()

  const {
    sort,
    setSort,
    strapi4Params: sortParams,
    clearSort
  } = useSort()

  useEffect(() => {
    // This is in case the component isn't rerendered when window changes
    resetPagination()
    clearSort()
    resetParams()
  }, [hotel])

  const defaultFilters = useMemo(() => ({
    populate: activityRequests.defaultPopulate,
    ...(hotel && {'filters[hotels][id][$eq]': hotel?.id})
  }), [hotel])

  const baseFilters = { ...defaultFilters, ...paginationParams, ...sortParams }

  const {
    activities,
    refresh: refreshActivities,
    isLoading: isLoadingActivities,
    params,
    changeParams,
    resetParams
  } = useActivities({ params: baseFilters, setPagination })
  // eslint-disable-next-line
  useEffect(() => changeParams(baseFilters), [defaultFilters, paginationParams, sortParams])

  const {
    activity: activityToUpdate,
    setActivity: setActivityToUpdate,
    update: updateActivity,
    isLoading: isUpdatingActivity
  } = useActivity()

  const {
    activity: activityToDelete,
    setActivity: setActivityToDelete,
    remove: deleteActivity,
    isLoading: isDeletingActivity
  } = useActivity()

  return (
    <React.Fragment>

      <UpdateActivityModal
        activityToUpdate={activityToUpdate}
        setActivityToUpdate={setActivityToUpdate}
        close={() => setActivityToUpdate(null)}
        update={() => {
          return updateActivity()
            .then(async () => {
              await refreshActivities()
              setActivityToUpdate(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingActivities || isUpdatingActivity}
      />

      <DeleteActivityModal
        activityToDelete={activityToDelete}
        close={() => setActivityToDelete(null)}
        remove={() => {
          deleteActivity()
            .then(async () => {
              await refreshActivities()
              setActivityToDelete(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingActivities || isDeletingActivity}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ActivitiesTableCard
              {...{
                pagination,
                goToPage,
                setPageSize,
                sort,
                setSort,
                params,
                changeParams
              }}
              activities={activities}
              setActivityToUpdate={setActivityToUpdate}
              setActivityToDelete={setActivityToDelete}
              isLoading={isLoadingActivities}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Activities;