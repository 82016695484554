import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import { activityRequests } from "../../../api/Api"
import useStrapi4Params from "../../hooks/useStrapi4Params";


const useActivities = ({ params: initialParams, setPagination } = {}) => {
  const { params, changeParams, resetParams } = useStrapi4Params(initialParams)
  const [activities, setActivities] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [params])

  const refresh = async () => {
    setLoading(true)
    return loadActivities(params)
      .then(([activities, { pagination }]) => {
        if(setPagination) setPagination(pagination)
        setActivities(activities)
        return activities
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    activities,
    refresh,
    isLoading,

    params,
    changeParams,
    resetParams
  }
}

export const loadActivities = async params => {
  try {
    return await activityRequests.getList(params)
  } catch (e) {
    const message = (e?.message || "Activities loading failed")
    notify.error(message)
    throw new Error(message)
  }
}



export default useActivities;