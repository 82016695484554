import React, {useEffect, useMemo} from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

import { notificationRequests } from "../../../../api/Api";

import useNotifications from "../../../../components/notifications/hooks/useNotifications";
import useNotification from "../../../../components/notifications/hooks/useNotification";
import useStrapi4Pagination from "../../../../components/hooks/useStrapi4Pagination";
import useSort from "../../../../components/hooks/useSort";

// Components
import SendNotificationModal from "../../../../components/notifications/SendNotificationModal";
import NotificationsTableCard from "../../../../components/notifications/NotificationsTableCard"


const Notifications = () => {

  const {
    pagination,
    setPagination,
    goToPage,
    setPageSize,
    strapi4Params: paginationParams,
    // resetPagination
  } = useStrapi4Pagination()

  const {
    sort,
    setSort,
    strapi4Params: sortParams,
    // clearSort
  } = useSort({ sentDate: 'down' })

  const baseFilters = {
    populate: notificationRequests.defaultPopulate,
    'filters[sender][id][$gt]': 0,
    ...paginationParams,
    ...sortParams
  }

  const {
    notifications,
    refresh: refreshNotifications,
    isLoading: isLoadingNotifications,
    params,
    changeParams,
    // resetParams
  } = useNotifications({ params: baseFilters, setPagination })

  useEffect(() => changeParams(baseFilters), [paginationParams, sortParams])


  const {
    notification: notificationToSend,
    setNotification: setNotificationToSend,
    send: sendNotification,
    isLoading: isSendingNotification
  } = useNotification()

  return (
    <React.Fragment>

      <SendNotificationModal
        notificationToSend={notificationToSend}
        setNotificationToSend={setNotificationToSend}
        close={() => setNotificationToSend(null)}
        send={() => {
          return sendNotification()
            .then(async () => {
              await refreshNotifications()
              setNotificationToSend(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingNotifications || isSendingNotification}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <NotificationsTableCard
              {...{
                pagination,
                goToPage,
                setPageSize,
                sort,
                setSort,
                params,
                changeParams
              }}
              notifications={notifications}
              setNotificationToSend={setNotificationToSend}
              isLoading={isLoadingNotifications}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Notifications;