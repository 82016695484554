import React, {useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import {useNavigate, useParams} from "react-router";
import { Header } from "@general-backoffice/core/index";

import useCountries from "../../../../components/countries/hooks/useCountries";
import useCountry from "../../../../components/countries/hooks/useCountry";

// Components
import CountriesTableCard from "../../../../components/countries/CountriesTableCard";
import UpdateCountryModal from "../../../../components/countries/UpdateCountryModal";


const Countries = () => {
  const navigate = useNavigate()

  const {
    countries,
    refresh: refreshCountries,
    isLoading: isLoadingCountries,
    filters,
    changeFilters,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = useCountries({
    filters: true,
    sort: { useAsFilter: 'down' },
    pagination: true,
    onLoadError: () => navigate('/dashboard', { replace: true })
  })

  const {
    country: countryToUpdate,
    setCountry: setCountryToUpdate,
    update: updateCountry,
    isLoading: isUpdatingCountry
  } = useCountry()

  const areLoading = isLoadingCountries || isUpdatingCountry
  return (
    <React.Fragment>

      <UpdateCountryModal
        countryToUpdate={countryToUpdate}
        setCountryToUpdate={setCountryToUpdate}
        close={() => setCountryToUpdate(null)}
        update={() => {
          return updateCountry()
            .then(async () => {
              await refreshCountries()
              setCountryToUpdate(null)
            })
            .catch(() => null)
        }}
        isLoading={areLoading}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <CountriesTableCard
              {...{
                filters,
                changeFilters,
                pagination,
                goToPage,
                changePageSize,
                sort,
                changeSort
              }}
              countries={countries}
              setCountryToUpdate={setCountryToUpdate}
              isLoading={areLoading}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Countries;