import React, {useEffect, useMemo} from "react";
import { Container, Row, Col } from "reactstrap";
import {Header, notify} from "@general-backoffice/core/index";
import {useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import { setHotel as setSessionHotel } from "../../../../redux/reducers/session/actions";

import useHotel from "../../../../components/hotels/hooks/useHotel";

// Components
import HotelDetailsCard from "../../../../components/hotels/HotelDetailsCard";
import {servicePhotoRequests} from "../../../../api/Api";
import useServicePhotos from "../../../../components/service-photos/hooks/useServicePhotos";
import HotelServicePhotosCard from "../../../../components/hotels/HotelServicePhotosCard";
import useServicePhoto from "../../../../components/service-photos/hooks/useServicePhoto";
import UpdateServicePhotoModal from "../../../../components/service-photos/UpdateServicePhotoModal";
import DeleteServicePhotoModal from "../../../../components/service-photos/DeleteServicePhotoModal";

const defaultHotel = { publishedAt: null }

const Hotels = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { hotel: sessionHotel } = useSelector(({ sessionState }) => sessionState)

  const { id } = useParams()
  const {
    hotel,
    setHotel,
    load: loadHotel,
    update: updateHotel,
    isLoading
  } = useHotel(id)

  useEffect(() => {
    if(!id) setHotel({...defaultHotel})
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if(id && hotel && sessionHotel && `${sessionHotel?.id}` !== `${id}`) {
      dispatch(setSessionHotel(hotel))
      notify(`Welcome to ${hotel.name}`)
    }
  }, [id, !!hotel])




  const servicePhotosFilters = useMemo(() => {
    return({
      populate: servicePhotoRequests.defaultPopulate,
      ...(hotel && {'filters[hotel][id][$eq]': hotel?.id})
    })}, [hotel?.id])

  const {
    servicePhotos,
    setServicePhotos,
    refresh: loadServicePhotos,
    isLoading: isLoadingServicePhotos,
    changeParams: changeServicePhotosParams
  } = useServicePhotos({
    params: servicePhotosFilters,
    autoLoad: !!hotel?.id
  })

  useEffect(() => !!hotel &&
    changeServicePhotosParams(servicePhotosFilters)
  // eslint-disable-next-line
  , [servicePhotosFilters])

  const {
    servicePhoto: servicePhotoToUpdate,
    setServicePhoto: setServicePhotoToUpdate,
    update: updateServicePhoto,
    isLoading: isUpdatingServicePhoto
  } = useServicePhoto()

  const {
    servicePhoto: servicePhotoToDelete,
    setServicePhoto: setServicePhotoToDelete,
    remove: deleteServicePhoto,
    isLoading: isDeletingServicePhoto
  } = useServicePhoto()


  return <>

    <UpdateServicePhotoModal
      servicePhotoToUpdate={servicePhotoToUpdate}
      setServicePhotoToUpdate={setServicePhotoToUpdate}
      close={() => setServicePhotoToUpdate(null)}
      update={() => updateServicePhoto()
        .then(async () => {
          await loadServicePhotos()
          setServicePhotoToUpdate(null)
        })
        .catch(() => null)
      }
      isLoading={isUpdatingServicePhoto || isLoadingServicePhotos || isLoading}
    />

    <DeleteServicePhotoModal
      servicePhotoToDelete={servicePhotoToDelete}
      close={() => setServicePhotoToDelete(null)}
      remove={() => {
        deleteServicePhoto()
          .then(async () => {
            await loadServicePhotos()
            setServicePhotoToDelete(null)
          })
          .catch(() => null)
      }}
      isLoading={isDeletingServicePhoto || isLoadingServicePhotos || isLoading}
    />

    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <HotelDetailsCard
              hotel={hotel}
              setHotel={setHotel}
              updateHotel={() => updateHotel()
                .then(newHotel => {
                  if(!hotel.id) navigate(`/dashboard/hotels/${newHotel.id}`)
                  else loadHotel()
                })
              }
              isLoading={isLoading}
            />
            <HotelServicePhotosCard
              servicePhotos={servicePhotos}
              setServicePhotos={setServicePhotos}
              refreshServicePhotos={loadServicePhotos}
              hotel={hotel}
              setServicePhotoToUpdate={setServicePhotoToUpdate}
              setServicePhotoToDelete={setServicePhotoToDelete}
              isLoading={isLoading || isLoadingServicePhotos || isUpdatingServicePhoto || isDeletingServicePhoto}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  </>
}

export default Hotels;