import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { Col, Row } from "reactstrap";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";
import useLang from "../hooks/useLang";


const UpdateCategoryModal = ({ categoryToUpdate, setCategoryToUpdate, close, update, isLoading }) => {
  const { id, photo, locale, localizations } = categoryToUpdate || {}
  const { file, isLoading: isLoadingFile } = useFileInput(photo)

  const {
    lang,
    LangSelector,
    isLoading: isLoadingLang
  } = useLang({
    loaded: !!categoryToUpdate,
    locale,
    setLocale: locale => handleInputChange('locale', locale)
  })

  const localization = localizations?.[lang]
  const { name } = localization || {}

  const {
    handleInputEvent,
    handleInputChange,
    handleLocalizedInputEvent
  } = useForm(setCategoryToUpdate, lang)

  const areLoading = isLoading || isLoadingLang
  return (
    <Modal.FormContainer
      title={!!id ? `Edit ${name}` : "Create category"}
      isOpen={!!categoryToUpdate}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: isLoading }}
      isLoading={areLoading}
      onSubmit={update}
    >
      <Row>
        <Col className="pr-0">
          <FormInput.Input
            onChange={handleLocalizedInputEvent}
            value={name}
            name="name"
            label="Name"
            disabled={areLoading}
            required
          />
        </Col>
        <Col xs="auto" className="flex-center pt-1">
          <LangSelector />
        </Col>
      </Row>

      <FormInput.SingleDropzone
        label="Media"
        accept="image/*,video/*"
        file={file}
        onDrop={file => handleInputChange('photo', file)}
        buttons={[
          ...(file ? [{
            children: <i className="fas fa-trash"/>,
            color: "danger",
            onClick: () => handleInputChange('photo', null)
          }] : [])
        ]}
        disabled={isLoading || isLoadingFile}
        required
      />

    </Modal.FormContainer>
  );
}

export default UpdateCategoryModal;