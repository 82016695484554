import React from "react";
import { Modal } from "@general-backoffice/core";


const DeleteServiceModal = ({ serviceToDelete, close, remove, isLoading }) => {
  return (
    <Modal.DeleteConfirm
      isOpen={!!serviceToDelete}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="Delete service"
      descriptionDelete={<div>Service <span className="font-weight-bold">"{serviceToDelete?.name}"</span> will be deleted.</div>}
      closeText="Cancel"
      buttonDelete={{ children: "Delete", onClick: remove }}
    />
  );
}

export default DeleteServiceModal;