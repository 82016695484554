import React from "react";
import { Card } from "reactstrap";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { useNavigate } from "react-router";
import _ from 'lodash'

import AddButtonChildren from "../shared/AddButtonChildren";

const PlacesTableCard = ({ places = [], parent, setPlaceToUpdate, setPlaceToDelete, isLoading, filters, changeFilters, pagination, goToPage, changePageSize, sort, changeSort }) => {
  const navigate = useNavigate()

  const columns = [
    {
      name: "Place",
      formattedContent: ({isSearchable, name}) =>
        isSearchable ? <TableOutput.Status status={<b>{name}</b>} color="primary"/> : name,
      isTh: true,
      sort: d => changeSort('name', d),
      sortStatus: sort?.name
    },
    {
      name: "Photo",
      formattedContent: ({name, photo}) => photo && <TableOutput.Image src={photo?.url} alt={name}/>
    },
    // {
    //   name: "Coordinates",
    //   formattedContent: ({latitude, longitude}) => (latitude && longitude) && `${latitude}, ${longitude}`
    // },
    {
      name: "",
      formattedContent: place =>
        <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setPlaceToUpdate(_.cloneDeep(place)),
              description: "Edit place"
            },
            {
              icon: <i className="fas fa-sitemap"/>,
              onClick: () => navigate(`/dashboard/countries/places/${place.id}`),
              description: "Place children"
            },
            {
              icon: <i className="fas fa-trash"/>,
              onClick: () => setPlaceToDelete({ ...place }),
              description: "Delete place"
            }
          ]}
        />
    }
  ]

  return (
    <Card className="overflow-hidden position-relative">

      <TableCard.Header
        subtitle="Places from"
        title={parent}
        isLoading={isLoading}
        buttons={[
          {
            children: <AddButtonChildren children="Place"/>,
            onClick: () => setPlaceToUpdate({
              parent: { id: filters?.['filters[parent][id][$eq]'] }
            }),
            color: 'primary'
          }
        ]}
        search={{
          value: filters?.['filters[name][$containsi]'],
          onChange: e => changeFilters('filters[name][$containsi]', e.target.value),
          msToChange: 800,
          placeholder: "Search..."
        }}
      />

      <Table
        columns={columns}
        rows={places}
        onRowClick={place => setPlaceToUpdate(_.cloneDeep(place))}
        isLoading={isLoading}
        noContentMessage="No places were found"
        rowHeight={81}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default PlacesTableCard;