import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { Col, Row } from "reactstrap";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";
import useLang from "../hooks/useLang";


const UpdateServicePhotoModal = ({ servicePhotoToUpdate, setServicePhotoToUpdate, close, update, isLoading }) => {
  const { id, photo, title: defaultTitle, locale, localizations } = servicePhotoToUpdate || {}
  const { file, isLoading: isLoadingFile } = useFileInput(photo)

  const {
    lang,
    LangSelector,
    isLoading: isLoadingLang
  } = useLang({
    loaded: !!servicePhotoToUpdate,
    locale,
    setLocale: locale => handleInputChange('locale', locale)
  })

  const localization = localizations?.[lang]
  const { title } = localization || {}

  const {
    handleLocalizedInputEvent,
    handleInputChange
  } = useForm(setServicePhotoToUpdate, lang)


  const areLoading = isLoading || isLoadingLang || isLoadingFile
  return (
    <Modal.FormContainer
      title={!!id ? `Edit ${defaultTitle}` : "Create servicePhoto"}
      isOpen={!!servicePhotoToUpdate}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: areLoading }}
      isLoading={areLoading}
      onSubmit={update}
    >
      <Row>
        <Col className="pr-0">
          <FormInput.Input
            onChange={handleLocalizedInputEvent}
            value={title}
            name="title"
            label="Title"
            disabled={areLoading}
          />
        </Col>
        <Col xs="auto" className="flex-center pt-1" style={{zIndex: 1000}}>
          <LangSelector />
        </Col>
      </Row>
      
      <FormInput.SingleDropzone
        label="Media"
        accept="image/*,video/*"
        file={file}
        onDrop={file => handleInputChange('photo', file)}
        disabled={areLoading}
        required
      />

    </Modal.FormContainer>
  );
}

export default UpdateServicePhotoModal;