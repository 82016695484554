import React from "react";
import { Card } from "reactstrap";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { useNavigate } from "react-router";

import AddButtonChildren from "../shared/AddButtonChildren";
import {Icon} from "../utils/icons";


const ServicesTableCard = ({ services = [], setServiceToUpdate, setServiceToDelete, isLoading, pagination, goToPage, setPageSize, sort, setSort, params, changeParams }) => {

  const columns = [
    {
      name: "Service",
      property: 'name',
      isTh: true,
      sort: d => setSort('parent.name', d),
      sortStatus: sort?.['parent.name']
    },
    {
      name: "Icon",
      formattedContent: ({icon}) => <Icon name={icon} text={icon} className="mr-2" style={{height: 15}}/>,
      sort: d => setSort('icon', d),
      sortStatus: sort?.icon
    },
    {
      name: "",
      formattedContent: service =>
        <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setServiceToUpdate({ ...service }),
              description: "Edit service"
            },
            {
              icon: <i className="fas fa-trash"/>,
              onClick: () => setServiceToDelete({ ...service }),
              description: "Delete service"
            }
          ]}
        />
    }
  ]

  return (
    <Card className="overflow-hidden position-relative">

      <TableCard.Header
        title="Services"
        isLoading={isLoading}
        buttons={[
          {
            children: <AddButtonChildren children="Service"/>,
            onClick: () => setServiceToUpdate({}),
            color: 'primary'
          }
        ]}
        search={{
          value: params?.['filters[name][$containsi]'],
          onChange: e => changeParams('filters[name][$containsi]', e.target.value),
          msToChange: 800,
          placeholder: "Search..."
        }}
      />

      <Table
        columns={columns}
        rows={services}
        onRowClick={service => setServiceToUpdate({...service})}
        isLoading={isLoading}
        noContentMessage="No services were found"
        rowHeight={81}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={setPageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ServicesTableCard;