const environment = process.env.NODE_ENV || "production";

const getConfiguration = () => {

  switch (environment) {
    case "development":
      return {
        apiUrl: "https://api.trip2post.com/api",
        // apiUrl: "http://localhost:1337/api",
        // mediaUrl: 'http://localhost:1337',
        basename: "",
      }
    default:
      return {
        apiUrl: "https://api.trip2post.com/api",
        mediaUrl: null,
        basename: "",
      }
  }
}

export default getConfiguration();