import { useRef } from "react";
import { toCanvas, toJpeg } from "html-to-image";
import JsPDF from "jspdf";

// last edit 05/02/24

const useHtmlConverter = (name = "file") => {
  const ref = useRef(null);

  const convertToCanvas = () =>
    toCanvas(ref.current, { cacheBust: false, pixelRatio: 1.5 }).catch((err) => console.log(err));

  const convertToJpg = () =>
    toJpeg(ref.current, { cacheBust: false, quality: 1 }).catch((err) => console.log(err));

  const convertToImagePdf = (forceA4) => convertToCanvas().then((canvas) => {
    const dataUrl = canvas.toDataURL('image/jpg', 0);
    const pdf = new JsPDF({
      orientation: (canvas.width > canvas.height && !forceA4) && "landscape",
      unit: "px",
      format: forceA4 ? "a4" : [canvas.width, canvas.height]
    });

    if (forceA4)
      pdf.addImage(dataUrl, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getWidth() / (canvas.width/canvas.height));
    else
      pdf.addImage(dataUrl, 'JPEG', 0, 0, canvas.width, canvas.height);

    return pdf
  })


  const openJpg = () => convertToCanvas().then(canvas => {
    canvas.toBlob(blob => {
      const objectURL = URL.createObjectURL(blob);
      window.open(objectURL);
    }, 'image/jpg');
  })

  const downloadJpg = ({ name: name2 }) => convertToJpg().then(dataUrl => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `${name2 || name}.jpg`;
    link.click();
  })



  const openImagePdf = (props) => convertToImagePdf(props?.forceA4).then(pdf => window.open(pdf.output('bloburl')))
  const downloadImagePdf = (props) => convertToImagePdf(props?.forceA4).then(pdf => pdf.save((props?.name || name) + ".pdf"))


  return {
    ref,
    openJpg,
    downloadJpg,
    openImagePdf,
    downloadImagePdf,
  }
}


export default useHtmlConverter