import {useEffect, useState} from "react";
import {notify} from "@general-backoffice/core/index";

import { uploadFile } from "../../api/Api"


const useFileInput = inputFile => {
  const [file, setFile] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    processFile(inputFile)
      .then(setFile)
      .finally(() =>
        setIsLoading(false)
      )
  }, [inputFile?.id || inputFile?.path])

  return {
    file,
    isLoading
  }
}


export const useMultiFileInput = (inputFiles, format) => {
  // This is so it only reloads if there are actual changes
  const ids = (inputFiles || []).map(f => f?.id || f?.path).join(' ')

  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if(inputFiles?.length) {
      setIsLoading(true)
      Promise.all(inputFiles.map(f => processFile(f, format)))
        .then((images) => setFiles(images.filter(i => !!i)))
        .finally(() =>
          setIsLoading(false)
        )
    } else setFiles([])
  }, [ids])

  useEffect(() => () => {
    files.forEach(({preview}) => preview && URL.revokeObjectURL(preview))
  }, [files]);

  return {
    files,
    isLoading
  }
}


export const processFile = async (inputFile, format) => {
  if(!inputFile) return null
  else if(inputFile?.id) {
    const { url, formats, name, mime } = inputFile
    const usedVersion = format ? (formats?.[format]?.url || url) : url
    return await getFileFromUrl(usedVersion, name, mime, inputFile)
      .then(f => {
        if(f instanceof File) f.preview = f?.strapiFile?.formats?.thumbnail?.url || f?.strapiFile?.url
        return f
      })
      .catch(e => {
        notify.error('Error while loading image')
        console.log(e)
        // if(onError && typeof onError === 'function') onError() // handleInputChange('banner', null)
        return null
      })
  } else {
    if(inputFile instanceof File) inputFile.preview = URL.createObjectURL(inputFile)
    return inputFile
  }

}

export const getFileFromUrl = async (url, name, defaultType = 'image/jpeg', strapiFile) => {
  const response = await fetch(url);
  const data = await response.blob();
  const file = new File([data], name, {
    type: data.type || defaultType
  });

  if(strapiFile) file.strapiFile = strapiFile

  return file
}


export const getFileId = async image => {
  const id = image?.strapiFile?.id || image?.id
  if(!image)
    return null
  else if(id)
    return id
  else {
    const uploadedFiles = await uploadFile(image)
    return uploadedFiles[0].id
  }
}


export default useFileInput;