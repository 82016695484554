import React, {useMemo, useState} from "react";
import { Container } from "reactstrap";
import {
  SidebarContainer,
  RoutesInside,
  Footer,
  Header,
  useFinalRoutes
} from "@general-backoffice/core";
import {useDispatch, useSelector} from "react-redux";


import { logout } from "../../redux/reducers/session/actions";
import UserHotelsModal from "../../components/hotels/UserHotelsModal";
import logo from "./../../media/branding/logo-dark-squad.png"
import useUsers from "../../components/users/hooks/useUsers";
import {useNavigate} from "react-router";



const Dashboard = () => {
  const navigate = useNavigate()
  const routes = useFinalRoutes();
  const { hotel, user } = useSelector(({ sessionState }) => sessionState)
  const [isOpenSidebar, setOpenSidebar] = useState(true)
  const [isOpenHotels, setOpenHotels] = useState(false)
  const dispatch = useDispatch();

  const { users: influencers } = useUsers({
    params: {
      'filters[role][type][$eq]': 'influencer',
      "populate": 'socialNetworks',
    }
  })

  const notifications = useMemo(() => {
    const notifications = []

    if(influencers && user.role === 'Admin') {
      const toVerify = influencers.filter(i => i?.socialNetworks.find(sn => sn.verified === 'pending')).length
      if(toVerify)
        notifications.push({
          onClick: () => navigate('/dashboard/users'),
          title: `${toVerify} ${toVerify > 1 ? 'users need' : 'influencer needs'} verification`,
          description: 'You can verify their social network information from the Users tab on the sidebar clicking the corresponding users on the table.'
        })
    }

    return notifications
    // eslint-disable-next-line
  }, [influencers])

  // type NotificationProps = {
  //   onClick?: MouseEventHandler<HTMLElement>,
  //   imgSrc?: string,
  //   title: string,
  //   subtitle?: string,
  //   description: string,
  // }

  const ntCount = notifications?.length || [];
  const notificationsHeader = ntCount > 0 ? `You have ${ntCount} ${ntCount === 1 ? "notification" : "notiications"}` : "You have no notifications"


  const sessionOptions = [
    {
      title: "Hotels",
      onClick: () => setOpenHotels(true),
      icon: "fas fa-dice-d6",
      // useDivider: true
    },
    {
      title: "Logout",
      onClick: () => dispatch(logout()),
      icon: "fas fa-running",
      useDivider: true
    }
  ]

  return (
    <React.Fragment>
      <UserHotelsModal isOpen={isOpenHotels} setOpen={setOpenHotels}/>
      <Container fluid className="p-0 m-0 min-vh-100">
        <SidebarContainer
          routes={routes}
          isOpen={isOpenSidebar}
          setOpen={setOpenSidebar}
          logoSrc={logo}
        >
          <Header.Session
            isOpen={isOpenSidebar}
            setOpen={setOpenSidebar}
            sessionName={hotel?.name}
            avatarSrc={hotel?.logo?.url}
            notifications={notifications || []}
            isDark={false}
            color="secondary"
            sessionOptions={sessionOptions}
            sessionOptionsHeader="¡Bienvenido!"
            notificationsHeader={notificationsHeader}
          />
          <RoutesInside/>
          <Footer.Session/>
        </SidebarContainer>
      </Container>
    </React.Fragment>
  );
}

export default Dashboard;