import { useEffect, useCallback, useMemo, useState } from "react";

export const defaultPagination = {
  page: 1,
  pageSize: 10,
  pageCount: 1,
  total: 0
}

const usePagination = (items, initialPagination = defaultPagination) => {
  const [pagination, setPagination] = useState(initialPagination)
  const {
    page,
    pageSize,
    pageCount,
    total
  } = pagination

  const goToPage = useCallback(page => {
    if(page > 0 && page <= pageCount)
      setPagination(p => ({...p, page}))
  }, [pageCount])

  const setPageSize = useCallback(pageSize => {
    if(pageSize > 0)
      setPagination(p =>
        ({...p, pageSize, page: 1})
      )
  }, [page, pageCount, total])

  const resetPagination = () => setPagination(defaultPagination)

  useEffect(() => items && setPagination(({pageSize, page}) => {
    const pageCount = Math.ceil(items.length / pageSize) || 1
    const newPage = page > pageCount ? 1 : page
    return {
      page: newPage,
      pageSize,
      pageCount,
      total: items?.length || 0,
    }
  }), [items, pagination.pageSize])

  const pageItems = useMemo(() => {
    const { page, pageSize } = pagination
    return items ? items.slice((page-1)*pageSize, page*pageSize) : []
  }, [items, pagination])


  return {
    pageItems,
    pagination,
    setPagination,
    goToPage,
    setPageSize,
    resetPagination
  }

}

export default usePagination;