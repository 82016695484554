import React, { useMemo, useState } from "react"
import {Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

import reservationsStatesConfig from "./config/states";
import useUsers from "../users/hooks/useUsers";
import { filtersPerRole } from "../users/utils/strapi4Params";
import { FormInput } from "@general-backoffice/core/index";

// const ReservationStatusFilter = ({ status, setStatus }) => {
//   const [isPromotionsStateSelectOpen, setPromotionsStateSelectOpen] = useState(false)
//   const selectedConfig = reservationsStatesConfig?.[status]
//   return (
//     <Dropdown isOpen={isPromotionsStateSelectOpen} toggle={() => setPromotionsStateSelectOpen(s => !s)} direction="down">
//       <DropdownToggle color="white" caret className="text-capitalize flex-center form-control">
//         <Badge color="" className="badge-dot py-0">
//           <i className={`bg-${selectedConfig?.color || 'dark'}`}/>
//           <span className="text-capitalize" style={{minWidth: '3rem'}}>{selectedConfig?.text || status || 'all'}</span>
//         </Badge>
//       </DropdownToggle>
//       <DropdownMenu right>
//         <DropdownItem header>Reservation</DropdownItem>
//         <DropdownItem onClick={() => setStatus(null)}>
//           <Badge color="" className="badge-dot mr-4">
//             <i className={`bg-dark`}/>
//             <span className="text-capitalize">all</span>
//           </Badge>
//         </DropdownItem>
//         {Object.entries(reservationsStatesConfig).map(([state, { color, text }], i) =>
//           <DropdownItem key={i} onClick={() => setStatus(state)}>
//             <Badge color="" className="badge-dot mr-4">
//               <i className={`bg-${color}`}/>
//               <span className="text-capitalize">{text || state}</span>
//             </Badge>
//           </DropdownItem>
//         )}
//       </DropdownMenu>
//     </Dropdown>
//   )
// }



const ReservationStatusFilter = ({ status, setStatus, ...props }) => {
  const options = useMemo(() =>
    Object.entries(reservationsStatesConfig).map(([state, { color, text }], i) => ({
      value: state,
      label:
        <Badge color="" className="badge-dot">
          <i className={`bg-${color}`}/>
          <span className="text-capitalize">{text || state}</span>
        </Badge>
    }))
  , [])

  return (
    <FormInput.Select
      {...props}
      onChange={option => setStatus(option?.value)}
      value={status && options.find(({value}) => `${value}` === `${status}`)}

      options={options}
      isSearchable={false}
      isClearable
    />

  );
}

export default ReservationStatusFilter