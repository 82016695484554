import React, {useCallback, useState} from "react";
import {Card, CardBody, Button} from "reactstrap";
import CardHeader from "@general-backoffice/core/lib/components/card/CardHeader";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import _ from 'lodash'

import AddButtonChildren from "../shared/AddButtonChildren";
import {setHotelServicePhotos} from "../service-photos/hooks/useServicePhotos";
import SortHotelServicePhotosModal from "../service-photos/SortHotelServicePhotosModal";
import {getFileFromUrl} from "../hooks/useFileInput";
import downloadFilesAsZip from "../utils/downloadFilesAsZip";


const HotelServicePhotosCard = ({ hotel, servicePhotos, setServicePhotos, refreshServicePhotos, setServicePhotoToUpdate, setServicePhotoToDelete, isLoading: isLoadingParent }) => {
  const [isSorting, setIsSorting] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const downloadFiles = useCallback(async () => {
    const files = []
    for(let {title, photo} of servicePhotos) {
      const file = await getFileFromUrl(photo.url, title)
      files.push({name: title ? title+photo.ext : photo.name, file})
    }
    downloadFilesAsZip(files, `${hotel?.name}_servicePhotos.zip`)
  }, [servicePhotos, hotel])

  const areLoading = isLoading || isLoadingParent
  return !!hotel?.id && <>

    <SortHotelServicePhotosModal
      servicePhotos={!!isSorting && servicePhotos}
      setServicePhotos={setServicePhotos}
      close={() => setIsSorting(false)}
      submit={() => {
        setLoading(true)
        setHotelServicePhotos(hotel?.id, servicePhotos)
          .then(async () => {
            refreshServicePhotos()
            setIsSorting(false)
          })
          .catch(() => null)
          .finally(() => setLoading(false))
      }}
      isLoading={areLoading}
    />

    <Card className="overflow-hidden">
      <CardHeader
        title='Services photos'
        isLoading={areLoading}
        buttons={[
          {
            children: <i className="fas fa-download" />,
            onClick: downloadFiles
          },
          {
            children: <i className="fas fa-sort-amount-down-alt"/>,
            onClick: () => setIsSorting(true)
          },
          {
            children: <AddButtonChildren children="Add"/>,
            onClick: () => setServicePhotoToUpdate({ hotel })
          }
        ]}
      />
      <CardBody className="pt-0">
        {servicePhotos?.length ?
          <Splide hasTrack={ false }>
            <SplideTrack className="bg-dark">
              {servicePhotos.map((servicePhoto, i) =>
                <ServicePhotoSlide
                  key={i}
                  servicePhoto={servicePhoto}
                  update={() => setServicePhotoToUpdate(_.cloneDeep(servicePhoto))}
                  remove={() => setServicePhotoToDelete(_.cloneDeep(servicePhoto))}
                  isLoading={areLoading}
                />
              )}
            </SplideTrack>
          </Splide>
          :
          <div className="text-center mx-5">No service photos</div>
        }
      </CardBody>
    </Card>
  </>
}



const ServicePhotoSlide = ({ servicePhoto, update, remove, isLoading }) => {
  const { title, photo } = servicePhoto
  const { name, url, mime } = photo || {}

  return (
    <SplideSlide>
      <div style={{height: 600}} className="position-relative">
        {mime.includes('image') &&
          <img src={url} className="w-100 h-100" style={{objectFit: 'contain'}} alt={name}/>
        }
        {mime.includes('video') &&
          <video className="w-100 h-100" style={{objectFit: 'contain'}} controls>
            <source src={url} type="video/mp4"/>
          </video>
        }

        <div
          className="position-absolute w-100 d-flex justify-content-between p-3"
          style={{top: 0, background: 'linear-gradient(180deg, rgba(0,0,0,0.97) 14%, rgba(0,0,0,0.63) 57%, rgba(0,0,0,0) 100%)'}}
        >
          <span className="text-white" style={{fontSize: '1.3rem'}}>{title}</span>
          <div className="flex-center">
            <Button
              color="primary"
              className="px-2 py-1"
              onClick={e => {
                e.preventDefault()
                window.open(url,'_newtab');
              }}
            ><i className="fas fa-link"/></Button>
            <Button color="default" className="px-2 py-1" onClick={update} disabled={isLoading}>
              <i className="fas fa-edit"/> Edit
            </Button>
            <Button color="danger" className="px-2 py-1" onClick={remove} disabled={isLoading}>
              <i className="fas fa-trash"/>
            </Button>
          </div>
        </div>

      </div>
    </SplideSlide>
  )
}


export default HotelServicePhotosCard;