import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";

import useHotels from "./hooks/useHotels";

const normalize = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()

const HotelSelect = ({ hotel, setHotel, isMulti, ...props }) => {
  const { hotels, isLoading } = useHotels()

  const options = useMemo(() =>
    (hotels || []).map(hotel => ({
      value: hotel.id,
      label: hotel.name,
      data: hotel
    }))
  , [hotels])

  const filterOptions = ({ label }, input) => normalize(label).includes(normalize(input))

  return (
    <FormInput.Select
      {...props}
      placeholder='Search hotel'
      onChange={isMulti ?
          options => setHotel(options.map(h => h?.data))
        :
          option => setHotel(option?.data || null)
      }
      value={isMulti ?
        (hotel && hotel.map(hotel => options.find(({value}) => `${value}` === `${hotel.id}`)))
      :
        (hotel && options.find(({value}) => `${value}` === `${hotel.id}`))
      }

      options={options}
      isDisabled={isLoading || props.isDisabled}
      filterOption={filterOptions}
      isClearable
      isMulti={isMulti}
    />

  );
}

export default HotelSelect;