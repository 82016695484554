
import { Navigate } from "react-router";
import Places from "../../views/pages/dashboard/places"
import Countries from "../../views/pages/dashboard/countries";

const subRoutes = state => [
  {
    name: "Countries",
    path: "",
    component: <Countries />
  },
  {
    name: "Places",
    path: ":country/places",
    component: <Places />
  },
  {
    name: "Places",
    path: "places/:parent",
    component: <Places />
  },
  {
    path: "*",
    index: true,
    component: <Navigate to="/dashboard/countries" replace={true}/>,
  }
]

export default subRoutes;
