import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";

import usePlaces from "./hooks/usePlaces";
import usePlace from "./hooks/usePlace";

import UpdatePlaceModal from "./UpdatePlaceModal";

const normalize = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()


const PlaceSelect = ({ place, setPlace, isDisabled, creatable = true, ...props }) => {
  const {
    places,
    refresh,
    isLoading
  } = usePlaces({})

  const options = useMemo(() =>
    (places || []).map(place => ({
      value: place.id,
      label: place.name,
      data: place
    }))
  , [places])


  const filterOptions = ({ value, label, data }, input) =>
    value === 'create' ||
    (input ?
      normalize(label).includes(normalize(input))
    :
      !!place ?
        data?.data?.parent?.id === place.id
      :
        !data?.data?.parent
    )

  const {
    place: placeToCreate,
    setPlace: setPlaceToCreate,
    update: createPlace,
    isLoading: isCreatingPlace
  } = usePlace()

  return <>

    <UpdatePlaceModal
      placeToUpdate={placeToCreate}
      setPlaceToUpdate={setPlaceToCreate}
      close={() => setPlaceToCreate(null)}
      update={() => createPlace()
        .then(async place => {
          const places = await refresh()
          setPlace(places.find(p => p.id === place?.id) || place)
          setPlaceToCreate(null)
        })
        .catch(() => null)
      }
      isLoading={isCreatingPlace || isLoading || isDisabled}
    />

    <FormInput.Select
      label="Place"
      icon={!!creatable &&
        <i className="fas fa-plus text-default px-2 cursor-pointer" onClick={() => setPlaceToCreate({ parent: place })}/>}
      {...props}
      onChange={option => setPlace(option?.data || null)}
      options={options}
      value={place && options.find(({value}) => `${value}` === `${place.id}`)}
      isDisabled={isLoading || isDisabled}
      filterOption={filterOptions}
      isClearable
    />
  </>
}

export default PlaceSelect;