import moment from "moment";
import followersRangeToString from "./followersRangeToString";

const convertReservationsToFullCalendarEvents = reservations =>
  (reservations || []).reduce((r, reservation) => {
    const calendarEvent = convertReservationToFullCalendarEvent(reservation)
    if (!!calendarEvent) r.push(calendarEvent)
    return r
  }, [])

const colorPerState = {
  completed: 'default',
  incomplete: 'warning',
  accepted: 'success',
  pending: 'info',
  cancelled: 'gray',
  denied: 'danger',
}


const convertReservationToFullCalendarEvent = reservation => {

  console.log('reservation', reservation)
  const { id, check_in, check_out, user, state, companions } = reservation
  const mainSN = (user?.socialNetworks || []).find(sn => sn.main) || user?.socialNetworks?.[0]
  const snName = mainSN?.social_network?.name
  return {
    id: id,
    sort:
      state === "accepted" ? 1 :
      state === "pending" ? 2 :
      3,
    start: check_in,
    end: moment(check_out).add(1, 'd').format('YYYY-MM-DD'),
    title: `
      ${snName} ${followersRangeToString(mainSN?.followers_range)}
      ${companions ? `+${companions}` : ''}
    `,
    className: `px-2 border-white border bg-${colorPerState[state] || 'primary'}`
    ,
    style: { cursor: 'pointer' },
    extendedProps: {
      ...reservation
    },
  }
}


const convertFullCalendarEventsToReservations = (fullCalendarEvents = []) =>
  fullCalendarEvents.map(convertFullCalendarEventToReservation)

const convertFullCalendarEventToReservation = ({ extendedProps, start, end }) =>
  ({
    ...extendedProps,
    check_in:  moment(start).format("YYYY-MM-DD"),
    check_out: moment(end).subtract(1, 'd').format('YYYY-MM-DD')
  })



export {
  colorPerState,
  convertReservationsToFullCalendarEvents,
  convertReservationToFullCalendarEvent,
  convertFullCalendarEventsToReservations,
  convertFullCalendarEventToReservation
}