import React from "react";

const Stars = ({ num, max }) => {
  if(!num) return []
  let stars = Array(num).fill(0).map((v, i) => <i key={'star '+i} className="fas fa-star" style={{ color: "gold" }}/>)
  if(max && num < max)
    stars = stars.concat(Array(max-num).fill(0).map((v, i) => <i key={i} className="fas fa-star text-gray"/>))
  return stars
}

export default Stars