import { notify } from "@general-backoffice/core";

import { countryRequests } from "../../../api/Api"
import useStrapi4GetOne from "../../hooks/useStrapi4GetOne";

import { getFileId } from "../../hooks/useFileInput";
import { submitLocalizations } from "../../utils/localizations";
import handleError from "../../utils/handleError";


const useCountry = (id, params) => {
  const {
    item: country,
    setItem: setCountry,
    ...other // isLoading, setLoading, load, remove
  } = useStrapi4GetOne({
    id,
    params,
    submitItem: updateCountry,
    deleteItem: deleteCountry,
    loadItem: loadCountry
  })

  return {
    country,
    setCountry,
    ...other
  }
}


export const loadCountry = async (id, params) => {
  try {
    return await countryRequests.get(id, params)
  } catch (e) {
    handleError(e?.message || "Country loading failed")
  }
}

export const updateCountry = async country => {
  const errMsg = `Country update failed`
  if (!country) throw new Error(errMsg)
  try {
    const { id, photo, useAsFilter } = country

    const body = {
      useAsFilter,
      photo: await getFileId(photo)
    }

    const [res] = await countryRequests.update(id, body)
    notify.success(`Country modified correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const deleteCountry = async country => {
  const errMsg = "Error while deleting place"
  if (!country?.id) throw new Error(errMsg)
  try {
    await countryRequests.delete(country.id)
    notify.success("Country removed correctly")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}




export default useCountry;