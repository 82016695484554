import React, { useState, useMemo } from "react";
import { Table, Modal, notify } from "@general-backoffice/core";
import { useDispatch, useSelector } from "react-redux";
import { setHotel } from "../../redux/reducers/session/actions";
import {TableCard, TableOutput} from "@general-backoffice/core/index";
import AddButtonChildren from "../shared/AddButtonChildren";
import usePagination from "../hooks/usePagination";


const normalize = str => !!str && str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()


const UserHotelsModal = ({ isOpen, setOpen }) => {
  const dispatch = useDispatch()
  const { hotel, hotels = [] } = useSelector(({ sessionState }) => sessionState)

  const [filter, setFilters] = useState("")
  const filteredHotels = useMemo(() => {
    const nFilter = normalize(filter)
    if(!nFilter) return hotels
    const fHotels = hotels.filter(h => normalize(h.name).includes(nFilter))
    while(fHotels.length < 7) fHotels.push({})
    return fHotels
  }, [hotels, filter])

  const columns = [
    {
      name: "Hotel",
      formattedContent: ({ id, logo, name, publishedAt }) =>
        <div className={id === hotel?.id && "text-primary"}>
          {logo ?
            <TableOutput.ImageText
              src={logo.url}
              alt={logo.name}
              text={name}
              imageContainerClassName={!!publishedAt && "border-success"}
              imageContainerStyle={{borderWidth: 3}}
            />
          :
            name
          }
        </div>,
      isTh: true
    },
  ]

  const handleClick = newHotel => {
    if (hotel?.id !== newHotel.id) {
      dispatch(setHotel(newHotel))
      setOpen(false)
      notify(`Welcome to ${newHotel.name}`)
    }
  };


  return (
    <Modal.Simple isOpen={isOpen} toggleOpen={() => setOpen(!isOpen)} title="Hotels" closeText="Close">
      <div className="overflow-hidden mb-0 mx--4 mb--4 mt--4">

        <div className="mb--6 pb-2 mt--3">
          <TableCard.Header
            search={{
              value: filter,
              onChange: e => setFilters(e.target.value),
              placeholder: "Search..."
            }}
          />
        </div>

        <Table
          columns={columns}
          rows={filteredHotels}
          rowAmount={7}
          onRowClick={handleClick}
          noContentMessage="No hotels were found"
        />

      </div>
    </Modal.Simple>
  );
}

export default UserHotelsModal;