import { useCallback, useMemo } from "react";
import { notify } from "@general-backoffice/core";
import { useStrapi4GetList } from "@libraries/strapi-4-utils";
import moment from "moment/moment";

import { hotelRequests } from "../../../api/Api"
import useGeneralConfig from "../../hooks/useGeneralConfig";


const RESERVATION_STATE_FILTER = 'filters[reservations][state][$eq]'



const useHotels = props => {
  const { generalConfig, isLoading: isLoadingGeneralConfig } = useGeneralConfig()

  const parseFilters = useCallback(filters => {
    const parsedFiltersList = Object.entries(filters || {}).map(([key, val]) => {
      switch(key) {
        case 'reservationsStatus':
          if(val === 'content') return {
            [RESERVATION_STATE_FILTER]: 'accepted',
            'filters[reservations][check_out][$lte]': moment().format()
          }
          if(val === 'accepted') return {
            [RESERVATION_STATE_FILTER]: 'accepted',
            'filters[reservations][check_out][$gt]': moment().format()
          }
          return { [RESERVATION_STATE_FILTER]: val || undefined }
        case 'isCurrentlyAvailable':
          if(val) {
            const today = moment().add(generalConfig?.reservationMarginDays || 0, 'days').format('YYYY-MM-DD');
            return {
              // 'filters[availabilities][startDate][$lte]': today,
              'filters[availabilities][endDate][$gt]': today
            }
          }
        default:
          return {[key]: val}
      }
    })
    return Object.assign({}, ...parsedFiltersList)
  }, []);

  const {
    items: hotels,
    refresh,
    isLoading,

    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadHotels,
    parseFilters
  })

  return {
    hotels,
    refresh,
    isLoading: isLoading || isLoadingGeneralConfig,
    ...other
  }
}

export const loadHotels = async params => {
  try {
    return await hotelRequests.getList(params)
  } catch (e) {
    const message = (e?.message || "Hotels loading failed")
    notify.error(message)
    throw new Error(message)
  }
}



export default useHotels;