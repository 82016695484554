import React, { useEffect, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

import useUsers from "../../../../components/users/hooks/useUsers";
import useSort from "../../../../components/hooks/useSort";

// Components
import UsersTableCard from "../../../../components/users/UsersTableCard"
import usePagination from "../../../../components/hooks/usePagination";

import { filtersPerStatus, filtersPerRole } from "../../../../components/users/utils/strapi4Params"


const Users = ({ validation, role }) => {

  const {
    sort,
    setSort,
    strapi4Params: sortParams,
    clearSort
  } = useSort({ createdAt: 'down' })

  const defaultFilters = useMemo(() =>  ({
    "populate[country]": true,
    ...(!!role && filtersPerRole?.[role]),
    ...(!!validation && filtersPerStatus?.[validation])
  }), [validation, role])
  
  const baseFilters = { ...defaultFilters, ...sortParams }

  const {
    users,
    // refresh,
    isLoading,
    params,
    changeParams,
    resetParams
  } = useUsers({ params: baseFilters })

  useEffect(() => changeParams(baseFilters), [defaultFilters, sortParams])

  const {
    pageItems: pageUsers,
    pagination,
    // setPagination,
    goToPage,
    setPageSize,
    resetPagination
  } = usePagination(users)

  useEffect(() => {
    // This is in case the component isn't rerendered when window changes
    resetPagination()
    clearSort()
    resetParams()
  }, [validation, role])


  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <UsersTableCard
              {...{
                pagination,
                goToPage,
                setPageSize,
                sort,
                setSort,
                params,
                changeParams
              }}
              users={pageUsers}
              isLoading={isLoading}
              role={role}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Users;