import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useParams } from "react-router";

import useReservation from "../../../../components/reservations/hooks/useReservation";

// Components
import ReservationDetailsCard from "../../../../components/reservations/ReservationDetailsCard";
import {reservationRequests} from "../../../../api/Api";

const defaultReview = {}

const Reservation = () => {
  const { id } = useParams()

  const {
    reservation,
    setReservation,
    load: loadReservation,
    update: updateReservation,
    validate: validateReservation,
    isLoading
  } = useReservation(id, {
    populate: reservationRequests.defaultPopulate.concat([
      'user.mediaKit',
      'hotel.socialNetworks.social_network',
      'hotel.coupon',
      'user.socialNetworks',
      'user.mediakitInfo.photos'
    ])
  })

  useEffect(() => {
    if(!id) setReservation({...defaultReview})
    // eslint-disable-next-line
  }, [id])

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <ReservationDetailsCard
              reservation={reservation}
              setReservation={setReservation}
              updateReservation={props => updateReservation(props).then(loadReservation)}
              validateReservation={state =>
                validateReservation(state).then(loadReservation)
              }
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Reservation;