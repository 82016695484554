import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";
import { createFilter } from 'react-select';

import useServices from "./hooks/useServices";
import useService from "./hooks/useService";

import UpdateServiceModal from "./UpdateServiceModal";
import { Icon } from "../utils/icons";


const ServicesSelect = ({ services, setServices, isDisabled, creatable = true, ...props }) => {
  const {
    services: loadedServices,
    refresh,
    isLoading
  } = useServices()

  const options = useMemo(() =>
    (loadedServices || [])
      .sort((a, b) => a.name > b.name ? 1 : -1)
      .map(service => ({
        value: service.id,
        label: <Icon name={service?.icon} text={service.name} className="mr-2" style={{height: 15}}/>,
        data: service
      }))
  , [loadedServices])

  const {
    service: serviceToCreate,
    setService: setServiceToCreate,
    update: createService,
    isLoading: isCreatingService
  } = useService()

  return <>

    <UpdateServiceModal
      serviceToUpdate={serviceToCreate}
      setServiceToUpdate={setServiceToCreate}
      close={() => setServiceToCreate(null)}
      update={() => createService()
        .then(async service => {
          const refreshServices = await refresh()
          const newService = refreshServices.find(a => a.id === service?.id) || service
          setServices(services.concat([newService]))
          setServiceToCreate(null)
        })
        .catch(() => null)
      }
      isLoading={isCreatingService || isLoading || isDisabled}
    />

    <FormInput.Select
      label="Services"
      icon={!!creatable &&
        <i className="fas fa-plus text-default px-2 cursor-pointer" onClick={() => setServiceToCreate({})}/>}
      {...props}
      value={services && services.map(service => options.find(({value}) => value === service.id))}
      onChange={options =>  setServices(options.map(v => v?.data))}
      options={options}
      filterOption={createFilter({
        stringify: option => option?.data?.data?.name
      })}
      isDisabled={isLoading || isDisabled}
      isMulti
    />
  </>
}

export default ServicesSelect;