import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";

import { availabilityRequests } from "../../../api/Api"
import moment from "moment";


const useAvailabilities = (period) => {
  const hotel = useSelector(({ sessionState }) => sessionState.hotel)
  const [availabilities, setAvailabilities] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(period)
      refresh(...period).catch(() => null)
    // eslint-disable-next-line
  }, [hotel?.id, period])

  const refresh = async (start, end) => {
    setLoading(true)
    return loadAvailabilities(hotel?.id, start, end)
      .then(([availabilities]) => {
        setAvailabilities(availabilities)
        return availabilities
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    availabilities,
    refresh,
    isLoading
  }
}

export const loadAvailabilities = async (hotelId, start, end) => {
  try {
    return await availabilityRequests.getList({
      "filters[room][hotel][id][$eq]": hotelId,

      // By adding 42 days around start and end, the surrounding months' events dont pop up when view is changed
      ...(end && {"filters[$or][0][startDate][$lte]": moment(end).add(42, 'd').format('YYYY-MM-DD')}),
      ...(start && {"filters[$or][0][endDate][$gte]": moment(start).subtract(42, 'd').format('YYYY-MM-DD')})
    })
  } catch (e) {
    const message = (e?.message || "Availabilities loading failed")
    notify.error(message)
    throw new Error(message)
  }
}



export default useAvailabilities;