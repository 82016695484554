
export const filtersPerStatus = {
  verified: { 'filters[$and][0][socialNetworks][verified][$eq]': 'verified' },
  pending: { 'filters[$and][0][socialNetworks][verified][$eq]': 'pending' },
  denied: { 'filters[$and][0][socialNetworks][verified][$eq]': 'denied' }
}

export const filtersPerRole = {
  influencer: {
    "filters[role][type][$eq]": 'influencer',
    "populate[socialNetworks][populate]": 'followers_range,social_network',
  },
  authenticated: {'filters[role][type][$eq]': 'authenticated'},
}
