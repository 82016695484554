import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";
import {createFilter} from "react-select";

import useAdvantages from "./hooks/useAdvantages";
import useAdvantage from "./hooks/useAdvantage";

import UpdateAdvantageModal from "./UpdateAdvantageModal";
import { Icon } from "../utils/icons";


const AdvantagesSelect = ({ advantages, setAdvantages, isDisabled, creatable = true, ...props }) => {
  const {
    advantages: loadedAdvantages,
    refresh,
    isLoading
  } = useAdvantages()


  const options = useMemo(() =>
    (loadedAdvantages || [])
      .sort((a, b) => a.name > b.name ? 1 : -1)
      .map(advantage => ({
        value: advantage.id,
        label: <Icon name={advantage?.icon} text={advantage.name} className="mr-2" style={{height: 15}}/>,
        data: advantage
      }))
  , [loadedAdvantages])

  const {
    advantage: advantageToCreate,
    setAdvantage: setAdvantageToCreate,
    update: createAdvantage,
    isLoading: isCreatingAdvantage
  } = useAdvantage()

  return <>

    <UpdateAdvantageModal
      advantageToUpdate={advantageToCreate}
      setAdvantageToUpdate={setAdvantageToCreate}
      close={() => setAdvantageToCreate(null)}
      update={() => createAdvantage()
        .then(async advantage => {
          const refreshAdvantages = await refresh()
          const newAdvantage = refreshAdvantages.find(a => a.id === advantage?.id) || advantage
          setAdvantages(advantages.concat([newAdvantage]))
          setAdvantageToCreate(null)
        })
        .catch(() => null)
      }
      isLoading={isCreatingAdvantage || isLoading || isDisabled}
    />

    <FormInput.Select
      label="Advantages"
      icon={!!creatable &&
        <i className="fas fa-plus text-default px-2 cursor-pointer" onClick={() => setAdvantageToCreate({})} />}
      {...props}
      value={(advantages || []).map(advantage => options.find(({value}) => value === advantage.id))}
      onChange={options => setAdvantages(options.map(v => v?.data))}
      options={options}
      filterOption={createFilter({
        stringify: option => option?.data?.data?.name
      })}
      isDisabled={isLoading || isDisabled}
      isMulti
    />
  </>
}

export default AdvantagesSelect;