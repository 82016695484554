import {useMemo, useState} from "react";

const useStrapi4Params = (initialParams = {}) => {
  const [params, setParams] = useState(initialParams)

  const changeParams = (param, value) => {
    if(typeof param === 'object')
      setParams(s => ({...s, ...param}))
    else
      setParams(s => ({...s, [param]: value}))
  }

  const resetParams = () => setParams(initialParams)

  const parsedParams = useMemo(() =>
    Object.entries(params).reduce((r, [param, value]) => {
      if(value === undefined) return r
      if(typeof value === 'object' && !Array.isArray(value)) return {...r, ...value}
      else return {...r, [param]: value}
    }, {})
  , [params])


  return {
    params,
    parsedParams,
    changeParams,
    resetParams
  }

}

export default useStrapi4Params;