import React from "react";
import { Card } from "reactstrap";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";

import AddButtonChildren from "../shared/AddButtonChildren";


const CategoriesTableCard = ({ categories = [], setCategoryToUpdate, setCategoryToDelete, isLoading, pagination, goToPage, setPageSize, sort, setSort, params, changeParams  }) => {

  const columns = [
    {
      name: "Category",
      formattedContent: ({ photo, name }) => photo ? <TableOutput.ImageText src={photo.url} alt={photo.name} text={name}/> : name,
      isTh: true,
      sort: d => setSort('name', d),
      sortStatus: sort?.name
    },
    {
      name: "",
      formattedContent: category => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setCategoryToUpdate({ ...category }),
              description: "Edit category"
            },
            {
              icon: <i className="fas fa-trash"/>,
              onClick: () => setCategoryToDelete({ ...category }),
              description: "Delete category"
            }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="overflow-hidden position-relative">

      <TableCard.Header
        title="Categories"
        isLoading={isLoading}
        buttons={[
          {
            children: <AddButtonChildren children="Category"/>,
            onClick: () => setCategoryToUpdate({}),
            color: 'primary'
          }
        ]}
        search={{
          value: params?.['filters[name][$containsi]'],
          onChange: e => changeParams('filters[name][$containsi]', e.target.value),
          msToChange: 800,
          placeholder: "Search..."
        }}
      />

      <Table
        columns={columns}
        rows={categories}
        onRowClick={category => setCategoryToUpdate({...category})}
        isLoading={isLoading}
        noContentMessage="No categories were found"
        rowHeight={81}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={setPageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default CategoriesTableCard;