import React, { useEffect, useMemo, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup, Label,
} from "reactstrap";
import CardHeader from "@general-backoffice/core/lib/components/card/CardHeader";
import { FormInput, Loading, notify, TableOutput } from "@general-backoffice/core/index";
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { saveAs } from 'file-saver';
import moment from "moment";

import {activityRequests, hotelRequests} from "../../api/Api";

import useActivities from "../activities/hooks/useActivities";
import useActivity from "../activities/hooks/useActivity";

import UpdateActivityModal from "../activities/UpdateActivityModal";
import Stars from "../shared/Stars"
import useHotel from "../hotels/hooks/useHotel";
import handleError from "../utils/handleError";
import reservationsStatesConfig, {getReservationState} from "./config/states";
import {downloadStrapiFiles} from "../utils/downloadFilesAsZip";
import ReservationObligations from "../templates/ReservationObligations";

import useForm from "../hooks/useForm";
import useHtmlConverter from "../hooks/useHtmlConverter";
import Mediakit from "../templates/Mediakit";


const ReservationDetailsCard = ({ reservation, updateReservation, setReservation, validateReservation, isLoading }) => {
  const { user: sessionUser } = useSelector(({ sessionState }) => sessionState)
  const {
    id,
    check_in,
    check_out,
    user,
    hotel,
    state,
    companions,
    children,
    obligations,
    review,
    // nights,
    number,
    sentMediakitToHotel,
    sentReportToHotel,
    appPhotosUpdated,
    adminComment
  } = reservation || {}

  const {
    handleInputChange
  } = useForm(setReservation)

  const { ref: obligationsPdf, downloadImagePdf: downloadPdf } = useHtmlConverter(`obligations_reservation_${number}`)
  const { ref: mediakitRef, downloadImagePdf: downloadMediakit } = useHtmlConverter(`mediakit_${user?.email}`)

  const {
    // id,
    comment,
    published_content_photos,
    stats_photo,
    rate,
    updatedAt,
  } = review || {}

  const parsedObligations = JSON.parse(obligations || "{}") || {}
  const {
    stories = 0,
    reels = 0,
    posts = 0
  } = parsedObligations

  let buttons = []
  if(reservation) {
    const curState = getReservationState(reservation)
    if (['content', 'incomplete'].includes(curState))
      buttons.push({
        children: 'Complete',
        color: "success",
        onClick: () => validateReservation('completed')
      })
    if (['content', 'completed'].includes(curState))
      buttons.push({
        children: 'Incomplete',
        color: "warning",
        onClick: () => validateReservation('incomplete')
      })
    if (['pending', 'denied'].includes(curState))
      buttons.push({
        children: 'Accept',
        color: "success",
        onClick: () => validateReservation('accepted')
      })
    if (['pending', 'accepted'].includes(curState))
      buttons.push({
        children: 'Deny',
        color: "danger",
        onClick: () => validateReservation('denied')
      })
  }


  // This is to sort if a photo has been used for an activity
  // const contentIds = (published_content_photos || []).map(p => p?.id)
  const defaultFilters = useMemo(() => ({
    populate: activityRequests.defaultPopulate, // TODO: here there should be more filters
    // 'filters[$or][0][video][video][id][$in]': contentIds,
    // 'filters[$or][1][photo][id][$in]': contentIds,
    // 'filters[photo][id][$eq]': 669,
  }), [reservation])
  const {
    activities,
    refresh: refreshActivities,
    isLoading: isLoadingActivities,
    changeParams
  } = useActivities({ params: defaultFilters })
  // eslint-disable-next-line
  useEffect(() => changeParams(defaultFilters), [defaultFilters])

  const {
    hotel: fullHotel,
    load: refreshHotel,
  } = useHotel(hotel?.id)
  const hotelPhotos = (fullHotel?.photos || []).map(p => p?.id)

  const addToHotel = async media => {
    try {
      await hotelRequests.update(hotel.id, { photos: [...hotelPhotos, media?.id] })
      notify.success(`Media added to hotel successfully`)
    } catch (e) {
      handleError(e?.message || `Error adding media to hotel`)
    }
    await refreshHotel(hotel?.id)
  }


  const {
    activity: activityToUpdate,
    setActivity: setActivityToUpdate,
    update: updateActivity,
    isLoading: isUpdatingActivity
  } = useActivity()


  const { text, color } = reservationsStatesConfig?.[getReservationState({state, check_out})] || {}
  return <>
    <UpdateActivityModal
      activityToUpdate={activityToUpdate}
      setActivityToUpdate={setActivityToUpdate}
      close={() => setActivityToUpdate(null)}
      update={() => {
        return updateActivity()
          .then(async () => {
            await refreshActivities()
            setActivityToUpdate(null)
          })
          .catch(() => null)
      }}
      isLoading={isUpdatingActivity || isLoading || isLoadingActivities}
    />

    <Card className="overflow-hidden">
      <CardHeader
        title={[
          user && `${user?.name} ${user?.familyName} (${number})`,
          user && <TableOutput.Status status={text || state || 'all'} color={color || 'dark'}/>
        ]}
        subtitle={moment(updatedAt).format('DD-MM-YYYY')}
        buttons={buttons}
        isLoading={isLoading}
        children={<>
          <div className="mb--4 px-2">
            <FormInput.Switch
              onChange={({target: {checked}}) => updateReservation({id, sentMediakitToHotel: checked})}
              checked={!!sentMediakitToHotel}
              name="sentMediakitToHotel"
              label="Mediakit sent"
              disabled={isLoading}
            />
          </div>
          <div className="mb--4 px-2">
            <FormInput.Switch
              onChange={({target: {checked}}) => updateReservation({id, sentReportToHotel: checked})}
              checked={!!sentReportToHotel}
              name="sentReportToHotel"
              label="Report sent"
              disabled={isLoading}
            />
          </div>
          <div className="mb--4 px-2">
            <FormInput.Switch
              onChange={({target: {checked}}) => updateReservation({id, appPhotosUpdated: checked})}
              checked={!!appPhotosUpdated}
              name="appPhotosUpdated"
              label="App content updated"
              disabled={isLoading}
            />
          </div>
        </>}
      />
      {isLoading && <Loading.Line/>}
      <CardBody>
        <Form>

          <Row>
            <Col xs={12} sm={6} lg={3}>
              <FormGroup>
                <Label className="form-control-label d-flex justify-content-between">
                  <span>Influencer</span>
                  <Link to={`/dashboard/influencers/${user?.id}`}><i className="fas fa-link text-primary"/></Link>
                </Label>
                <FormInput.Input
                  value={reservation && `${user?.name} ${user?.familyName || ''}`}
                  description={[!!companions && <><b>Companions:</b> {companions}<br/></>, !!children && <><b>Children:</b> {children}</>]}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <FormInput.Input
                value={hotel?.name}
                label="Hotel"
                disabled
              />
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <FormInput.Input
                value={reservation && `${moment(check_in).format('DD-MM-YYYY')} → ${moment(check_out).format('DD-MM-YYYY')}`}
                label="Check in - Check out"
                disabled
              />
            </Col>
            <Col xs={12} sm={6} lg={3}>

              <div className="flex-center justify-content-between mb-2">
                <label className="form-control-label">Mediakit {(!user?.mediakitInfo && !!user?.mediaKit) && "(old)"}</label>
                <div className="flex-center">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      if(user?.mediakitInfo) downloadMediakit()
                      else if (user?.mediaKit)
                        window.open(user?.mediaKit?.url, '_blank', 'noopener,noreferrer')
                    }}
                    outline
                    disabled={!user?.mediakitInfo && !user?.mediaKit}
                  ><i className="fas fa-download"/></Button>
                </div>

                <div style={{position: 'absolute', right: -9999}}>
                  <div ref={mediakitRef}>
                    <Mediakit mediakit={user?.mediakitInfo} user={user}/>
                  </div>
                </div>
              </div>

              <FormGroup>
                <div className="d-flex justify-content-between">
                  <label className="form-control-label">Obligations</label>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={downloadPdf}
                    outline
                  ><i className="fas fa-download"/> </Button>
                </div>
                {!!stories && <div className="ml-2 my-1">{stories} <b>stor{stories > 1 ? 'ies' : 'y'}</b></div>}
                {!!reels && <div className="ml-2 my-1">{reels} <b>reel{reels > 1 && 's'}</b></div>}
                {!!posts && <div className="ml-2 my-1">{posts} <b>post{posts > 1 && 's'}</b></div>}

                <div style={{position: 'absolute', right: -9999}}>
                  <div ref={obligationsPdf}>
                    <ReservationObligations {...{reservation}} obligations={parsedObligations}/>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <hr className="mt-3"/>

          <Row>
            <Col xs={12} sm={5} lg={4}>
              <FormInput.Input
                value={moment(updatedAt).format('DD-MM-YYYY')}
                label="Last update"
                disabled
              />
              <FormGroup row className="d-flex align-items-center justify-content-between no-gutters">
                <Label className="col-auto form-control-label my-0 mr-2">Rating</Label>
                <div className="col-auto">
                  {rate ? <Stars num={rate}/> : 'No rating'}
                </div>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between">
                <Label className="form-control-label">Media kit</Label>
                {user?.mediaKit ?
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => window.open(user?.mediaKit?.url, '_newtab')}
                    outline
                    // disabled={true}
                  ><i className="fas fa-link"/> </Button>
                :
                  'No mediakit'
                }
              </FormGroup>
            </Col>
            <Col xs={12} sm={7} lg={8}>
              <FormInput.Input
                type="textarea"
                rows={6}
                value={comment || 'No comment'}
                label="Comment"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <label className="form-control-label flex-center justify-content-between">
                  <div>Content</div>
                  <Button
                    color="primary" size="sm" outline
                    onClick={() => downloadStrapiFiles(published_content_photos, `reservation_${number}_content`)}
                  >
                    <i className="fas fa-download" />
                  </Button>
                </label>
                {published_content_photos?.length ?
                  <Splide hasTrack={ false }>
                    <SplideTrack className="bg-dark">
                      {published_content_photos.map((media, i) =>
                        <ContentSlide
                          key={i}
                          media={media}
                          activity={activities && activities.find(a => a?.video?.video?.id === media.id || a?.photo?.id === media.id)}
                          addedToHotel={hotelPhotos.includes(media.id)}
                          addToHotel={addToHotel}
                          hotel={hotel}
                          setActivityToUpdate={setActivityToUpdate}
                        />
                      )}
                    </SplideTrack>
                  </Splide>
                :
                  <div className="text-center mx-5">No content</div>
                }
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup>
                <label className="form-control-label flex-center justify-content-between">
                  <div>Stats</div>
                  <Button
                    color="primary" size="sm" outline
                    onClick={() => downloadStrapiFiles(stats_photo, `reservation_${number}_stats`)}
                  >
                    <i className="fas fa-download" />
                  </Button>
                </label>
                {stats_photo?.length ?
                  <Splide hasTrack={ false }>
                    <SplideTrack className="bg-dark">
                      {stats_photo.map(({name, url}, i) =>
                        <SplideSlide key={i}>
                          <div style={{height: 600}}>
                            <img src={url} className="w-100 h-100" style={{objectFit: 'contain'}} alt={name}/>
                          </div>
                        </SplideSlide>
                      )}
                    </SplideTrack>
                  </Splide>
                :
                  <div className="text-center mx-5">No stats</div>
                }
              </FormGroup>
            </Col>
          </Row>

        </Form>
      </CardBody>
    </Card>

    <Card>
      <CardBody>
        <div className="d-flex justify-content-end">
          <Button
            size="sm"
            color="primary"
            onClick={() => updateReservation({ id, adminComment })}
            disabled={isLoading}
          >Save</Button>
        </div>

        <Row>
          <Col>
            <FormInput.Quill
              label="Admin comment"
              value={adminComment || ""}
              onChange={v => handleInputChange("adminComment", v)}
              disabled={isLoading || sessionUser?.role !== 'Admin'}
            />
          </Col>
        </Row>

      </CardBody>
      {isLoading && <Loading.Line />}
    </Card>

  </>
}


const ContentSlide = ({ hotel, activity, addedToHotel, addToHotel, media, setActivityToUpdate }) => {
  const {name, url, mime} = media
  const newActivity = activity ? activity :
    mime.includes('image') ?
        { photo: media, hotel }
      : mime.includes('video') ?
        { video: { video: media }, hotel }
      :
        null

  return (
    <SplideSlide>
      <div style={{height: 600}} className="position-relative">
        {mime.includes('image') ?
          <img src={url} className="w-100 h-100" style={{objectFit: 'contain'}} alt={name}/>
        : mime.includes('video') ?
          <video className="w-100 h-100" style={{objectFit: 'contain'}} controls>
            <source src={url} type="video/mp4"/>
          </video>
        :
          <div className="h-100 flex-center">
            <i className="fas fa-file text-white" style={{fontSize: '3rem'}}/>
          </div>
        }

        <div className="position-absolute w-100 d-flex justify-content-end p-3" style={{top: 0}}>
          <Button
            size="sm"
            color="primary"
            onClick={e => {
              e.preventDefault()
              saveAs(url, name);
            }
          }>
            <i className="fas fa-download"/>
          </Button>
          {!!newActivity &&
            <Button size="sm" color="default" onClick={() => setActivityToUpdate(newActivity)}>
              {activity ? 'Update activity' : 'Create activity'}
            </Button>
          }
          {(!!newActivity && !addedToHotel) &&
            <Button size="sm" color="default" onClick={() => addToHotel(media)}>
              Add to hotel
            </Button>
          }
        </div>

      </div>
    </SplideSlide>
  )
}



export default ReservationDetailsCard;