import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";

import useCategories from "./hooks/useCategories";

// const normalize = str => str.normalize("NFD").recategory(/[\u0300-\u036f]/g, "").trim().toLowerCase()

const CategorySelect = ({ category, setCategory, ...props }) => {
  const { categories, isLoading } = useCategories()

  const options = useMemo(() =>
    (categories || [])
      .sort((a, b) => a.name > b.name ? 1 : -1)
      .map(category => ({
        value: category.id,
        label: category.name,
        data: category
      }))
  , [categories])

  // const filterOptions = ({ label, data }, input) =>
  //   input ?
  //     normalize(label).includes(normalize(input))
  //   :
  //     !!category ?
  //       data?.data?.parent?.id === category.id
  //     :
  //       !data?.data?.parent

  return (
    <FormInput.Select
      label="Category"
      {...props}
      onChange={option => setCategory(option?.data || null)}
      options={options}
      value={category && options.find(({value}) => `${value}` === `${category.id}`)}
      isDisabled={isLoading || props.isDisabled}
      isClearable
    />

  );
}

export default CategorySelect;