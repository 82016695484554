import React, { useEffect, useState, useMemo } from "react";

import useLocales from "./useLocales";

import {FormInput} from "@general-backoffice/core/index";
import {Col, Row} from "reactstrap";


const useLang = ({ loaded = true, locale, setLocale }) => {
  const [lang, setLang] = useState()
  const {
    locales,
    isLoading
  } = useLocales()

  useEffect(() => {
    if(locales && loaded) {
      if(!locale) setLocale(locales[0])
      else setLang(locale)
    }
    // eslint-disable-next-line
  }, [locales, locale, !!loaded])

  const langOptions = useMemo(() => locales.map(l => ({label: l, value: l})), [locales])
  const LangSelector = ({className, ...props}) =>
    <FormInput.Select
      {...props}
      className={"mb--4 " + className}
      onChange={({value}) => setLang(value)}
      options={langOptions}
      value={langOptions && langOptions.find(({value}) => value === lang)}
      isDisabled={isLoading}
    />

  return {
    lang,
    // setLang,
    // langOptions,
    LangSelector,
    isLoading,
  }
}

export default useLang;