import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { Col, Row } from "reactstrap";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";
import useLang from "../hooks/useLang";


const UpdateCountryModal = ({ countryToUpdate, setCountryToUpdate, close, update, isLoading }) => {
  const { id, photo, continent, useAsFilter, locale, localizations } = countryToUpdate || {}
  const { file: photoFile, isLoading: isLoadingPhotoFile } = useFileInput(photo)

  const {
    lang,
    LangSelector,
    isLoading: isLoadingLang
  } = useLang({
    loaded: !!countryToUpdate,
    locale,
    setLocale: locale => handleInputChange('locale', locale)
  })

  const { name } = localizations?.[lang] || {}

  const {
    handleInputEvent,
    handleInputChange,
    handleLocalizedInputEvent
  } = useForm(setCountryToUpdate, lang)


  const areLoading = isLoading || isLoadingLang
  return (
    <Modal.FormContainer
      title='Edit country'
      isOpen={!!countryToUpdate}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: areLoading }}
      isLoading={areLoading}
      onSubmit={update}
    >
      <Row>
        <Col className="pr-0">
          <FormInput.Input
            onChange={handleLocalizedInputEvent}
            value={name}
            name="name"
            label="Name"
            disabled={areLoading || true}
            required
          />
        </Col>
        <Col xs="auto" className="flex-center pt-1">
          <LangSelector />
        </Col>
      </Row>

      <FormInput.Input
        onChange={handleInputChange}
        value={continent}
        name="continent"
        label="Continent"
        disabled={areLoading || true}
        required
      />

      <FormInput.Switch
        onChange={handleInputEvent}
        checked={useAsFilter}
        name="useAsFilter"
        label="Use as filter"
        labels={["Yes", "No"]}
        disabled={isLoading}
      />

      <FormInput.SingleDropzone
        label="Media"
        accept="image/*"
        file={photoFile}
        onDrop={file => handleInputChange('photo', file)}
        buttons={[
          ...(photoFile ? [{
            children: <i className="fas fa-trash"/>,
            color: "danger",
            onClick: () => handleInputChange('photo', null)
          }] : [])
        ]}
        disabled={isLoading || isLoadingPhotoFile}
        required={!!useAsFilter}
      />

    </Modal.FormContainer>
  );
}

export default UpdateCountryModal;