import React, { useState } from "react";
import {Card, CardBody, Form, Row, Col, Collapse, FormGroup, Button} from "reactstrap";
import CardHeader from "@general-backoffice/core/lib/components/card/CardHeader";
import { FormInput, FormDecoration, hooks } from "@general-backoffice/core/index";
import SortableList, {SortableItem} from "react-easy-sort";
import _ from 'lodash'

import useFileInput, { useMultiFileInput } from "../hooks/useFileInput";
import useForm from "../hooks/useForm";
import useLang from "../hooks/useLang";

import PlaceSelect from "../places/PlaceSelect";
import CategorySelect from "../categories/CategorySelect";
import ActivitiesSelect from "../activities/ActivitiesSelect";
import AdvantagesSelect from "../advantages/AdvantagesSelect";
import ServicesSelect from "../services/ServicesSelect";
import FollowersRangesSelect from "../followersRanges/FollowersRangesSelect";
import AddButtonChildren from "../shared/AddButtonChildren";
import CreateHotelSocialNetwork from "./CreateHotelSocialNetwork";
import SortableFilesPreview from "../shared/SortableFilesPreview";
import downloadFilesAsZip from "../utils/downloadFilesAsZip";


const HotelDetailsCard = ({ hotel, setHotel, updateHotel, isLoading }) => {
  const {
    id,
    name,
    publishedAt,
    stars,
    photos,
    latitude,
    longitude,
    website,
    swipeUpLink,
    // identifier,
    logo,
    minAge,
    socialNetworks,
    coupon,
    category,
    services,
    // reservations,
    place,
    // manager,
    // rooms,
    min_range,
    minFollowers,
    // availabilities,
    // services_photos, // I'm rerquesting this separatedly so i can reload only this
    facilities,
    activities,
    locale,
    localizations,
    maxStay
  } = hotel || {}

  const {
    file: logoImage,
    isLoading: isLoadingLogoImage
  } = useFileInput(logo)
  const {
    files: photosFiles,
    isLoading: isLoadingPhotosFiles
  } = useMultiFileInput(photos)

  const downloadPhotos = fileName =>
      downloadFilesAsZip(photosFiles.map(f => ({name: f.name, file: f})), fileName)

  const {
    lang,
    LangSelector,
    isLoading: isLoadingLang
  } = useLang({
    loaded: !!hotel,
    locale,
    setLocale: locale => handleInputChange('locale', locale)
  })
  const { info, targetAudience, requiredNacionality, requiredCreatorType, rememberSection } = localizations?.[lang] || {}

  const {
    handleInputEvent,
    handleInputChange,
    handleLocalizedInputEvent,
    handleLocalizedInputChange
  } = useForm(setHotel, lang)

  const areLoading = isLoading || isLoadingLang
  return (
    <Card className="overflow-hidden">
      <CardHeader
        title={id ? name : 'New Hotel'}
        subtitle={place?.name}
        isLoading={areLoading}
        buttons={[{
          children: "Save",
          disabled: areLoading,
          form: "hotelDetails",
          type: 'submit'
        }]}
      />
      <CardBody>
        <Form
          id="hotelDetails"
          onSubmit={e => {
            e.preventDefault()
            updateHotel()
          }}
        >
          <Row>
            <Col xs={12} sm={6} md={4}>
              <FormInput.Input
                name="name"
                label="Name *"
                value={name}
                onChange={handleInputEvent}
                disabled={areLoading}
                required
              />
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormInput.Input
                name="website"
                label="Website"
                value={website}
                onChange={handleInputEvent}
                disabled={areLoading}
                // required
              />
            </Col>
            <Col xs={12} md={4}>
              <Row>
                <Col>
                  <FormInput.Switch
                    onChange={({ target: { checked } }) => handleInputChange("publishedAt", checked ? new Date().toISOString() : null)}
                    checked={!!publishedAt}
                    name="publishedAt"
                    label="Published"
                    labels={["Yes", "No"]}
                    disabled={areLoading}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={2} lg="auto">
              <FormInput.Input
                name="stars"
                label="Stars *"
                type="number"
                value={stars}
                onChange={handleInputEvent}
                min={1}
                max={5}
                disabled={areLoading}
                required
              />
            </Col>
            <Col xs={4} md={3} lg={2}>
              <FormInput.Input
                name="minAge"
                label="Minimum age *"
                type="number"
                value={minAge}
                onChange={handleInputEvent}
                min={16}
                disabled={areLoading}
                required
              />
            </Col>
            <Col xs={4} md={3} lg={2}>
              <FormInput.Input
                name="maxStay"
                label="Maximum stay *"
                type="number"
                value={maxStay}
                onChange={handleInputEvent}
                min={1}
                disabled={areLoading}
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <Row>
                <Col className="pr-1">
                  <FormInput.Input
                    name="latitude"
                    label="Latitude *"
                    type="number"
                    value={latitude}
                    onChange={handleInputEvent}
                    min={-90}
                    max={90}
                    step="any"
                    disabled={areLoading}
                    required
                  />
                </Col>
                <Col className="pl-1">
                  <FormInput.Input
                    name="longitude"
                    label="Longitude *"
                    type="number"
                    value={longitude}
                    onChange={handleInputEvent}
                    min={-180}
                    max={180}
                    step="any"
                    disabled={areLoading}
                    required
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={6} lg="auto" className="flex-lg-grow-1">
              <PlaceSelect
                label="Place *"
                place={place}
                setPlace={place => handleInputChange('place', place)}
                isDisabled={areLoading}
                required
              />
            </Col>
            <Col xs={12} sm={6} lg="auto" className="flex-lg-grow-1">
              <CategorySelect
                label="Category *"
                category={category}
                setCategory={category => handleInputChange('category', category)}
                isDisabled={areLoading}
                required
              />
            </Col>
          </Row>

          <FormDecoration.LineTitle>
            <LangSelector />
          </FormDecoration.LineTitle>

          <Row>
            <Col>
              <FormInput.Input
                type="textarea"
                rows="7"
                name="info"
                label="Info *"
                value={info}
                onChange={handleLocalizedInputEvent}
                disabled={areLoading}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6} xl={4}>
              <FormInput.Input
                name="targetAudience"
                label="Target audience"
                value={targetAudience}
                onChange={handleLocalizedInputEvent}
                disabled={areLoading}
                // required
              />
            </Col>
            <Col xs={12} lg={6} xl={4}>
              <FormInput.Input
                name="requiredNacionality"
                label="Required nacionality"
                value={requiredNacionality}
                onChange={handleLocalizedInputEvent}
                disabled={areLoading}
                // required
              />
            </Col>
            <Col xs={12} xl={4}>
              <FormInput.Input
                name="requiredCreatorType"
                label="Creator type"
                value={requiredCreatorType}
                onChange={handleLocalizedInputEvent}
                disabled={areLoading}
                // required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput.Quill
                key={lang}
                label="Extra requirements"
                value={rememberSection || ""}
                onChange={v => handleLocalizedInputChange('rememberSection', v)}
                disabled={areLoading}
              />
            </Col>
          </Row>

          <FormDecoration.Line/>

          <Row>
            <Col>
              <Col>
                <FormInput.SingleDropzone
                  label="Logo *"
                  accept="image/*"
                  file={logoImage}
                  onDrop={(file) => handleInputChange('logo', file)}
                  disabled={areLoading || isLoadingLogoImage}
                  required
                />

                <FormInput.MultiDropzone
                  label={<div className="flex-center justify-content-between w-100">
                    <div>Images *</div>
                    <Button
                      color="primary" size="sm" outline
                      onClick={() => downloadPhotos(`${hotel?.name}_images`)}
                    >
                      <i className="fas fa-download" />
                    </Button>
                  </div>}
                  accept="image/*,video/*"
                  onDrop={([file]) => handleInputChange('photos', [...photosFiles, file])}
                  disabled={areLoading || isLoadingPhotosFiles}
                />
                <SortableFilesPreview
                  files={photosFiles}
                  onSort={(oldI, newI) =>
                    setHotel(h => {
                      const photosCopy = _.cloneDeep(h.photos)
                      photosCopy.splice(newI, 0, photosCopy.splice(oldI, 1)[0])
                      return {...h, photos: photosCopy}
                    })
                  }
                  buttons={[
                    {
                      color: "primary",
                      onClick: file => window.open(file?.strapiFile?.url || file?.preview,'_newtab'),
                      children: <i className="fas fa-link"/>
                    },
                    {
                      color: "danger",
                      onClick: (file, i) => {
                        const photosCopy = _.cloneDeep(photos)
                        photosCopy.splice(i, 1)
                        handleInputChange('photos', photosCopy)
                      },
                      children: <i className="fas fa-trash"/>
                    }
                  ]}
                  disabled={areLoading || isLoadingPhotosFiles}
                />

              </Col>
            </Col>
            <Col>
              <ActivitiesSelect
                label={
                  <a href="/dashboard/hotel/activities" style={{ cursor: 'pointer' }}>
                    Activities <i className="fas fa-link"/>
                  </a>
                }
                activities={activities}
                setActivities={activities => handleInputChange('activities', activities)}
                isDisabled={areLoading}
              />
              <AdvantagesSelect
                label="Advantages *"
                advantages={facilities}
                setAdvantages={facilities => handleInputChange('facilities', facilities)}
                isDisabled={areLoading}
                required
              />
              <ServicesSelect
                label="Services *"
                services={services}
                setServices={services => handleInputChange('services', services)}
                isDisabled={areLoading}
                required
              />

              <Row>
                <Col style={{minWidth: 220}}>
                  <FollowersRangesSelect
                    label="Min followers range *"
                    followersRange={min_range}
                    setFollowersRange={fr => handleInputChange('min_range', fr)}
                    isDisabled={areLoading}
                    required
                  />
                </Col>
                <Col style={{minWidth: 250}}>
                  <FormInput.Input
                    name="minFollowers"
                    label="Min followers text"
                    value={minFollowers}
                    onChange={handleInputEvent}
                    disabled={areLoading}
                  />
                </Col>
              </Row>

              <FormInput.Switch
                onChange={({ target: { checked } }) => handleInputChange("coupon", checked ? {} : null)}
                checked={!!coupon}
                name="coupon"
                label="Coupon"
                labels={["Yes", "No"]}
                disabled={areLoading}
              />
              <Collapse isOpen={!!coupon}>
                <Row>
                  <Col>
                    <FormInput.Input
                      name="coupon.code"
                      label="Code *"
                      value={coupon?.code}
                      onChange={handleInputEvent}
                      disabled={areLoading}
                      required={!!coupon}
                    />
                  </Col>
                  <Col>
                    <FormInput.Input
                      name="coupon.discount"
                      label="Discount (%)"
                      type="number"
                      value={coupon?.discount}
                      onChange={handleInputEvent}
                      min={0}
                      max={100}
                      step="1"
                      disabled={areLoading}
                    />
                  </Col>
                </Row>
              </Collapse>

              <FormInput.Input
                name="swipeUpLink"
                label="SwipeUpLink * "
                value={swipeUpLink}
                onChange={handleInputEvent}
                disabled={areLoading}
                required
              />

              <SocialNetworks
                label="Social Networks"
                socialNetworks={socialNetworks}
                setSocialNetworks={sns => handleInputChange('socialNetworks', sns)}
                isLoading={areLoading}
              />

            </Col>
          </Row>

        </Form>
      </CardBody>
    </Card>
  )
}


const SocialNetworks = ({ label, socialNetworks, setSocialNetworks, isLoading }) => {
  const [newSocialNetwork, setNewSocialNetwork] = useState()
  return <>

    <CreateHotelSocialNetwork
      socialNetworkToCreate={newSocialNetwork}
      setSocialNetworkToCreate={setNewSocialNetwork}
      close={() => setNewSocialNetwork(null)}
      create={() => {
        setSocialNetworks(socialNetworks.concat([newSocialNetwork]))
        setNewSocialNetwork(null)
      }}
      isLoading={isLoading}
    />

    <FormGroup>
      <div className="d-flex justify-content-between mb-2">
        <label className="form-control-label">{label}</label>
        <Button size="sm" color="primary" onClick={() => setNewSocialNetwork({})}>
          <AddButtonChildren>Add</AddButtonChildren>
        </Button>
      </div>
      {socialNetworks?.length ? socialNetworks.map(({accountName, social_network}, i) =>
          <div key={i} className="d-flex justify-content-between pl-2 py-2">
            <div>
              <span className="text-muted">@</span><b>{accountName}</b>{" "}
              {social_network && <span style={{fontSize: 12}}>({social_network?.name})</span>}
            </div>
            <Button
              size="sm" color="danger"
              onClick={() => {
                const snCopy = _.cloneDeep(socialNetworks)
                snCopy.splice(i, 1)
                setSocialNetworks(snCopy)
              }}
            ><i className="fas fa-trash"/></Button>
          </div>
        )
        :
        <div className="text-center mx-5">No social networks</div>
      }
    </FormGroup>
  </>
}


export default HotelDetailsCard;