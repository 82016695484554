import React, { useState, useMemo, createRef } from "react";
import { Card as ReactstrapCard } from "reactstrap";
import { Loading, Card } from "@general-backoffice/core";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin  from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
  colorPerState,
  convertReservationsToFullCalendarEvents,
  convertFullCalendarEventToReservation
} from "./utils/reservationsCalendarEvents";

import {
  convertAvailabilitiesToFullCalendarEvents
} from "../availabilities/utils/availabilitiesCalendarEvents";
import AddButtonChildren from "../shared/AddButtonChildren";



const ReservationsCalendarCard = ({ reservations, availabilities, setReservationToUpdate, isLoading, setShownPeriod, getDefaultReservation }) => {
  const [subtitle, setSubtitle] = useState("")

  const calendarRef = createRef()
  const getApi = () => calendarRef.current.getApi()

  const onDateSet = ({start, end}) => {
    const startDate = moment(start)
    const endDate = moment(end).subtract(1, "second")

    setSubtitle(`${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`)

    // By adding 42 days around start and end, the surrounding months' events don't pop up when view is changed
    setShownPeriod([
      startDate.subtract(42, 'd').format("YYYY-MM-DD"),
      endDate.add(42, 'd').format("YYYY-MM-DD")
    ])
  }

  const onEventClick = ({event}) => setReservationToUpdate(convertFullCalendarEventToReservation(event))

  const onDateClick = ({dateStr, startStr, endStr}) =>{
    const start = dateStr || startStr
    return moment().isBefore(start) &&
      setReservationToUpdate({
        check_in: moment(start).format("YYYY-MM-DD"),
        check_out: endStr ? moment(endStr).format("YYYY-MM-DD") : moment(start).add(1, 'day').format("YYYY-MM-DD")
      })
  }

  const calendarEvents = useMemo(() => ([
    ...convertReservationsToFullCalendarEvents(reservations),
    ...convertAvailabilitiesToFullCalendarEvents(availabilities, true)
  ]), [reservations, availabilities])

  const {cancelled, denied, ...colorsToShow} = colorPerState
  const areLoading = isLoading
  return (
    <ReactstrapCard>
      <Card.Header
        title="Reservations"
        subtitle={subtitle}
        buttons={[
          {
            className: "p-0 bg-white border-0 shadow-none",
            children:
              <div className="flex-center text-dark mr-2 flex-wrap">
                {Object.entries(colorsToShow).map(([state, color], i) =>
                  <div key={i} className="d-flex align-items-center px-1 text-capitalize">
                    <div className={`bg-${color} mr-1 mb-1`} style={{height: 13, width: 13}}/>
                    {state}
                  </div>
                )}
              </div>,
          },
          // { children: <AddButtonChildren children="Reservation"/>, onClick: () => setReservationToUpdate(getDefaultReservation()), className: "mr-4" },

          { children: <i className="fas fa-angle-left px-2"/>, onClick: () => getApi().prev() },
          { children: "Now", onClick: () => getApi().today(), disabled: areLoading },
          { children: <i className="fas fa-angle-right px-2"/>, onClick: () => getApi().next() }
        ]}
        isLoading={areLoading}
      />

      {areLoading && <Loading.Line/>}
      <div className="overflow-auto calendar" id="calendar" style={{ height: '60vh', minHeight: 400 }}>
        <FullCalendar

          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          firstDay={1}
          last
          datesSet={onDateSet}

          stickyHeaderDates
          headerToolbar=""
          height="100%"

          events={calendarEvents}
          eventClick={onEventClick}
          eventOrder="sort"

          selectable
          select={onDateClick}
          dateClick={onDateClick}

        />
      </div>
    </ReactstrapCard>
  );
}


export default ReservationsCalendarCard;