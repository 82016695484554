import { notify } from "@general-backoffice/core";

import { placeRequests } from "../../../api/Api"
import useStrapi4GetOne from "../../hooks/useStrapi4GetOne";

import { getFileId } from "../../hooks/useFileInput";
import { submitLocalizations } from "../../utils/localizations";
import handleError from "../../utils/handleError";


const usePlace = (id, params) => {
  const {
    item: place,
    setItem: setPlace,
    ...other // isLoading, setLoading, load, remove
  } = useStrapi4GetOne({
    id,
    params,
    submitItem: submitPlace,
    deleteItem: deletePlace,
    loadItem: loadPlace
  })

  return {
    place,
    setPlace,
    ...other
  }
}


export const loadPlace = async (id, params) => {
  try {
    return await placeRequests.get(id, params)
  } catch (e) {
    handleError(e?.message || "Place loading failed")
  }
}

export const submitPlace = async place => {
  const isNew = !place?.id

  const errMsg = `Place ${isNew ? 'creation' : 'modification'} failed`
  if (!place) throw new Error(errMsg)
  try {
    const { id, latitude, longitude, photo, country, parent, localizations, ...props } = place

    // Remove fields that shouldn't be changed from here
    delete props.children
    delete props.hotels
    delete props.activities

    const body = {
      latitude: latitude && parseFloat(latitude).toFixed(5),
      longitude: longitude && parseFloat(longitude).toFixed(5),
      country: country?.id || country,
      parent: parent?.id || parent,
      photo: await getFileId(photo),
      ...props
    }

    const [res] = isNew ?
      await placeRequests.create(body)
      :
      await placeRequests.update(id, body)

    // Update or create every localization needed
    const defaultId = isNew ? res.id : id
    await submitLocalizations(localizations, defaultId, placeRequests.update, placeRequests.localize)

    notify.success(`Place ${isNew ? 'created' : 'modified'} correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const deletePlace = async place => {
  const errMsg = "Error while deleting place"
  if (!place?.id) throw new Error(errMsg)
  try {
    await placeRequests.delete(place.id)
    notify.success("Place removed correctly")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}




export default usePlace;