import { useMemo, useState } from "react";

const strapi4SortDir = {
  up: ':asc',
  down: ':desc'
}

const useSort = (initialSort = {}) => {
  const [sort, setSort] = useState(initialSort)

  const strapi4Params = useMemo(() =>
    Object.entries(sort).reduce((r, [field, direction]) => {
      if (direction)
        r[`sort[${Object.keys(r).length}]`] = field + strapi4SortDir[direction]
      return r
    }, {})
  , [sort])

  const changeSort = (field, order) => {
    if(typeof field === 'object')
      setSort(s => ({...s, ...field}))
    else
    setSort(s => ({...s, [field]: order || null}))
  }

  const clearSort = () => setSort(initialSort)

  return {
    sort,
    setSort: changeSort,
    strapi4Params,
    clearSort
  }

}

export default useSort;