import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  Row,
  Col,
  FormGroup, Badge
} from "reactstrap";
import CardHeader from "@general-backoffice/core/lib/components/card/CardHeader";
import { FormInput } from "@general-backoffice/core";
import { notify } from "@general-backoffice/core";
import {useSelector} from "react-redux";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";

import followersRangeToString from "../reservations/utils/followersRangeToString";
import {userRequests} from "../../api/Api";
import handleError from "../utils/handleError";

import ReservationsTableCard from "../reservations/ReservationsTableCard";
import useHtmlConverter from "../hooks/useHtmlConverter";
import Mediakit from "../templates/Mediakit";
import UpdateMediakitModal from "./UpdateMediakitModal";




const UserDetailsCard = ({ user, setUser, refreshUser, updateUser, isLoading, reservationsTableProps }) => {
  const { user: sessionUser } = useSelector(({ sessionState }) => sessionState)
  const {
    id,
    name,
    familyName,
    email,
    phone,
    profilePhoto,
    // birthdate,
    gender,
    country,
    socialNetworks,
    adminComment,
    mediaKit,
    mediakitInfo,
    role
  } = user || {}
  const { file: profileImage } = useFileInput(profilePhoto)
  const { file: mediaKitFile, isLoading: isLoadingMediaKitFile } = useFileInput(mediaKit)
  const [isEditingMediakit, setIsEditingMediakit] = useState(false)
  const { ref: mediakitRef, downloadImagePdf: downloadMediakit } = useHtmlConverter(`mediakit_${email}`)
  const [isLoadingLocal, setIsLoading] = useState(false)

  const {
    handleInputChange,
  } = useForm(setUser)

  return <>

    <UpdateMediakitModal
      isOpen={isEditingMediakit}
      close={() => setIsEditingMediakit(false)}
      onMediakitUpdate={updatedMediakit => handleInputChange("mediakitInfo", updatedMediakit)}
      mediakit={mediakitInfo}
      user={user}
    />

    <Card className="overflow-hidden">
      <CardHeader
        title={`${name}${familyName ? ` ${familyName}` : ''}`}
        subtitle={email}
        isLoading={isLoading}
        // buttons={[{
        //   children: "Save",
        //   disabled: isLoading,
        //   form: "userDetails",
        //   type: 'submit'
        // }]}
      />
      <CardBody>
        <Form
          // id="userDetails"
          // onSubmit={e => {
          //   e.preventDefault()
          //   updateUser()
          // }}
        >
          <Row>
            <Col xs={12} sm={6} lg={3}>
              <FormInput.Input
                value={name}
                name="name"
                label="Name"
                disabled
              />
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <FormInput.Input
                value={familyName}
                name="familyName"
                label="Surnames"
                disabled
              />
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <FormInput.Input
                type="email"
                value={email}
                name="email"
                label="Email"
                disabled
              />
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <FormInput.Input
                value={phone}
                name="phone"
                label="Phone"
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={7}>
              <FormInput.SingleDropzone
                label="Profile photo"
                accept="image/*"
                file={profileImage}
                onDrop={(file) => handleInputChange('profilePhoto', file)}
                disabled
              />
            </Col>
            <Col xs={12} md={5}>
              <FormInput.Select
                label="Country"
                value={{label: country?.name, value: country}}
                isDisabled
              />
              <FormInput.Select
                label="Gender"
                value={{label: gender, value: gender}}
                isDisabled
              />
            </Col>
          </Row>
        </Form>

      </CardBody>
    </Card>


    {role?.type === 'influencer' && <>
      <Card>
        <CardBody>
          <div className="flex-center justify-content-between">
            <label className="form-control-label m-0">Mediakit</label>
            <div className="flex-center">
              <Button
                color="default"
                size="sm"
                onClick={() => setIsEditingMediakit(true)}
                outline
              ><i className="fas fa-edit"/> </Button>
              <Button
                color="primary"
                size="sm"
                onClick={downloadMediakit}
                outline
                disabled={!mediakitInfo}
              ><i className="fas fa-download"/> </Button>
            </div>
          </div>
          <div style={{ position: 'absolute', right: -9999 }}>
            <div ref={mediakitRef}>
              <Mediakit mediakit={mediakitInfo} user={user} />
            </div>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              color="primary"
              onClick={e => {
                e.preventDefault()
                updateUser()
              }}
              disabled={isLoading}
            >Save</Button>
          </div>

          <Row>
            <Col xs={12} lg={8}>
              <FormInput.Quill
                label="Admin comment"
                value={adminComment || ""}
                onChange={v => handleInputChange("adminComment", v)}
                disabled={isLoading || sessionUser?.role !== 'Admin'}
                style={{ minHeight: 400 }}
              />
            </Col>
            <Col xs={12} md={4}>
              <FormInput.SingleDropzone
                label="Media kit (old)"
                accept="application/pdf, image/*"
                file={mediaKitFile}
                // onDrop={(file) => handleInputChange('mediaKit', file)}
                buttons={[...(mediaKit ? [
                  {
                    color: "primary",
                    onClick: () => window.open(mediaKitFile?.strapiFile?.url || mediaKitFile?.preview,'_newtab'),
                    children: <i className="fas fa-link"/>
                  },
                  {
                    children: <i className="fas fa-trash"/>,
                    color: "danger",
                    onClick: () => handleInputChange('mediaKit', null)
                  },
                ] : [])]}
                disabled={isLoading || isLoadingMediaKitFile}
              />
            </Col>
          </Row>

        </CardBody>
      </Card>


      <Card>
        <CardBody>
            <FormGroup>
              <label className="form-control-label">Social Networks</label>

              {!socialNetworks?.length ?
                <div className="flex-center p-5">This influencer has no social networks</div>
                :
                <Row className="mt-2">
                  {socialNetworks.map((sn, i) =>
                    <Col key={i} xs={12} sm={6} lg={4}>
                      <SocialNetworkCard
                        socialNetwork={sn}
                        updateValidation={async state => {
                          socialNetworks[i].verified = state
                          setIsLoading(true)
                          try {
                            await userRequests.update(id, { socialNetworks:
                              socialNetworks.map(({id, verified, main}) => ({id, verified, main: !!main}))
                            })
                            notify.success(`Influencer social network verification status changed successfully`)
                          } catch (e) {
                            handleError(e?.message || `Error changing social network verification status`)
                          }
                          await refreshUser()
                          setIsLoading(false)
                        }}
                        isLoading={isLoadingLocal}
                      />
                    </Col>
                  )}
                </Row>
              }
            </FormGroup>
        </CardBody>
      </Card>

      <ReservationsTableCard {...reservationsTableProps} hiddenFields={['influencer']} />

    </>}

  </>
}


const verifiedColors = {
  verified: 'success',
  pending: 'primary',
  denied: 'danger'
}

const SocialNetworkCard = ({ socialNetwork, updateValidation, isLoading }) => {
  const { accountName, main, verified, followers_range, social_network, verification } = socialNetwork
  const { name: snName } = social_network
  return(
    <Card className="position-relative h-100">
      <CardHeader
        title={accountName}
        subtitle={snName.toUpperCase()}
        isLoading={isLoading}
      />
      {main &&
        <i className="fas fa-star text-primary position-absolute" style={{top: 23, right: 20, fontSize: 20}}/>
      }
      <CardBody>

        <label className="form-control-label">Followers</label>
        <div>{followersRangeToString(followers_range)}</div>

        <label className="form-control-label mt-3">Media</label>
        <ul className="pl-2" style={{ listStyleType: 'none' }}>
          {(verification || []).map(({url, name}, i) =>
            <li key={i} className="overflow-hidden" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              <a href={url} target="_blank" rel="noreferrer">{name}</a>
            </li>
          )}
        </ul>

        <label className="form-control-label mt-3">Status</label>
        <div className="pl-2" style={{ listStyleType: 'none' }}>
          <Badge color="" className="badge-dot py-0">
            <i className={`bg-${verifiedColors[verified]}`}/>
            <span className="text-capitalize">{verified || 'all'}</span>
          </Badge>
        </div>

      </CardBody>
      <CardFooter className="d-flex justify-content-end">
        {verified === 'pending' && <>
          <Button color="success" onClick={() => updateValidation('verified')} disabled={isLoading}>
            Validate
          </Button>
          <Button color="danger" onClick={() => updateValidation('denied')} disabled={isLoading}>
            Deny
          </Button>
        </>}
      </CardFooter>
    </Card>
  )
}



export default UserDetailsCard;