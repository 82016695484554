import React, {useEffect, useMemo, useState} from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useParams } from "react-router";

import { reservationRequests } from "../../../../api/Api";

import useUser from "../../../../components/users/hooks/useUser";
import useReservations from "../../../../components/reservations/hooks/useReservations";
// import useStrapi4Params from "../../../../components/hooks/useStrapi4Params";

// Components
import UserDetailsCard from "../../../../components/users/UserDetailsCard";
import useStrapi4Pagination from "../../../../components/hooks/useStrapi4Pagination";
import useSort from "../../../../components/hooks/useSort";

import reservationsStatesConfig from "../../../../components/reservations/config/states";



const User = () => {
  const { id } = useParams()

  const {
    user,
    setUser,
    refresh: refreshUser,
    update: updateUser,
    isLoading
  } = useUser(id, {
    "populate[socialNetworks][populate]": 'followers_range,social_network,verification',
    "populate[country][populate]": true,
    "populate[profilePhoto][populate]": true,
    "populate[role]": true,
    "populate[mediaKit]": true,
    "populate[mediakitInfo][populate]": "*",
  })



  // User reservations
  const {
    reservations,
    refresh: refreshReservations,
    isLoading: isLoadingReservations,
    // params,
    // changeParams,
    // resetParams

    filters,
    setFilters,
    changeFilters,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = useReservations({
    filters: {
      ...(id && { 'filters[user][id][$eq]': id }),
      // state: reservationsStatesConfig?.[filters?.status]?.filters
    },
    sort: { check_in: 'down' },
    pagination: true,
    // params: baseFilters, setPagination
  })


  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <UserDetailsCard
              user={user}
              setUser={setUser}
              refreshUser={refreshUser}
              updateUser={() => updateUser().then(() => refreshUser())}
              reservationsTableProps={{
                reservations,
                refreshReservations,
                isLoading: isLoadingReservations,

                filters,
                setFilters,
                changeFilters,
                pagination,
                goToPage,
                changePageSize,
                sort,
                changeSort
              }}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default User;