import { countryRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";

import handleError from "../../utils/handleError";

const parseFilters = filters => {
  const parsedFiltersList = Object.entries(filters || {}).map(([key, val]) => {
    switch(key) {
      case 'name_contains':
        return { 'filters[name][$containsi]': val }
      default:
        return {[key]: val}
    }
  })
  return Object.assign({}, ...parsedFiltersList)
}

const useCountries = props => {
  const {
    items: countries,
    refresh,
    isLoading,
    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadCountries,
    parseFilters
  })

  return {
    countries,
    refresh,
    isLoading,

    ...other
  }
}

export const loadCountries = async params => {
  try {
    console.log('params', params, {...params})
    return await countryRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Countries loading failed")
  }
}

export default useCountries;