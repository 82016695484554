
import { Navigate } from "react-router";
import Hotels from "../../views/pages/dashboard/hotels/Hotels"
import Hotel from "../../views/pages/dashboard/hotels/Hotel";


const subRoutes = state => [
  {
    name: "Hotels",
    path: "",
    component: <Hotels />
  },
  {
    name: "Hotel",
    path: ":id",
    component: <Hotel />
  },
  {
    name: "Create Hotel",
    path: "new",
    component: <Hotel />
  },
  {
    path: "*",
    index: true,
    component: <Navigate to="/dashboard/hotels" replace={true}/>,
  }
]

export default subRoutes;
