import React from "react";
import { Card } from "reactstrap";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";

import AddButtonChildren from "../shared/AddButtonChildren";


const ActivitiesTableCard = ({ activities = [], setActivityToUpdate, setActivityToDelete, isLoading, pagination, goToPage, setPageSize, sort, setSort, params, changeParams  }) => {

  const columns = [
    {
      name: "Activity",
      property: "name",
      isTh: true
    },
    // {
    //   name: "Hotels",
    //   property: "hotel.name"
    // },
    { name: "Place", property: "place.name" },
    {
      name: "Media",
      formattedContent: ({ name, photo, video }) => photo ?
        <TableOutput.Image src={photo?.url || video?.thumbnail?.url} alt={name}/>
      :
        <a href={video?.video?.url} target="_blank" rel="noreferrer">
          Video
        </a>
    },
    {
      name: "Link",
      formattedContent: ({ link }) =>
        <div style={{ maxWidth: 200, textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <a href={link} target="_blank" rel="noreferrer">
            {link}
          </a>
        </div>
    },
    {
      name: "",
      formattedContent: activity => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setActivityToUpdate({ ...activity }),
              description: "Edit activity"
            },
            {
              icon: <i className="fas fa-trash"/>,
              onClick: () => setActivityToDelete({ ...activity }),
              description: "Delete activity"
            }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="overflow-hidden position-relative">

      <TableCard.Header
        title="Activities"
        isLoading={isLoading}
        buttons={[
          {
            children: <AddButtonChildren children="Activity"/>,
            onClick: () => setActivityToUpdate({
              place: params?.['filters[place][id][$eq]'],
              hotel: params?.['filters[hotel][id][$eq]']
            }),
            color: 'primary'
          }
        ]}
        search={{
          value: params?.['filters[name][$containsi]'],
          onChange: e => changeParams('filters[name][$containsi]', e.target.value),
          msToChange: 800,
          placeholder: "Search..."
        }}
      />

      <Table
        columns={columns}
        rows={activities}
        onRowClick={activity => setActivityToUpdate({...activity})}
        isLoading={isLoading}
        noContentMessage="No activities were found"
        rowHeight={81}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={setPageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default ActivitiesTableCard;