import React from "react";
import { Modal } from "@general-backoffice/core";


const DeleteServicePhotoModal = ({ servicePhotoToDelete, close, remove, isLoading }) => {
  const title = servicePhotoToDelete?.title
  return (
    <Modal.DeleteConfirm
      isOpen={!!servicePhotoToDelete}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="Delete activity"
      descriptionDelete={<div>Service Photo {title && <span className="font-weight-bold">"{title}" </span>}will be deleted.</div>}
      closeText="Cancel"
      buttonDelete={{ children: "Delete", onClick: remove }}
    />
  );
}

export default DeleteServicePhotoModal;