import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import {hotelRequests, servicePhotoRequests} from "../../../api/Api"
import useStrapi4Params from "../../hooks/useStrapi4Params";
import handleError from "../../utils/handleError";


const useServicePhotos = ({ params: initialParams, autoLoad = true } = {}) => {
  const { params, changeParams, resetParams } = useStrapi4Params(initialParams)
  const [servicePhotos, setServicePhotos] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(!!autoLoad) refresh().catch(() => null)
    // eslint-disable-next-line
  }, [params])
  
  const refresh = async () => {
    setLoading(true)
    return loadServicePhotos(params)
      .then(([servicePhotos]) => {
        setServicePhotos(servicePhotos)
        return servicePhotos
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    servicePhotos,
    setServicePhotos,
    refresh,
    isLoading,

    params,
    changeParams,
    resetParams
  }
}

export const loadServicePhotos = async params => {
  try {
    return await servicePhotoRequests.getList(params)
  } catch (e) {
    const message = (e?.message || "ServicePhotos loading failed")
    notify.error(message)
    throw new Error(message)
  }
}


export const setHotelServicePhotos = async (hotelId, servicePhotos) => {
  const errMsg = `Hotel service photos modification failed`
  if (!hotelId || !servicePhotos) throw new Error(errMsg)
  try {
    for(const sp of servicePhotos) {
      await servicePhotoRequests.update(sp?.id || sp, { hotel: null })
      await servicePhotoRequests.update(sp?.id || sp, { hotel: hotelId })
    }
    notify.success(`Hotel service photos modified correctly`)
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}



export default useServicePhotos;