import React, {useEffect, useMemo, useState} from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import {Badge, Col, Collapse, Row} from "reactstrap";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";
import useLang from "../hooks/useLang";
import usePlaces from "./hooks/usePlaces";
import useCountries from "../countries/hooks/useCountries";


const UpdatePlaceModal = ({ placeToUpdate, setPlaceToUpdate, close, update, isLoading }) => {
  const {
    id,
    photo,
    latitude,
    longitude,
    isSearchable,
    useParent,
    country,
    parent,
    children,
    locale,
    localizations
  } = placeToUpdate || {}
  const { file } = useFileInput(photo)

  const {
    lang,
    LangSelector,
    isLoading: isLoadingLang
  } = useLang({
    loaded: !!placeToUpdate,
    locale,
    setLocale: locale => handleInputChange('locale', locale)
  })

  const localization = localizations?.[lang]
  const { name } = localization || {}


  const {
    handleInputEvent,
    handleInputChange,
    handleLocalizedInputEvent
  } = useForm(setPlaceToUpdate, lang)


  const areLoading = isLoading || isLoadingLang
  return (
    <Modal.FormContainer
      title={!!id ? `Edit ${name}` : "Create place"}
      isOpen={!!placeToUpdate}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: areLoading }}
      isLoading={areLoading}
      onSubmit={update}
    >
      <FormInput.SingleDropzone
        label="Media"
        accept="image/*"
        file={file}
        onDrop={file => handleInputChange('photo', file)}
        buttons={[
          ...(file ? [{
            children: <i className="fas fa-trash"/>,
            color: "danger",
            onClick: () => handleInputChange('photo', null)
          }] : [])
        ]}
        disabled={isLoading}
      />

      <Row>
        <Col className="pr-0">
          <FormInput.Input
            onChange={handleLocalizedInputEvent}
            value={name}
            name="name"
            label="Name"
            disabled={areLoading}
            required
          />
        </Col>
        <Col xs="auto" className="flex-center pt-1">
          <LangSelector style={{zIndex: 9999}} />
        </Col>
      </Row>

      <ParentSelect
        label="Parent"
        placeId={id}
        parent={parent}
        country={country}
        changePlace={({parent, country}) =>
          setPlaceToUpdate(p => ({...p, parent, country}))
        }
        required
      />

      <FormInput.Switch
        onChange={handleInputEvent}
        checked={isSearchable}
        name="isSearchable"
        label="Show as filter"
        labels={["Yes", "No"]}
        disabled={isLoading}
      />

      <FormInput.Switch
        onChange={handleInputEvent}
        checked={useParent}
        name="useParent"
        label="Use parent"
        labels={["Yes", "No"]}
        disabled={isLoading}
      />

      <Row>
        <Col>
          <FormInput.Input
            name="latitude"
            label="Latitude"
            type="number"
            value={latitude}
            onChange={handleInputEvent}
            min={-90}
            max={90}
            step="any"
            disabled={isLoading}
          />
        </Col>
        <Col>
          <FormInput.Input
            name="longitude"
            label="Longitude"
            type="number"
            value={longitude}
            onChange={handleInputEvent}
            min={-180}
            max={180}
            step="any"
            disabled={isLoading}
          />
        </Col>
      </Row>

    </Modal.FormContainer>
  );
}


const normalize = (str = "") => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()

const ParentSelect = ({ placeId, parent, country, changePlace, isDisabled, ...props }) => {
  const {
    countries = [],
    isLoading: isLoadingCountries
  } = useCountries({
    // filters: {'filters[useAsFilter][$eq]': true}
  })

  const {
    places = [],
    isLoading: isLoadingPlaces
  } = usePlaces({
    filters: {'filters[id][$ne]': placeId},
    depsToReset: [placeId]
  })

  const options = useMemo(() => ([
    {
      label: 'Countries',
      options: countries.map(country => ({
        value: country.id,
        // label: country.useAsFilter ?
        //   <>
        //     <Badge color="" className="badge-dot py-0">
        //       <i className='bg-primary'/>
        //     </Badge>
        //     {country.name}
        //   </>
        //   :
        //     country.name,
        label: country.name,
        data: country,
        isCountry: true
      }))
    },
    {
      label: 'Places',
      options: places.map(place => ({
        value: place.id,
        label: `${place.name} (${place?.country?.name})`,
        data: place
      }))
    }
  ]), [places, countries])


  const filterOptions = ({ value, label, data, isCountry }, input) => {
    if(input) return normalize(label).includes(normalize(input))
    // else {
    //   !!parent ?
    //     data?.data?.parent?.id === place.id
    //     :
    //     !data?.data?.parent
    // }
    return true
  }

  const onChange = option =>
    changePlace(!!option ?
      option?.isCountry ?
        { parent: null, country: option.data }
      :
        { parent: option.data, country: option.data?.country }
    :
      { parent: null, country: null }
    )

  const selected = useMemo(() => {
    if(parent)
      return options[1].options.find(({value}) => `${value}` === `${parent.id}`)
    if(country)
      return options[0].options.find(({value}) => `${value}` === `${country.id}`) || { label: country.name }
  }, [options, parent, country])

  return (
    <FormInput.Select
      {...props}
      onChange={onChange}
      options={options}
      value={selected}
      isDisabled={isLoadingCountries || isLoadingPlaces || isDisabled}
      filterOption={filterOptions}
      isClearable
    />
  )
}


export default UpdatePlaceModal;