import React, { useMemo } from "react";
import { Modal, FormInput } from "@general-backoffice/core";

import useForm from "../hooks/useForm";
import useSocialNetworks from "../socialNetworks/hooks/useSocialNetworks";


const CreateHotelSocialNetwork = ({ socialNetworkToCreate, setSocialNetworkToCreate, close, create, isLoading }) => {
  const { id, accountName, social_network } = socialNetworkToCreate || {}

  const {
    handleInputEvent,
    handleInputChange
  } = useForm(setSocialNetworkToCreate)

  const {
    socialNetworks,
    isLoading: isLoadingSocialNetworks
  } = useSocialNetworks()

  const options = useMemo(() =>
    (socialNetworks || []).map(sn => ({
      value: sn.id,
      label: sn.name,
      data: sn
    }))
  , [socialNetworks])


  const areLoading = isLoading || isLoadingSocialNetworks
  return (
    <Modal.FormContainer
      title="Add social network"
      isOpen={!!socialNetworkToCreate}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: areLoading }}
      isLoading={areLoading}
      onSubmit={create}
    >
      <FormInput.Input
        onChange={handleInputEvent}
        value={accountName}
        name="accountName"
        label="Handle"
        disabled={areLoading}
      />

      <FormInput.Select
        label="Social network"
        onChange={option => handleInputChange('social_network', option?.data || null)}
        options={options}
        value={social_network && options.find(({value}) => `${value}` === `${social_network.id}`)}
        isDisabled={areLoading}
        required
      />


    </Modal.FormContainer>
  );
}

export default CreateHotelSocialNetwork;