import React, { useState, useEffect } from "react";
import {FormInput, notify} from "@general-backoffice/core/index";

import { followersRangeRequests } from "../../api/Api";
import followersRangeToString from "../reservations/utils/followersRangeToString";


const FollowersRangesSelect = ({ followersRange, setFollowersRange, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setLoading(true)
    followersRangeRequests.getList()
      .then(([ranges]) =>
        setOptions(ranges.map(r => ({
          value: r.id,
          label: followersRangeToString(r),
          data: r,
          isDisabled: r.id === 1
        })))
      )
      .catch(e => notify.error(e?.message || "Followers ranges loading failed"))
      .finally(() => setLoading(false))
  }, [])

  return (
    <FormInput.Select
      label='Followers range'
      {...props}
      onChange={option => setFollowersRange(option?.data || null)}
      options={options}
      value={followersRange && options.find(({value}) => `${value}` === `${followersRange.id}`)}
      isDisabled={isLoading || props.isDisabled}
      isClearable
    />

  );
}

export default FollowersRangesSelect;