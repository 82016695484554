import { useState } from "react";
import { notify } from "@general-backoffice/core";

import {activityRequests, advantageRequests} from "../../../api/Api"

import handleError from "../../utils/handleError";
import { submitLocalizations } from "../../utils/localizations";
import {deleteAvailability} from "../../availabilities/hooks/useAvailability";


const useAdvantage = () => {
  const [advantage, setAdvantage] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const update = () => {
    if (!advantage || isLoading) return;
    setLoading(true)
    return submitAdvantage(advantage)
      .finally(() =>
        setLoading(false)
      )
  }

  const remove = async () => {
    if (!advantage || isLoading) return;
    setLoading(true)
    return deleteAdvantage(advantage)
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    advantage,
    setAdvantage,
    update,
    remove,
    isLoading
  }
}


export const submitAdvantage = async (advantage) => {
  const isNew = !advantage?.id

  const errMsg = `Advantage ${isNew ? 'creation' : 'modification'} failed`
  if (!advantage) throw new Error(errMsg)
  try {

    const { id, localizations, ...props } = advantage

    // Remove fields that shouldn't be changed from here
    delete props.hotels

    const body = props

    const [res] = isNew ?
      await advantageRequests.create(body)
      :
      await advantageRequests.update(id, body)


    // Update or create every localization needed
    const defaultId = isNew ? res.id : id
    await submitLocalizations(localizations, defaultId, advantageRequests.update, advantageRequests.localize)

    notify.success(`Advantage ${isNew ? 'created' : 'modified'} correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const deleteAdvantage = async advantage => {
  const errMsg = "Error while deleting advantage"
  if (!advantage?.id) throw new Error(errMsg)
  try {
    await advantageRequests.delete(advantage.id)
    notify.success("Advantage removed correctly")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}




export default useAdvantage;