import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import { activityRequests } from "../../../api/Api"

import handleError from "../../utils/handleError";
import { getFileId } from "../../hooks/useFileInput";


const useActivity = id => {
  const [activity, setActivity] = useState(null);
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    if(id) load(id).catch(() => null)
  }, [id])


  const load = async id => {
    setLoading(true)
    return loadActivity(id)
      .then(([activity]) => {
        setActivity(activity)
        return activity
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = () => {
    if (!activity || isLoading) return;
    setLoading(true)
    return submitActivity(activity)
      .finally(() =>
        setLoading(false)
      )
  }

  const remove = async () => {
    if (!activity || isLoading) return;
    setLoading(true)
    return deleteActivity(activity)
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    activity,
    setActivity,
    load,
    update,
    remove,
    isLoading
  }
}


export const loadActivity = async id => {
  try {
    return await activityRequests.get(id)
  } catch (e) {
    const message = (e?.message || "Activity loading failed")
    notify.error(message)
    throw new Error(message)
  }
}


export const submitActivity = async activity => {
  const isNew = !activity?.id

  const errMsg = `Activity ${isNew ? 'creation' : 'modification'} failed`
  if (!activity) throw new Error(errMsg)
  try {
    const { id, hotels, place, video, photo, link, ...props } = activity

    const body = {
      place: place?.id || place,
      hotels: hotels && hotels.map(h => h?.id || h),
      photo: await getFileId(photo),
      link: link || null,
      ...props
    }

    if(video === null) body.video = null
    if(video !== undefined) {
      const videoId = await getFileId(video?.video)
      const thumbnailId = await getFileId(video?.thumbnail)
      body.video = {
        ...video,
        video: videoId,
        thumbnail: thumbnailId
      }
    }

    const [res] = isNew ?
      await activityRequests.create(body)
    :
      await activityRequests.update(id, body)

    notify.success(`Activity ${isNew ? 'created' : 'modified'} correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export const deleteActivity = async activity => {
  const errMsg = "Error while deleting activity"
  if (!activity?.id) throw new Error(errMsg)
  try {
    await activityRequests.delete(activity.id)
    notify.success("Activity removed correctly")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}



export default useActivity;