import { useState, useEffect } from "react";
import { notify } from "@general-backoffice/core/index";

import { generalConfigRequests } from "../../api/Api"

const useGeneralConfig = props => {
  const [isLoading, setIsLoading] = useState()
  const [generalConfig, setConfig] = useState()
  useEffect(() => {
    setIsLoading(true)
    generalConfigRequests.get()
      .then(([res]) => setConfig(res))
      .catch((e) => notify.error(e?.message || "Error while loading general config"))
      .finally(() => setIsLoading(false))
  }, []);

  return { generalConfig, isLoading }
}

export default useGeneralConfig;