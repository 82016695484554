import React from "react";
import Dashboard from "../../views/layouts/Dashboard";
import { Navigate } from "react-router";

// MainRoutes
import Home from "../../views/pages/dashboard/home";
import Notifications from "../../views/pages/dashboard/notifications";
import Activities from "../../views/pages/dashboard/activities";
import Categories from "../../views/pages/dashboard/categories";
import Services from "../../views/pages/dashboard/services";
import Advantages from "../../views/pages/dashboard/advantages";
// import Rooms from "../../views/pages/dashboard/rooms"


// SubRoutes;
// import availabilitiesSubroutes from "./availabilitiesSubroutes"
import hotelsSubroutes from "./hotelsSubroutes"
import influencersSubroutes from "./influencersSubroutes"
import usersSubroutes from "./usersSubroutes"
import placesSubroutes from "./placesSubroutes"
import reservationsSubroutes from "./reservationsSubroutes"
import hotelReservationsSubroutes from "./hotelReservationsSubroutes"



const subRoutes = state => {
  const hotelSection = state?.hotel?.name.trim() || 'Hotel'
  return [
    {
      name: "Home",
      accessRoles: ['Admin'],
      path: "",
      showOnNav: "Administrator",
      icon: "fas fa-home text-default",
      component: <Home/>
    },
    {
      name: "Hotels",
      accessRoles: ['Admin'],
      path: "hotels",
      showOnNav: "Administrator",
      icon: "fas fa-h-square text-default",
      subRoutes: hotelsSubroutes(state)
    },
    {
      name: "Influencers",
      accessRoles: ['Admin'],
      path: "influencers",
      showOnNav: "Administrator",
      icon: "fas fa-user-tie text-default",
      subRoutes: influencersSubroutes(state)
    },
    {
      name: "Reservations",
      accessRoles: ['Admin'],
      path: "reservations",
      showOnNav: 'Administrator',
      icon: "fas fa-calendar text-default",
      subRoutes: reservationsSubroutes(state)
    },
    {
      name: "Users",
      accessRoles: ['Admin'],
      path: "users",
      showOnNav: "Administrator",
      icon: "fas fa-user text-default",
      subRoutes: usersSubroutes(state)
    },
    {
      name: "Notifications",
      accessRoles: ['Admin'],
      path: "notifications",
      showOnNav: "Administrator",
      icon: "fas fa-bell text-default",
      component: <Notifications/>
    },

    {
      name: "All Activities",
      accessRoles: ['Admin'],
      path: "activities",
      showOnNav: " ",
      icon: "fas fa-hiking text-default",
      component: <Activities/>
    },
    {
      name: "Places",
      accessRoles: ['Admin'],
      path: "countries",
      showOnNav: " ",
      icon: "fas fa-map-marker-alt text-default",
      subRoutes: placesSubroutes(state)
    },
    {
      name: "Categories",
      accessRoles: ['Admin'],
      path: "categories",
      showOnNav: " ",
      icon: "fas fa-th-large text-default",
      component: <Categories/>
    },
    {
      name: "Services",
      accessRoles: ['Admin'],
      path: "services",
      showOnNav: " ",
      icon: "fas fa-concierge-bell text-default",
      component: <Services/>
    },
    {
      name: "Advantages",
      accessRoles: ['Admin'],
      path: "advantages",
      showOnNav: " ",
      icon: "fas fa-hand-holding-usd text-default",
      component: <Advantages/>
    },


    // Hotel specific sections
    {
      name: "Reservations",
      accessRoles: ['Admin', 'Hotel'],
      path: "hotel/reservations",
      showOnNav: hotelSection,
      icon: "fas fa-calendar text-default",
      subRoutes: hotelReservationsSubroutes(state)
    },
    {
      name: "Activities",
      accessRoles: ['Admin', 'Hotel'],
      path: "hotel/activities",
      showOnNav: hotelSection,
      icon: "fas fa-hiking text-default",
      component: <Activities hotel={state?.hotel}/>
    },
  ]
}


const index = state => ({
  path: "/dashboard",
  component: <Dashboard/>,
  subRoutes: subRoutes(state).concat({
    path: "*",
    accessRoles: 'Admin',
    index: true,
    component: <Navigate to="" replace={true}/>
  })
})

export default index;