import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { Collapse } from "reactstrap";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";
import PlaceSelect from "../places/PlaceSelect";
import HotelSelect from "../hotels/HotelSelect";


const UpdateActivityModal = ({ activityToUpdate, setActivityToUpdate, close, update, isLoading }) => {
  const { id, name, video: videoObj, photo, link, place, hotels } = activityToUpdate || {}
  const { video, /*thumbnail,*/ muted } = videoObj || {}
  const { file, isLoading: isLoadingFile } = useFileInput(photo || video)

  const {
    handleInputEvent,
    handleInputChange
  } = useForm(setActivityToUpdate)

  return (
    <Modal.FormContainer
      title={!!id ? `Edit ${name}` : "Create activity"}
      isOpen={!!activityToUpdate}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: isLoading }}
      isLoading={isLoading}
      onSubmit={update}
    >
      <FormInput.Input
        onChange={handleInputEvent}
        value={name}
        name="name"
        label="Name"
        disabled={isLoading}
        required
      />

      <FormInput.SingleDropzone
        label="Media"
        accept="image/*,video/*"
        file={file}
        onDrop={file => {
          const type = file.type.split('/')[0]
          if(type === 'video')
            setActivityToUpdate(a => ({
              ...a,
              photo: null,
              video: {
                ...(videoObj || {}),
                video: file
              }
            }))
          else if (type === 'image')
            setActivityToUpdate(a => ({
              ...a,
              video: null,
              photo: file
            }))
        }}
        buttons={[
          ...(!!videoObj ? [{
            children: <i className="fas fa-play"/>,
            color: "success",
            onClick: () => window.open(video?.url, '_blank')
          }] : []),
          ...(file ? [{
            children: <i className="fas fa-trash"/>,
            color: "danger",
            onClick: () => handleInputChange('video', null)
          }] : [])
        ]}
        disabled={isLoading || isLoadingFile}
        required
      />

      <Collapse isOpen={!!videoObj}>
        <FormInput.Switch
          onChange={handleInputEvent}
          checked={muted}
          name="video.muted"
          label="Muted"
          labels={["Yes", "No"]}
          disabled={isLoading}
        />
      </Collapse>


      <PlaceSelect
        label="Place"
        place={place}
        setPlace={place => handleInputChange('place', place)}
        required={!hotels?.length}
      />

      <HotelSelect
        label="Hotels"
        hotel={hotels}
        setHotel={hotels => handleInputChange('hotels', hotels)}
        isMulti
      />

      <FormInput.Input
        onChange={handleInputEvent}
        value={link}
        name="link"
        label="Link"
        disabled={isLoading}
      />

    </Modal.FormContainer>
  );
}

export default UpdateActivityModal;