
import { Navigate } from "react-router";
import ReservationsCalendar from "../../views/pages/dashboard/reservations/ReservationsCalendar"
import Reservations from "../../views/pages/dashboard/reservations/Reservations"
import Reservation from "../../views/pages/dashboard/reservations/Reservation";

const subRoutes = state => [
  {
    name: "Reservations",
    path: "",
    showOnNav: true,
    component: <Reservations hotel={state?.hotel}/>
  },
  {
    name: "Reservations calendar",
    path: "calendar",
    showOnNav: true,
    component: <ReservationsCalendar hotel={state?.hotel} />
  },
  {
    name: "Reservation",
    path: "details/:id",
    component: <Reservation />
  },
  {
    path: "*",
    index: true,
    component: <Navigate to="/dashboard/hotel/reservations" replace={true}/>,
  }
]

export default subRoutes;
