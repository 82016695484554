import React from "react";
import { Modal } from "@general-backoffice/core";


const DeleteAdvantageModal = ({ advantageToDelete, close, remove, isLoading }) => {
  return (
    <Modal.DeleteConfirm
      isOpen={!!advantageToDelete}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="Delete advantage"
      descriptionDelete={<div>Advantage <span className="font-weight-bold">"{advantageToDelete?.name}"</span> will be deleted.</div>}
      closeText="Cancel"
      buttonDelete={{ children: "Delete", onClick: remove }}
    />
  );
}

export default DeleteAdvantageModal;