import React from 'react';
import ReactDOM from 'react-dom';
import moment from "moment";

import App from './App';
import reportWebVitals from './reportWebVitals';

import "@fortawesome/fontawesome-free/css/all.css"
import "@general-backoffice/core/lib/assets/vendor/nucleo/css/nucleo.css"
import "./styles/styles.css"
import 'moment/locale/es';
import '@splidejs/react-splide/dist/css/splide.min.css';

import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

moment.locale("es");

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
