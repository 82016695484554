import React from "react";
import { Loading, NotificationWrapper, PersistAndProvider } from "@general-backoffice/core";
import sessionStatePersistConfig from "./redux/reducers/session/statePersistConfig";
import sessionState from "./redux/reducers/session/state";
import {login, logout, setHotel} from "./redux/reducers/session/actions";
import Router from "./Router";
import {hotelRequests} from "./api/Api";

const stores = {
  sessionState: {
    persistConfig: sessionStatePersistConfig,
    baseReducer: sessionState
  }
}

const afterRehydrate = async (store) => {
  const state = store.getState();
  const { sessionState: { token, user, hotel } } = state;
  try {
    if (!user || !token) return store.dispatch(logout())
    store.dispatch(login(token, user));

    switch(user.role) {
      case 'Admin': {
        const [hotels] = await hotelRequests.getList()
        const curHotel = hotels.find(h => h.id === hotel.id) || hotels?.[0]
        store.dispatch(setHotel(curHotel, hotels))
        break;
      }
      case 'Hotel': {
        if(!hotel) return store.dispatch(logout())
        const [refreshedHotel] = await hotelRequests.get(hotel.id)
        store.dispatch(setHotel(refreshedHotel, [refreshedHotel]));
        break;
      }
      default:
        store.dispatch(logout())
    }
  } catch (e) {
    store.dispatch(logout())
  }
}


function App() {

  const loadingPage = (
    <div className="vh-100 flex-center">
      <Loading.BouncingDots size={"lg"}/>
    </div>
  )

  return (
    <PersistAndProvider stores={stores} afterRehydrate={afterRehydrate} loadingScreen={loadingPage}>
      <React.Suspense fallback={loadingPage}>
        <NotificationWrapper>
          <Router/>
        </NotificationWrapper>
      </React.Suspense>
    </PersistAndProvider>
  );
}

export default App;
