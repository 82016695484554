import React, {useMemo, useState} from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { notify } from "@general-backoffice/core/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Card } from "reactstrap";

import { setHotel } from "../../redux/reducers/session/actions";

import ReservationStatusFilter from "../reservations/ReservationStatusFilter";
import StateButton from "./utils/StateButton";
import reservationsStatesConfig, { getReservationState } from "../reservations/config/states";



const getReservationsPerState = (reservations) => (reservations || []).reduce((r, rsv) => {
  const state = getReservationState(rsv)
  if(!r[state]) r[state] = 1
  else r[state]++
  return r
}, {})



const HotelsReservationsTableCard = ({ hotels, isLoading, filters, changeFilters, pagination, goToPage, changePageSize, publishedHotels }) => {
  const dispatch = useDispatch()
  const { hotel } = useSelector(({ sessionState }) => sessionState)
  const navigate = useNavigate();

  const formattedHotels = useMemo(() =>
    (hotels || []).map(h => ({...h, reservationsPerState: getReservationsPerState(h.reservations)}))
  , [hotels])

  const columns = [
    {
      name: "Hotel",
      formattedContent: ({logo, name, publishedAt}) => logo ?
        <TableOutput.ImageText
          src={logo.url} alt={logo.name} text={name}
          imageContainerClassName={!!publishedAt && "border-success"}
          imageContainerStyle={{borderWidth: 3}}
        /> : name,
      isTh: true
    },
    {
      formattedContent: hotel =>
        <div className="d-flex justify-content-end">
          {Object.entries(reservationsStatesConfig).map(([state, config], i) => {
            const num = hotel?.reservationsPerState?.[state] || 0
            return (
              <StateButton
                key={i}
                num={num}
                text={config?.text || state}
                color={num ? config?.color : 'light'}
                onClick={() => handleClick(hotel, state)}
              />
            )
            })}
        </div>
    },
  ]

  const handleClick = (clickedHotel, state) => {
    if (hotel?.id !== clickedHotel.id) {
      dispatch(setHotel(clickedHotel))
      notify(`Welcome to ${clickedHotel.name}`)
    }
    navigate(`hotel/reservations?status=${state}`)
  };

  // const onlyWithReservations = filters?.['filters[reservations][id][$gt]'] !== undefined

  return (
    <Card className="overflow-visible">
      <TableCard.Header
        title="Reservation"
        subtitle={(!!pagination?.total && publishedHotels !== undefined) &&
          <>Published hotels: <b>{publishedHotels}</b> / {pagination.total}</>
        }
        isLoading={isLoading}
        buttons={[
          {
            children:
              <ReservationStatusFilter status={filters?.reservationsStatus} setStatus={status => changeFilters('reservationsStatus', status)} />,
            color: 'transparent',
            className: 'p-0',
            style: {zIndex: 10}
          }
        ]}
        search={{
          value: filters?.['filters[name][$containsi]'],
          onChange: e => changeFilters('filters[name][$containsi]', e.target.value),
          msToChange: 800,
          placeholder: "Search..."
        }}
      />
      <Table
        columns={columns}
        rows={formattedHotels}
        onRowClick={hotel => navigate(`hotels/${hotel?.id}`)}
        isLoading={isLoading}
        noContentMessage="No reservations were found"
        rowHeight={81}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}


export default HotelsReservationsTableCard;