import React, { useMemo } from "react";
import { Modal, FormInput } from "@general-backoffice/core";

import useForm from "../hooks/useForm";
import useUsers from "../users/hooks/useUsers";


const SendNotificationModal = ({ notificationToSend, setNotificationToSend, close, send, isLoading }) => {
  const { notification, to } = notificationToSend || {}
  const { title, body } = notification || {}


  const {
    handleInputChange,
    handleInputEvent
  } = useForm(setNotificationToSend)


  const {
    users,
    isLoading: isLoadingUsers
  } = useUsers({ params: {populate: ['role']} })

  console.log('loadedUsers', users)

  const options = useMemo(() =>
    [
      {
        value: 'incluencers',
        label: <b>Add all influencers</b>,
        data: users?.filter(u => u.role.type === 'influencer')
      },
      {
        value: 'authenticated',
        label: <b>Add all standard users</b>,
        data: users?.filter(u => u.role.type === '"authenticated"')
      },
      {
        value: 'authenticated',
        label: <b>Add all users</b>,
        data: users
      }
    ].concat((users || []).map(user => ({
      value: user.id,
      label: user.name + " " + user.familyName,
      data: user
    })))
  , [users])


  const areLoading = isLoading || isLoadingUsers
  return (
    <Modal.FormContainer
      title="Send notification"
      isOpen={!!notificationToSend}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Send", disabled: areLoading }}
      isLoading={areLoading}
      onSubmit={send}
    >
      <FormInput.Input
        onChange={handleInputEvent}
        value={title}
        name="notification.title"
        label="Title"
        disabled={areLoading}
        required
      />

      <FormInput.Input
        onChange={handleInputEvent}
        type="textarea"
        rows="7"
        name="notification.body"
        label="Body"
        value={body}
        disabled={areLoading}
      />

      <FormInput.Select
        label="Receiver/s"
        placeholder='Search user'
        onChange={options => handleInputChange('to', Array.from(new Set(options.reduce((r, v) => r.concat(v?.data), []))))}
        options={options}
        value={to && to.map(user => options.find(({value}) => value === user.id))}
        isDisabled={areLoading}
        isMulti
      />

    </Modal.FormContainer>
  );
}

export default SendNotificationModal;