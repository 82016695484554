import React from "react";
import { TableOutput, Table, TableCard, FormInput } from "@general-backoffice/core";
import {useNavigate} from "react-router";
import { Card } from "reactstrap";

import AddButtonChildren from "../shared/AddButtonChildren";
import Stars from "../shared/Stars";


const status = [
  { status: "Published", color: "success" },
  { status: "Unpublished", color: "danger" }
]

const HotelsTableCard = ({
                           hotels, isLoading, filters,
                           changeFilters, pagination,
                           goToPage, changePageSize, sort,
                           changeSort, filtersToShow
}) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Hotel",
      formattedContent: ({ logo, name }) => logo ? <TableOutput.ImageText src={logo.url} alt={logo.name} text={name}/> : name,
      isTh: true,
      sort: d => changeSort('name', d),
      sortStatus: sort?.name
    },
    {
      name: "Published",
      formattedContent: ({ publishedAt }) =>
        <TableOutput.Status {...(publishedAt ? status[0] : status[1])}/>,
      sort: d => changeSort('publishedAt', d ),
      sortStatus: sort?.publishedAt
    },
    {
      name: "Stars",
      formattedContent: ({ stars }) => <Stars num={stars} max={5}/>,
      sort: d => changeSort('stars', d),
      sortStatus: sort?.stars
    },
    {
      name: "Place",
      property: "place.name"
    },
    {
      name: "Banners",
      formattedContent: ({ photos }) => photos?.length &&
        <TableOutput.Images
          images={
            photos
              .filter(p => p.mime.includes('image'))
              .map(p => ({src: p?.formats?.thumbnail?.url || p.url, alt: p.name}))
              .slice(0, 5)
          }
        />
    },
    {
      name: "",
      sort: () => null,
      formattedContent: (hotel) => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => navigate(`${hotel.id}`),
              description: "Edit hotel"
            }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title="Hotels"
        subtitle={!!pagination?.total && `Total hotels: ${pagination.total}`}
        buttons={[
          { children: <AddButtonChildren children="Hotel"/>, onClick: () => navigate(`new`) }
        ]}
        search={{
          value: filters?.['filters[name][$containsi]'],
          onChange: e => changeFilters('filters[name][$containsi]', e.target.value),
          msToChange: 800,
          placeholder: "Search..."
        }}
        isLoading={isLoading}
      >
        {!!filtersToShow.length &&
          <div className="px-4 flex-center justify-content-end flex-wrap" style={{gap: '.8rem 1rem'}}>
            {filtersToShow.includes("isCurrentlyAvailable") &&
              <div className="mb--4">
                <FormInput.Switch
                  onChange={e => changeFilters('isCurrentlyAvailable', e.target.checked || null)}
                  checked={filters?.isCurrentlyAvailable}
                  label="Only currently available"
                  labels={["Yes", "No"]}
                  disabled={isLoading}
                />
              </div>
            }
          </div>
        }
      </TableCard.Header>
      <Table
        columns={columns}
        rows={hotels}
        onRowClick={hotel => navigate(`${hotel.id}`)}
        isLoading={isLoading}
        noContentMessage="No hotels were found"
        rowHeight={100}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default HotelsTableCard;