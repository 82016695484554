import { useEffect, useState } from "react";

import { useStrapi4QueryParams } from "@libraries/strapi-4-utils"


const useStrapi4GetList = ({
                         autoLoad = true,
                         load,
                         onLoadError,

                         populate,
                         filters: initialFilters,
                         pagination: initialPagination,
                         sort: initialSort,
                         parseFilters,
                         depsToReset = []
} = {}) => {
  const [items, setItems] = useState();
  const { queryParams, ...parsedProps } = useStrapi4QueryParams({
    populate,
    filters: initialFilters,
    pagination: initialPagination,
    sort: initialSort,
    parseFilters,
    depsToReset
  })
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(autoLoad) refresh()
    // eslint-disable-next-line
  }, [queryParams])

  const refresh = () => {
    if(!load || isLoading) return
    setLoading(true)
    return load(queryParams)
      .then(([items, { pagination }]) => {
        setItems(items)
        if(!!initialPagination) parsedProps.setPagination(pagination)
        return items
      })
      .catch(e => onLoadError?.(e))
      .finally(() => setLoading(false))
  }


  return {
    items,
    setItems,
    isLoading,
    setLoading,
    refresh,
    ...parsedProps
  }
}



export default useStrapi4GetList;