import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";
import { Button } from "reactstrap";

import useActivities from "./hooks/useActivities";
import useActivity from "./hooks/useActivity";

import UpdateActivityModal from "./UpdateActivityModal";


const ActivitiesSelect = ({ activities, setActivities, hotel, isDisabled, creatable = true, ...props }) => {
  const {
    activities: loadedActivities,
    refresh,
    isLoading
  } = useActivities({params: {
    ...(hotel && {
      'filters$or][0][hotel][id][$eq]': hotel.id,
      'filters[$or][1][hotel][id][$null]': true
    })
  }})

  const options = useMemo(() =>
    (loadedActivities || [])
      .sort((a, b) => a.name > b.name ? 1 : -1)
      .map(activity => ({
        value: activity.id,
        label: activity.name,
        data: activity
      }))
  , [loadedActivities])

  const {
    activity: activityToCreate,
    setActivity: setActivityToCreate,
    update: createActivity,
    isLoading: isCreatingActivity
  } = useActivity()

  return <>

    <UpdateActivityModal
      activityToUpdate={activityToCreate}
      setActivityToUpdate={setActivityToCreate}
      close={() => setActivityToCreate(null)}
      update={() => createActivity()
        .then(async activity => {
          const refreshActivities = await refresh()
          const newActivity = refreshActivities.find(a => a.id === activity?.id) || activity
          setActivities(activities.concat([newActivity]))
          setActivityToCreate(null)
        })
        .catch(() => null)
      }
      isLoading={isCreatingActivity || isLoading || isDisabled}
    />

    <FormInput.Select
      label="Activities"
      icon={!!creatable &&
        <i className="fas fa-plus text-default px-2 cursor-pointer" onClick={() => setActivityToCreate({})} />}
      {...props}
      value={activities && activities.map(activity => options.find(({value}) => value === activity.id))}
      onChange={options =>  setActivities(options.map(v => v?.data))}
      options={options}
      isDisabled={isLoading || isDisabled}
      isMulti
    />
  </>
}

export default ActivitiesSelect;