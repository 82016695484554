import { useCallback, useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import moment from "moment";
import {useSelector} from "react-redux";

import { reservationRequests } from "../../../api/Api"

import handleError from "../../utils/handleError";


const useReservation = (id, params) => {
  const hotel = useSelector(({ sessionState }) => sessionState.hotel)
  const [reservation, setReservation] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(id) load(id).catch(() => null)
  }, [id])

  const load = async () => {
    setLoading(true)
    return loadReservation(id, params)
      .then(([reservation]) => {
        setReservation(reservation)
        return reservation
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = (reservationChanges) => {
    if (!(reservationChanges || reservation) || isLoading) return;
    setLoading(true)
    return submitReservation(reservationChanges || reservation)
      .finally(() =>
        setLoading(false)
      )
  }

  const validate = (state) => {
    if (!reservation || isLoading) return;
    setLoading(true)
    return validateReservation(reservation?.id, state)
      .finally(() =>
        setLoading(false)
      )
  }


  const getDefaultReservation = useCallback(availability => ({
    hotel: hotel.id,
    hidden: false,
    state: 'accepted',
    ...(availability && {
      availability: availability.id,
      check_in: availability.startDate,
      check_out: availability.endDate,
      night: moment(availability.endDate).diff(availability.startDate, 'd'),
    })
  }), [hotel])

  return {
    getDefaultReservation,
    reservation,
    setReservation,
    load,
    update,
    validate,
    isLoading
  }
}


export const loadReservation = async (id, params) => {
  try {
    return await reservationRequests.get(id, params)
  } catch (e) {
    const message = (e?.message || "Reservation loading failed")
    notify.error(message)
    throw new Error(message)
  }
}

export const submitReservation = async (reservation) => {
  const isNew = !reservation?.id

  const errMsg = `Reservation ${isNew ? 'creation' : 'modification'} failed`
  if (!reservation) throw new Error(errMsg)
  try {

    // TODO: for now only the fields expected to be used are changeable
    const {
      id,
      // check_in, check_out,
      sentMediakitToHotel,
      sentReportToHotel,
      appPhotosUpdated,
      adminComment,
      // hotel,
      ...props
    } = reservation

    const body = {
      sentMediakitToHotel,
      sentReportToHotel,
      appPhotosUpdated,
      adminComment
      // check_in: check_in && moment(check_in).format('YYYY-MM-DD'),
      // check_out: check_out && moment(check_out).format('YYYY-MM-DD'),
      // hidden: false,
      // ...props
    }

    const [res] = isNew ?
      await reservationRequests.create(body)
    :
      await reservationRequests.update(id, body)

    notify.success(`Reservation ${isNew ? 'created' : 'modified'} correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const validateReservation = async (id, state) => {
  const errMsg = `Reservation validation failed`
  if (!id || !state) throw new Error(errMsg)
  try {
    let res = await reservationRequests.validate(id, state)

    notify.success(`Reservation state changed correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export default useReservation;