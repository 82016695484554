import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { useNavigate } from "react-router";
import { Button, Card, FormGroup, Input, Label } from "reactstrap";
import moment from "moment";
import _ from 'lodash'

import Stars from "../shared/Stars";
import ReservationStatusFilter from "./ReservationStatusFilter";
import reservationsStatesConfig, { stateFilterQuery, getReservationState } from "./config/states";

import HotelSelect from "../hotels/HotelSelect";
import UserSelect from "../users/UserSelect";
import { FormInput, Loading } from "@general-backoffice/core/index";
import Checkbox from "react-three-state-checkbox";


const ReservationsTableCard = ({
                                 reservations,
                                 /*setReservationToUpdate,*/
                                 isLoading,
                                 filters,
                                 changeFilters,
                                 setFilters,
                                 clearFilters,
                                 pagination,
                                 goToPage,
                                 changePageSize,
                                 sort,
                                 changeSort,
                                 hiddenFields = [],
                                 subtitle
}) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: 'Number',
      property: "number"
    },
    ...(!hiddenFields.includes('influencer') ? [{
      name: "Influencer",
      formattedContent: ({ user }) => user && `${user?.name || ''} ${user?.familyName || ''}`,
      isTh: true,
      sort: d => changeSort({
        'user.name': d,
        'user.familyName': d
      }),
      sortStatus: sort['user.name']
    }] : []),
    ...(!hiddenFields.includes('hotel') ? [{
      name: "Hotel",
      property: "hotel.name",
      sort: d => changeSort('hotel.name', d),
      sortStatus: sort?.['hotel.name']
    }] : []),
    {
      name: 'Check in',
      formattedContent: ({ check_in }) => moment(check_in).format('DD-MM-YYYY'),
      sort: d => changeSort('check_in', d),
      sortStatus: sort['check_in']
    },
    { formattedContent: () => <div className="mx--2">→</div> },
    {
      name: 'Check out',
      formattedContent: ({ check_in, check_out }) => moment(check_out).format('DD-MM-YYYY'),
      sort: d => changeSort('check_out', d),
      sortStatus: sort['check_out']
    },
    {
      name: "Status",
      formattedContent: ({state, check_out}) => {
        const { text, color } = reservationsStatesConfig?.[getReservationState({state, check_out})] || {}
        return <TableOutput.Status status={text || state || 'all'} color={color || 'dark'}/>
      },
      sort: d => changeSort('state', d),
      sortStatus: sort?.state
    },
    {
      name: "Booked at",
      formattedContent: ({ createdAt }) => moment(createdAt).format('DD-MM-YYYY'),
      sort: d => changeSort('createdAt', d),
      sortStatus: sort?.createdAt
    },
    {
      name: "Mediakit sent",
      formattedContent: ({ sentMediakitToHotel }) =>
        <div className="text-center ml--2">
          {sentMediakitToHotel ?
            <i className="fas fa-check text-success" />
          :
            <i className="fas fa-times text-danger" />
          }
        </div>
    },
    {
      name: "Report sent",
      formattedContent: ({ sentReportToHotel }) =>
        <div className="text-center ml--2">
          {sentReportToHotel ?
            <i className="fas fa-check text-success" />
          :
            <i className="fas fa-times text-danger" />
          }
        </div>
    },
    {
      name: "App content updated",
      formattedContent: ({ appPhotosUpdated }) =>
        <div className="text-center ml--2">
          {appPhotosUpdated ?
            <i className="fas fa-check text-success" />
            :
            <i className="fas fa-times text-danger" />
          }
        </div>
    },
    {
      name: 'Nights',
      property: "nights",
      sort: d => changeSort('nights', d),
      sortStatus: sort?.['nights']
    },
    {
      name: 'Companions',
      property: "companions",
      sort: d => changeSort('companions', d),
      sortStatus: sort?.['companions']
    },
    {
      name: 'Children',
      property: "children",
      sort: d => changeSort('children', d),
      sortStatus: sort?.['children']
    },
    {
      name: "Last content update",
      formattedContent: ({ review }) => moment(review?.updatedAt).format("DD-MM-YYYY HH:mm"),
      sort: d => changeSort('review.updatedAt', d),
      sortStatus: sort?.['review.updatedAt']
    },

    ...(['content', 'completed', 'incomplete'].includes(filters?.status) ? [
      {
        name: "Content",
        formattedContent: ({ review: { published_content_photos: photos } }) => photos?.length &&
          <TableOutput.Images
            images={
              photos
                .filter(p => p.mime.includes('image'))
                .map(p => ({src: p?.formats?.thumbnail?.url || p.url, alt: p.name}))
                .slice(0, 5)
            }
          />
      },
      {
        name: "Stats",
        formattedContent: ({ review: { stats_photo: photos } }) => photos?.length &&
          <TableOutput.Images
            images={
              photos
                .filter(p => p.mime.includes('image'))
                .map(p => ({src: p?.formats?.thumbnail?.url || p.url, alt: p.name}))
                .slice(0, 5)
            }
          />
      },
      {
        name: "Rating",
        formattedContent: ({ review }) => <Stars num={review?.rate} max={5}/>,
        sort: d => changeSort('review.rate', d),
        sortStatus: sort['review.rate']
      }
    ] : []),

    // {
    //   name: "",
    //   formattedContent: reservation =>
    //     <TableOutput.CustomActions
    //       actions={[
    //         ...(setReservationToUpdate ? [{
    //           icon: <i className="fas fa-edit"/>,
    //           onClick: () => setReservationToUpdate(_.cloneDeep(reservation)),
    //           description: "Edit reservation"
    //         }]: [])
    //       ]}
    //     />
    // }

  ]

  return (
    <Card className="overflow-visible">
      <TableCard.Header
        title={[
          'Reservations',
          <div className="text-sm text-primary">{subtitle}</div>
        ]}
        subtitle={pagination && <>Total: <b>{pagination.total}</b></>}
        isLoading={isLoading}
        children={
          <Button size="sm" color="danger" className="mr-3" onClick={clearFilters} disabled={isLoading}>
            Clear filters
          </Button>
        }
        search={{
          value: filters?.['filters[$or][0][number][$containsi]'],
          onChange: e => setFilters(filters => ({
            ...filters,
            'filters[$or][0][number][$containsi]': e.target.value,
            'filters[$or][1][user][familyName][$containsi]': e.target.value,
            'filters[$or][2][user][name][$containsi]': e.target.value
          })),
          msToChange: 1600,
          placeholder: "Search..."
        }}
      />
      {isLoading && <Loading.Line/>}


      <div className="px-4 pt-3 d-flex align-items-center justify-content-around flex-wrap pb-3" style={{gap: '.8rem 1rem'}}>

        <FiltersDateFilter
          label="Booked between"
          from={filters?.booked_from}
          to={filters?.booked_to}
          changeFrom={d => changeFilters('booked_from', d)}
          changeTo={d => changeFilters('booked_to', d)}
          cleanFilters={() => setFilters(f => ({...f, booked_from: undefined, booked_to: undefined}))}
          isLoading={isLoading}
        />

        <FiltersDateFilter
          label="Check in between"
          from={filters?.checkIn_from}
          to={filters?.checkIn_to}
          changeFrom={d => changeFilters('checkIn_from', d)}
          changeTo={d => changeFilters('checkIn_to', d)}
          cleanFilters={() => setFilters(f => ({...f, checkIn_from: undefined, checkIn_to: undefined}))}
          isLoading={isLoading}
        />

        <FiltersDateFilter
          label="Check out between"
          from={filters?.checkOut_from}
          to={filters?.checkOut_to}
          changeFrom={d => changeFilters('checkOut_from', d)}
          changeTo={d => changeFilters('checkOut_to', d)}
          cleanFilters={() => setFilters(f => ({...f, checkOut_from: undefined, checkOut_to: undefined}))}
          isLoading={isLoading}
        />




        {!hiddenFields.includes('influencer') &&
          <FormGroup className="mb--4" style={{width: 180}}>
            <UserSelect
              label="Influencer"
              user={filters?.influencer || null}
              setUser={user => changeFilters('influencer', user?.id)}
              placeholder="Search influencer"
              isDisabled={isLoading}
            />
          </FormGroup>}

        {!hiddenFields.includes('hotel') &&
        <FormGroup className="mb--4" style={{width: 240}}>
          <HotelSelect
            label="Hotel"
            hotel={filters?.hotel || null}
            setHotel={hotel => changeFilters('hotel', hotel?.id)}
            isDisabled={isLoading}
          />
        </FormGroup>}

        <FormGroup className="mb--4" style={{width: 240}}>
          <ReservationStatusFilter
            label="Status"
            status={filters?.status || null}
            setStatus={status => changeFilters('status', status)}
            isDisabled={isLoading}
          />
        </FormGroup>

        <div className="pt-3">
          <TriCheckbox
            label="Companions"
            state={filters?.withCompanions}
            changeState={s => changeFilters('withCompanions', s)}
            disabled={isLoading}
          />

          <TriCheckbox
            label="Children"
            state={filters?.withChildren}
            changeState={s => changeFilters('withChildren', s)}
            disabled={isLoading}
          />
        </div>

        <div className="pt-3">
          <TriCheckbox
            label="Mediakit sent"
            state={filters?.sentMediakitToHotel}
            changeState={s => changeFilters('sentMediakitToHotel', s)}
            disabled={isLoading}
          />
          <TriCheckbox
            label="Report sent"
            state={filters?.sentReportToHotel}
            changeState={s => changeFilters('sentReportToHotel', s)}
            disabled={isLoading}
          />
          <TriCheckbox
            label="App content updated"
            state={filters?.appPhotosUpdated}
            changeState={s => changeFilters('appPhotosUpdated', s)}
            disabled={isLoading}
          />
        </div>

      </div>


      <Table
        columns={columns}
        rows={reservations}
        onRowClick={reservation => navigate(`/dashboard/hotel/reservations/details/${reservation.id}`)}
        isLoading={isLoading}
        noContentMessage="No reservations were found"
        rowHeight={81}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={changePageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

const FiltersDateFilter = ({ label, from, to, changeFrom, changeTo, cleanFilters, isLoading }) =>
  <FormGroup>
    <Label className="form-control-label d-flex justify-content-between">
      <span>{label}</span>
      {!!(from || to) &&
        <i className="fas fa-times text-danger mr-2" onClick={cleanFilters} />
      }
    </Label>
    <div className="flex-center flex-nowrap mb--4">
      <div style={{width: 125}}>
        <FormInput.DatePicker
          key={from}
          value={from ? moment(from) : null}
          onChange={d => changeFrom(d ? d.format('YYYY-MM-DD') : undefined)}
          className="mb--4"
          timeFormat={false}
          dateFormat="DD-MM-YYYY"
          isValidDate={to ? (d =>d.isSameOrBefore(to)): undefined}
          disabled={isLoading}
        />
      </div>
      <div className="mx-2">→</div>
      <div style={{width: 125}}>
        <FormInput.DatePicker
          key={to}
          value={to ? moment(to) : null}
          onChange={d => changeTo(d ? d.format('YYYY-MM-DD') : undefined)}
          className="mb--4"
          timeFormat={false}
          dateFormat="DD-MM-YYYY"
          isValidDate={from ? (d =>d.isSameOrAfter(from)): undefined}
          disabled={isLoading}
        />
      </div>
    </div>
  </FormGroup>

const TriCheckbox = ({ label, state, changeState, disabled }) =>
  <FormGroup className="mb-0 d-flex align-items-center justify-content-end">
    <Label className="form-control-label mr-2 mb-0" check>
      {label}
    </Label>
    <Checkbox
      checked={state === true}
      indeterminate={state === false}
      onChange={() =>
        changeState(
          state === true ? false :
          state === false ? undefined :
          true
        )
      }
      disabled={disabled}
    />
  </FormGroup>


export default ReservationsTableCard;