import React, {useEffect, useMemo, useRef} from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { Button, Row, Col } from "reactstrap";
import { components } from "react-select";
import moment from "moment";

import useForm from "../hooks/useForm";

import { colorPerState } from "./utils/reservationsCalendarEvents";
import followersRangeToString from "./utils/followersRangeToString";


const UpdateReservationModal = ({ reservationToUpdate = [], availabilities = [], setReservationToUpdate, validateReservation, update, isLoading }) => {
  const { id, check_in, check_out, hotel, number, companions, state, user, availability, createdAt } = reservationToUpdate || {}

  const defaultAvailability = useMemo(() => {
    return state === 'accepted' ? availability : null
    // eslint-disable-next-line
  }, [state, id])


  const {
    handleInputChange,
    handleInputEvent
  } = useForm(setReservationToUpdate)


  const errorElement = useRef()
  const throwValidationError = (name, error) => {
    const el = document.querySelector(`[name="${name}"]`)
    el.setCustomValidity(error)
    el.reportValidity();
    errorElement.current = el
  }
  const clearError = () => {
    if(errorElement.current) {
      errorElement.current.setCustomValidity('')
      errorElement.current = undefined
    }
  }
  useEffect(() => {
    if(errorElement.current) clearError()
  }, [errorElement.current?.value])

  const validate = () => {
    if(check_in && !moment(check_in).isValid() ) {
      throwValidationError("check_in", "Min")
      return false
    }
    if(!moment(check_out).isValid()) {
      throwValidationError("check_out", "Invalid date")
      return false
    }
    if(moment(check_in).isAfter(check_out)) {
      throwValidationError("check_out", "Check out must come after check in")
      return false
    }

    return true
  }

  const handleSubmit = () => validate() && update()



  const availabilitiesOptions = useMemo(() => {
    if(reservationToUpdate) {
      const matchingAvs = (availabilities || []).filter(a =>
        moment(check_in).isSameOrAfter(a.startDate) &&
        moment(check_out).isSameOrBefore(a.endDate)
      )

      if(defaultAvailability && !matchingAvs.find(o => o.id === defaultAvailability.id))
        matchingAvs.unshift(defaultAvailability)

      return matchingAvs.map(av => {
        const { id: avId, startDate, endDate, room, maxReservations, reservations } = av
        const acceptedReservations = reservations.filter(r => r.state === 'accepted').length
        return {
          value: avId,
          label: `${avId} ${room ? room.name : ''} ${moment(startDate).format('DD/MM')} - ${moment(endDate).format('DD/MM')}`,
          item: av,
          acceptedReservations,
          isFull: maxReservations && acceptedReservations >= maxReservations,
          // isPrevious: defaultAvailability && avId === defaultAvailability.id
        }
      })
    } else return []
    // eslint-disable-next-line
  }, [id, check_in, check_out, availabilities])



  // const hasPassed = moment().set({hour:0,minute:0,second:0,millisecond:0}).isAfter(check_in)
  const disabledInputs = isLoading || id
  const hasPassed = false


  const mainSN = user?.socialNetworks && (user?.socialNetworks.find(sn => sn.main) || user?.socialNetworks[0])
  const followers = useMemo(() =>
    followersRangeToString(mainSN?.followers_range)
    // eslint-disable-next-line
  , [reservationToUpdate])


  return (
    <Modal.FormContainer
      title={!!id ?
          <>
            {number && <div style={{fontSize: 12}} className="text-muted mb-1">#{number}</div>}
            <div>Manage reservation</div>
            <div className={`font-weight-bold text-${colorPerState?.[state] || 'dark'}`}>{state}</div>
          </>
        :
          "Create reservation"
      }
      isOpen={!!reservationToUpdate}
      toggleOpen={() => setReservationToUpdate(null)}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: isLoading, className: `${id ? 'd-none' : ''}` }}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    >
      {id &&
        <div className="mb-3" style={{color: '#8898aa', fontSize: '0.875rem'}}>
          <div className="mb-1">
            <span className="form-control-label">Name:</span>
            <span className="ml-1">{user?.name + " " + user?.familyName}</span>
          </div>
          <div className="mb-1">
            <span className="form-control-label">Followers:</span>
            <span className="ml-1">{followers}</span>
          </div>
          <div className="mb-1">
            <span className="form-control-label">Hotel:</span>
            <span className="ml-1">{hotel?.name}</span>
          </div>
          {user?.country &&
            <div className="mb-1">
              <span className="form-control-label">Country:</span>
              <span className="ml-1">{user?.country}</span>
            </div>
          }
          <div className="mb-1">
            <span className="form-control-label">Booked at:</span>
            <span className="ml-1">{moment(createdAt).format('DD-MM-YYYY')}</span>
          </div>
        </div>
      }


      {/* TODO: Change this datepicker to one with range*/}
      <Row className="mb--3">
        <Col xs={12}>
          <FormInput.DatePicker
            onChange={(value) => value && handleInputChange("check_in", moment(value).format("YYYY-MM-DD"))}
            value={check_in && moment(check_in, "YYYY-MM-DD")}
            name="check_in"
            label="Check in"
            timeFormat={false}
            disabled={disabledInputs}
            isValidDate={d => moment().isBefore(d)}
            required
          />
        </Col>
        <Col xs={12}>
          <FormInput.DatePicker
            onChange={(value) => value && handleInputChange("check_out", moment(value).format("YYYY-MM-DD"))}
            value={check_out && moment(check_out, "YYYY-MM-DD")}
            name="check_out"
            label="Check out"
            timeFormat={false}
            disabled={disabledInputs}
            isValidDate={d => moment().isBefore(d)}
            required
          />
        </Col>
      </Row>


      {/*<FormInput.Select*/}
      {/*  label="Availability"*/}
      {/*  placeholder={!check_in || !check_out ? 'Please select a date range' : 'Select'}*/}
      {/*  onChange={({item}) => handleInputChange('availability', item)}*/}
      {/*  options={availabilitiesOptions}*/}
      {/*  isOptionDisabled={({value, isFull}) =>*/}
      {/*    isFull && (!defaultAvailability || value !== defaultAvailability.id) && value !== null*/}
      {/*  }*/}
      {/*  value={availability && availabilitiesOptions.find(({value}) => value === availability.id)}*/}
      {/*  isDisabled={isLoading || !check_in || !check_out}*/}
      {/*  components={{ Option: AvailabilityOption }}*/}
      {/*/>*/}

      <Row>
        <Col xs={6}>
          <FormInput.Input
            type="number"
            min={0}
            onChange={handleInputEvent}
            value={companions || 0}
            name="visitors"
            label="Companions"
            disabled={disabledInputs}
          />
        </Col>
      </Row>

      {(id && !hasPassed) && <>
        {state === "accepted" &&
          <Button
            children="Cancel"
            color={colorPerState['denied']}
            className="w-100 my-1 mx-0"
            onClick={() => validateReservation('cancelled')}
            disabled={isLoading}
          />
        }
        {(
          state === "pending" ||
          state === "denied" ||
          state === "cancelled"
        ) &&
          <Button
            children="Accept"
            color={colorPerState['accepted']}
            className="w-100 my-1 mx-0"
            onClick={() => validateReservation('accepted')}
            disabled={isLoading}
          />
        }
        {state === "pending" &&
          <Button
            children="Deny"
            color={colorPerState['denied']}
            className="w-100 my-1 mx-0"
            onClick={() => validateReservation('denied')}
            disabled={isLoading}
          />
        }
      </>}

    </Modal.FormContainer>
  );
}

const AvailabilityOption = props => {
  const { isSelected } = props
  const { label, acceptedReservations, isFull, isPrevious, item } = props.data
  const space = item?.maxReservations ? `${acceptedReservations}/${item.maxReservations}` : acceptedReservations
  const spaceBg =
    (isPrevious && !isSelected) ? 'primary' :
      isFull ? 'gray' : 'success'
  return (
    <components.Option {...props} className={isPrevious ? 'border-primary border-left border-right' : ''}>
      <div className="d-flex justify-content-between align-items-center">
        <span>{label}</span>
        {acceptedReservations >= 0 &&
          <div
            className={`bg-${spaceBg} ml-2 p-2 rounded-circle text-white flex-center`}
            style={{width: 35, height: 35, fontSize: 12}}
          >
            {space}
          </div>
        }
      </div>
    </components.Option>
  )
};


export default UpdateReservationModal;