import { useCallback, useMemo, useState } from "react";

export const defaultPagination = {
  page: 1,
  pageSize: 10,
  pageCount: 1,
  total: 0
}

const useStrapi4Pagination = (initialPagination = defaultPagination) => {
  const [pagination, setPagination] = useState(initialPagination)
  const {
    page,
    pageSize,
    pageCount,
    total
  } = pagination

  const goToPage = useCallback(page => {
    if(page > 0 && page <= pageCount)
      setPagination(p => ({...p, page}))
  }, [pageCount])

  const setPageSize = useCallback(pageSize => {
    if(pageSize > 0)
      setPagination(p =>
        ({...p, pageSize, page: 1})
      )
  }, [page, pageCount, total])

  const strapi4Params = useMemo(() => ({
    'pagination[page]': page,
    'pagination[pageSize]': pageSize
  }), [page, pageSize])

  const resetPagination = () => setPagination(defaultPagination)

  return {
    pagination,
    setPagination,
    goToPage,
    setPageSize,
    strapi4Params,
    resetPagination
  }

}

export default useStrapi4Pagination;

