import { placeRequests } from "../../../api/Api"
import useStrapi4GetList from "../../hooks/useStrapi4GetList";

import handleError from "../../utils/handleError";

const parseFilters = filters => {
  const parsedFiltersList = Object.entries(filters || {}).map(([key, val]) => {
    switch(key) {
      case 'country':
        return {
          'filters[country]': val,
          'filters[parent][id][$null]': true
        }
      case 'parent':
        return { 'filters[parent]': val }
      default:
        return {[key]: val}
    }
  })
  return Object.assign({}, ...parsedFiltersList)
}

const usePlaces = props => {
  const {
    items: places,
    refresh,
    isLoading,

    ...other
  } = useStrapi4GetList({
    ...props,
    load: loadPlaces,
    parseFilters
  })

  return {
    places,
    refresh,
    isLoading,

    ...other
  }
}

export const loadPlaces = async params => {
  try {
    return await placeRequests.getList(params)
  } catch (e) {
    handleError(e?.message || "Places loading failed")
  }
}

export default usePlaces;