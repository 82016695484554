import React, {useEffect, useMemo} from "react";
import { Container, Row, Col } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { Header } from "@general-backoffice/core/index";

import { categoryRequests } from "../../../../api/Api"
import useCategories from "../../../../components/categories/hooks/useCategories";
import useCategory from "../../../../components/categories/hooks/useCategory";
import useStrapi4Pagination from "../../../../components/hooks/useStrapi4Pagination";
import useSort from "../../../../components/hooks/useSort";

// Components
import UpdateCategoryModal from "../../../../components/categories/UpdateCategoryModal";
import DeleteCategoryModal from "../../../../components/categories/DeleteCategoryModal";
import CategoriesTableCard from "../../../../components/categories/CategoriesTableCard"



const Categories = () => {
  const {
    pagination,
    setPagination,
    goToPage,
    setPageSize,
    strapi4Params: paginationParams,
    // resetPagination
  } = useStrapi4Pagination()

  const {
    sort,
    setSort,
    strapi4Params: sortParams,
    // clearSort
  } = useSort()

  // useEffect(() => {
  //   // This is in case the component isn't rerendered when window changes
  //   resetPagination()
  //   clearSort()
  //   resetParams()
  // }, [])

  const baseFilters = { populate: '*', ...paginationParams, ...sortParams }

  const {
    categories,
    refresh: refreshCategories,
    isLoading: isLoadingCategories,
    params,
    changeParams,
    // resetParams
  } = useCategories({ params: baseFilters, setPagination })

  // eslint-disable-next-line
  useEffect(() => changeParams(baseFilters), [paginationParams, sortParams])


  const {
    category: categoryToUpdate,
    setCategory: setCategoryToUpdate,
    update: updateCategory,
    isLoading: isUpdatingCategory
  } = useCategory()

  const {
    category: categoryToDelete,
    setCategory: setCategoryToDelete,
    remove: deleteCategory,
    isLoading: isDeletingCategory
  } = useCategory()

  return (
    <React.Fragment>

      <UpdateCategoryModal
        categoryToUpdate={categoryToUpdate}
        setCategoryToUpdate={setCategoryToUpdate}
        close={() => setCategoryToUpdate(null)}
        update={() => {
          return updateCategory()
            .then(async () => {
              await refreshCategories()
              setCategoryToUpdate(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingCategories || isUpdatingCategory}
      />

      <DeleteCategoryModal
        categoryToDelete={categoryToDelete}
        close={() => setCategoryToDelete(null)}
        remove={() => {
          deleteCategory()
            .then(async () => {
              await refreshCategories()
              setCategoryToDelete(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingCategories || isDeletingCategory}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <CategoriesTableCard
              {...{
                pagination,
                goToPage,
                setPageSize,
                sort,
                setSort,
                params,
                changeParams
              }}
              categories={categories}
              setCategoryToUpdate={setCategoryToUpdate}
              setCategoryToDelete={setCategoryToDelete}
              isLoading={isLoadingCategories}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Categories;