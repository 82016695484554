
import { Navigate } from "react-router";
import Users from "../../views/pages/dashboard/users/Users"
import User from "../../views/pages/dashboard/users/User";


const subRoutes = state => [
  {
    name: "Influencers",
    path: "",
    showOnNav: true,
    component: <Users role="influencer" />
  },
  {
    name: "Influencer to validate",
    path: "pending",
    showOnNav: true,
    component: <Users role="influencer" validation="pending" />
  },
  {
    name: "Influencer",
    path: ":id",
    component: <User />
  },
  {
    path: "*",
    index: true,
    component: <Navigate to="/dashboard/influencers" replace={true}/>,
  }
]

export default subRoutes;
