import { RestApi } from "@general-backoffice/core";
import configuration from "./../config/configuration"
import parseStrapi4Response from "./utils/parseStrapi4Response";

const { apiUrl, mediaUrl } = configuration;

const Api = new RestApi({
  path: apiUrl,
  processResponse: ({ data }) => parseStrapi4Response(data, mediaUrl),
  processError: e => {throw new Error(e.response?.data?.error?.message || e.message)}
})


//  User Requests
export const login = async (identifier, password) => Api.post("/auth/local?[populate]=*", { identifier, password })
// export const me = async () => Api.get(`/users/me?[populate]=*`);
export const uploadFile = async file => {
  const form = new FormData()
  form.append('files', file, file.name.replace('ñ', 'n'))
  return Api.post(`/upload`, form);
}
export const getLocales = async () => Api.get(`/i18n/locales`);

const confirmRemoval = token => Api.post("/users/me/confirm-remove", { token })
const forgotPassword = email => Api.post(`/auth/forgot-password`, { email })
const resetPassword = (code, password, passwordConfirmation) => Api.post(`/auth/reset-password`, { code, password, passwordConfirmation })


//  Hotel Requests
const populateHotel = [
  'photos', 'logo', 'coupon', 'category', 'services', 'place',
  'manager', 'min_range', 'facilities', 'activities', 'localizations',
  'socialNetworks.social_network', 'mediaKit'
]
const getHotel = (id, params) => Api.get(`/hotels/${id}`, { params: { populate: populateHotel, ...params } });
const getHotels = params => Api.get(`/hotels`, { params: { populate: populateHotel, publicationState: 'preview', ...params} });
const createHotel = body => Api.post(`/hotels`, {data: body});
const updateHotel = (id, body) => Api.put(`/hotels/${id}`, {data: body});
const localizeHotel = (id, body) => Api.post(`/hotels/${id}/localizations`, body);

const hotelRequests = {
  defaultPopulate: populateHotel,
  get: getHotel,
  getList: getHotels,
  create: createHotel,
  update: updateHotel,
  localize: localizeHotel
}


//  Room Requests
const getRoom = (id, params) => Api.get(`/rooms/${id}`, { params: params || { populate: '*' } });
const getRooms = params => Api.get(`/rooms`, { params: params || { populate: '*' } });
const createRoom = body => Api.post(`/rooms`, {data: body});
const updateRoom = (id, body) => Api.put(`/rooms/${id}`, {data: body});
const localizeRoom = (id, body) => Api.post(`/rooms/${id}/localizations`, body);
const deleteRoom = id => Api.delete(`/rooms/${id}`);

const roomRequests = {
  get: getRoom,
  getList: getRooms,
  create: createRoom,
  update: updateRoom,
  localize: localizeRoom,
  delete: deleteRoom
}


//  RoomSize Requests
const getRoomSizes = params => Api.get(`/room-sizes`, { params: params || { populate: '*' } });

const roomSizeRequests = {
  getList: getRoomSizes,
}


//  RoomAmenity Requests
const getRoomServices = params => Api.get(`/services`, { params: params || { populate: '*' } });

const roomServiceRequests = {
  getList: getRoomServices,
}


//  Availability Requests
const getAvailability = (id, params) => Api.get(`/availabilities/${id}`, { params: params || { populate: '*' } });
const getAvailabilities = params => Api.get(`/availabilities`, { params: params || { populate: '*' } });
const createAvailability = body => Api.post(`/availabilities`, {data: body});
const updateAvailability = (id, body) => Api.put(`/availabilities/${id}`, {data: body});
const deleteAvailability = id => Api.delete(`/availabilities/${id}`);

const availabilityRequests = {
  get: getAvailability,
  getList: getAvailabilities,
  create: createAvailability,
  update: updateAvailability,
  delete: deleteAvailability
}


//  Reservation Requests
const populateReservation = ['hotel', 'user', 'availability', 'review.published_content_photos', 'review.stats_photo']
const getReservation = (id, params) => Api.get(`/reservations/${id}`, { params: { populate: populateReservation, ...params } });
const getReservations = params => Api.get(`/reservations`, { params: { populate: populateReservation, ...params } });
const updateReservation = (id, body) => Api.put(`/reservations/${id}`, {data: body});
const updateReservationState = (id, state) => Api.put(`/reservations/${id}/state`, {data: { state }});
const createReservation = body => Api.post(`/reservations`, {data: body});

const reservationRequests = {
  defaultPopulate: populateReservation,
  get: getReservation,
  getList: getReservations,
  create: createReservation,
  update: updateReservation,
  validate: updateReservationState
}


//  Review Requests
const populateReview = [
  'published_content_photos', 'stats_photo', 'reservation.user',
  'reservation.hotel', 'reservation.hotel', 'user', 'availability',
  'user.socialNetworks.followers_range', 'review.published_content_photos',
  'review.stats_photo'
]
const getReview = (id, params) => Api.get(`/reviews/${id}`, { params: params || { populate: populateReview } });
const getReviews = params => Api.get(`/reviews`, { params: params || { populate: populateReview } });
const updateReview = (id, body) => Api.put(`/reviews/${id}`, {data: body});

const promotionRequests = {
  defaultPopulate: populateReview,
  get: getReview,
  getList: getReviews,
  update: updateReview
}


//  User Requests
const getUser = (id, params) => Api.get(`/users/${id}`, { params });
// const getUsersByRole = (role, params) => Api.get(`/users/role/${role}`, { params });
const getUsers = params => Api.get(`/users`, { params });
const updateUser = (id, body) => Api.put(`/users/${id}`, body);

const userRequests = {
  get: getUser,
  // getListByRole: getUsersByRole,
  getList: getUsers,
  update: updateUser,
}


//  Places Requests
const populatePlace = ['photo', 'country', 'parent', 'localizations']
const getPlace = (id, params) => Api.get(`/places/${id}`, { params: { populate: populatePlace, ...params } });
const getPlaces = params => Api.get(`/places`, { params: { populate: populatePlace, ...params } });
const createPlace = body => Api.post(`/places`, {data: body});
const updatePlace = (id, body) => Api.put(`/places/${id}`, {data: body});
const localizePlace = (id, body) => Api.post(`/places/${id}/localizations`, body);
const deletePlace = id => Api.delete(`/places/${id}`);

const placeRequests = {
  defaultPopulate: populatePlace,
  get: getPlace,
  getList: getPlaces,
  create: createPlace,
  update: updatePlace,
  localize: localizePlace,
  delete: deletePlace
}


//  Countries Requests
const populateCountry = ['photo', 'localizations']
const getCountry = (id, params) => Api.get(`/countries/${id}`, { params: { populate: populateCountry, ...params } });
const getCountries = params => Api.get(`/countries`, { params: { populate: populateCountry, ...params } });
const updateCountry = (id, body) => Api.put(`/countries/${id}`, {data: body});
const deleteCountry = id => Api.delete(`/countries/${id}`);

const countryRequests = {
  defaultPopulate: populateCountry,
  get: getCountry,
  getList: getCountries,
  update: updateCountry,
  delete: deleteCountry
}


//  Activity Requests
const populateActivity = ['photo', 'place', 'hotels', 'video.video']
const getActivity = (id, params) => Api.get(`/activities/${id}`, { params: params || { populate: populateActivity } });
const getActivities = params => Api.get(`/activities`, { params: params || { populate: populateActivity } });
const createActivity = body => Api.post(`/activities`, {data: body});
const updateActivity = (id, body) => Api.put(`/activities/${id}`, {data: body});
const deleteActivity = id => Api.delete(`/activities/${id}`);

const activityRequests = {
  defaultPopulate: populateActivity,
  get: getActivity,
  getList: getActivities,
  create: createActivity,
  update: updateActivity,
  delete: deleteActivity
}


//  Category Requests
const getCategory = (id, params) => Api.get(`/categories/${id}`, { params: params || { populate: '*' } });
const getCategories = params => Api.get(`/categories`, { params: params || { populate: '*' } });
const createCategory = body => Api.post(`/categories`, {data: body});
const updateCategory = (id, body) => Api.put(`/categories/${id}`, {data: body});
const deleteCategory = id => Api.delete(`/categories/${id}`);

const categoryRequests = {
  get: getCategory,
  getList: getCategories,
  create: createCategory,
  update: updateCategory,
  delete: deleteCategory
}


//  Service Requests
const populateService = ['localizations']
const getService = (id, params) => Api.get(`/services/${id}`, { params: params ||  { populate: populateService } });
const getServices = params => Api.get(`/services`, { params: params || { populate: populateService } });
const createService = body => Api.post(`/services`, {data: body});
const updateService = (id, body) => Api.put(`/services/${id}`, {data: body});
const localizeService = (id, body) => Api.post(`/services/${id}/localizations`, body);
const deleteService = id => Api.delete(`/services/${id}`);

const serviceRequests = {
  defaultPopulate: populateService,
  get: getService,
  getList: getServices,
  create: createService,
  update: updateService,
  localize: localizeService,
  delete: deleteService
}


//  Advantage Requests
const populateAdvantage = ['localizations']
const getAdvantage = (id, params) => Api.get(`/facilities/${id}`, { params: params ||  { populate: populateAdvantage } });
const getAdvantages = params => Api.get(`/facilities`, { params: params || { populate: populateAdvantage } });
const createAdvantage = body => Api.post(`/facilities`, {data: body});
const updateAdvantage = (id, body) => Api.put(`/facilities/${id}`, {data: body});
const localizeAdvantage = (id, body) => Api.post(`/facilities/${id}/localizations`, body);
const deleteAdvantage = id => Api.delete(`/facilities/${id}`);

const advantageRequests = {
  defaultPopulate: populateAdvantage,
  get: getAdvantage,
  getList: getAdvantages,
  create: createAdvantage,
  update: updateAdvantage,
  localize: localizeAdvantage,
  delete: deleteAdvantage
}


//  Notification Requests
const populateNotification = ['sender', 'receiver']
const getNotification = (id, params) => Api.get(`/notifications/${id}`, { params: params ||  { populate: populateNotification } });
const getNotifications = params => Api.get(`/notifications`, { params: params || { populate: populateNotification } });
const sendNotification = body => Api.post(`/notifications/send`, {data: body});

const notificationRequests = {
  defaultPopulate: populateNotification,
  get: getNotification,
  getList: getNotifications,
  send: sendNotification,
}


//  Category Requests
const getFollowersRange = () => Api.get(`/followers-ranges`);
const followersRangeRequests = {
  getList: getFollowersRange,
}


//  ServicePhoto Requests
const populateServicePhoto = ['localizations', 'photo']
const getServicePhoto = (id, params) => Api.get(`/service-photos/${id}`, { params: params ||  { populate: populateServicePhoto } });
const getServicePhotos = params => Api.get(`/service-photos`, { params: params || { populate: populateServicePhoto } });
const createServicePhoto = body => Api.post(`/service-photos`, {data: body});
const updateServicePhoto = (id, body) => Api.put(`/service-photos/${id}`, {data: body});
const localizeServicePhoto = (id, body) => Api.post(`/service-photos/${id}/localizations`, body);
const deleteServicePhoto = id => Api.delete(`/service-photos/${id}`);

const servicePhotoRequests = {
  defaultPopulate: populateServicePhoto,
  get: getServicePhoto,
  getList: getServicePhotos,
  create: createServicePhoto,
  update: updateServicePhoto,
  localize: localizeServicePhoto,
  delete: deleteServicePhoto
}


//  SocialNetwork Requests
const populateSocialNetwork = []
const getSocialNetworks = params => Api.get(`/social-networks`, { params: params || { populate: populateServicePhoto } });

const socialNetworkRequests = {
  defaultPopulate: populateSocialNetwork,
  getList: getSocialNetworks,
}


//  GeneralConfig Requests
const populateGeneralConfig = []
const getGeneralConfig = params => Api.get(`/general-config`, { params: params || { populate: populateGeneralConfig } });

const generalConfigRequests = {
  defaultPopulate: populateGeneralConfig,
  get: getGeneralConfig,
}


export {
  forgotPassword,
  resetPassword,
  confirmRemoval,
  hotelRequests,
  roomRequests,
  roomSizeRequests,
  roomServiceRequests,
  availabilityRequests,
  reservationRequests,
  promotionRequests,
  userRequests,
  placeRequests,
  countryRequests,
  activityRequests,
  categoryRequests,
  serviceRequests,
  advantageRequests,
  notificationRequests,
  followersRangeRequests,
  servicePhotoRequests,
  socialNetworkRequests,
  generalConfigRequests
}
export default Api;