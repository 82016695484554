import kFormatter from "../../utils/kFormatter";

const followersRangeToString = range => {
  const {min, max} = range || {}
  return range && (
    max ?
      `${kFormatter(min)} - ${kFormatter(max)}`
    :
      `+${kFormatter(min)}`
  )
}

export default followersRangeToString