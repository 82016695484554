import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

import useHotels from "../../../../components/hotels/hooks/useHotels";

// Components
import HotelsTableCard from "../../../../components/hotels/HotelsTableCard"
import {useNavigate} from "react-router";


const Hotels = () => {
  const navigate = useNavigate()

  const {
    hotels,
    isLoading: isLoadingHotels,

    filters,
    changeFilters,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort
  } = useHotels({
    filters: true,
    sort: { publishedAt: 'down' },
    pagination: true,
    onLoadError: () => navigate('/dashboard', { replace: true }),
  })

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <HotelsTableCard
              {...{
                filters,
                changeFilters,
                pagination,
                goToPage,
                changePageSize,
                sort,
                changeSort
              }}
              hotels={hotels}
              filtersToShow={["isCurrentlyAvailable"]}
              isLoading={isLoadingHotels}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Hotels;