import React from "react";
import { Modal } from "@general-backoffice/core";


const DeletePlaceModal = ({ placeToDelete, close, remove, isLoading }) => {
  return (
    <Modal.DeleteConfirm
      isOpen={!!placeToDelete}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="Delete place"
      descriptionDelete={<div>Place <span className="font-weight-bold">"{placeToDelete?.name}"</span> will be deleted.</div>}
      closeText="Cancel"
      buttonDelete={{ children: "Delete", onClick: remove }}
    />
  );
}

export default DeletePlaceModal;