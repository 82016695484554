import React from 'react';


import Airplane from '../../media/icons/airplane.svg';
import Alarm from '../../media/icons/alarm.svg';
import Bathtub from '../../media/icons/bathtub.svg';
import Bed from '../../media/icons/bed.svg';
import Bell from '../../media/icons/bell.svg';
import Bicycle from '../../media/icons/bicycle.svg';
import Briefcase from '../../media/icons/briefcase.svg';
import Building from '../../media/icons/building.svg';
import Bus from '../../media/icons/bus.svg';
import Calendar from '../../media/icons/calendar.svg';
import CalendarAdd from '../../media/icons/calendarAdd.svg';
import CalendarCheck from '../../media/icons/calendarCheck.svg';
import CalendarDelete from '../../media/icons/calendarDelete.svg';
import CalendarRemove from '../../media/icons/calendarRemove.svg';
import Car from '../../media/icons/car.svg';
import Chair from '../../media/icons/chair.svg';
import Clock from '../../media/icons/clock.svg';
import Cloud from '../../media/icons/cloud.svg';
import Cloudy from '../../media/icons/cloudy.svg';
import Cocktail from '../../media/icons/cocktail.svg';
import Coffee from '../../media/icons/coffee.svg';
import Compass from '../../media/icons/compass.svg';
import CreditCard from '../../media/icons/creditCard.svg';
import CrownIcon from '../../media/icons/crown.svg';
import Dashboard from '../../media/icons/dashboard.svg';
import Direction from '../../media/icons/direction.svg';
import Direction1 from '../../media/icons/direction1.svg';
import DirectionAlt from '../../media/icons/directionAlt.svg';
import Drawer from '../../media/icons/drawers.svg';
import Factory from '../../media/icons/factory.svg';
import ForkKnife from '../../media/icons/forkKnife.svg';
import Gift from '../../media/icons/gift.svg';
import Globe from '../../media/icons/globe.svg';
import Handbag from '../../media/icons/handbag.svg';
import Hanger from '../../media/icons/hanger.svg';
import Hook from '../../media/icons/hook.svg';
import House from '../../media/icons/house.svg';
import Map from '../../media/icons/map.svg';
import Moon from '../../media/icons/moon.svg';
import Pin from '../../media/icons/pin.svg';
import Pin2 from '../../media/icons/pin2.svg';
import Pin3 from '../../media/icons/pin3.svg';
import Rain from '../../media/icons/rain.svg';
import Safe from '../../media/icons/safe.svg';
import Sandglass from '../../media/icons/sandglass.svg';
import Ship from '../../media/icons/ship.svg';
import ShoppingBasket from '../../media/icons/shoppingBasket.svg';
import ShoppingCart from '../../media/icons/shoppingCart.svg';
import Shovel from '../../media/icons/shovel.svg';
import Shower from '../../media/icons/shower.svg';
import SoapDispenser from '../../media/icons/soapDispenser.svg';
import Sofa from '../../media/icons/sofa.svg';
import Star from '../../media/icons/star.svg';
import Store from '../../media/icons/store.svg';
import StreetSign from '../../media/icons/streetSign.svg';
import Suitcase from '../../media/icons/suitcase.svg';
import Sun from '../../media/icons/sun.svg';
import Timer from '../../media/icons/timer.svg';
import Towel from '../../media/icons/towel.svg';
import Train from '../../media/icons/train.svg';
import Wallet from '../../media/icons/wallet.svg';
import World from '../../media/icons/world.svg';


const icons = {
  'crown': CrownIcon,
  'timer':  Timer,
  'cocktail': Cocktail,
  'towel': Towel,
  'gift': Gift,
  'car': Car,
  'bed': Bed,
  'airplane': Airplane,
  'alarm': Alarm,
  'bathtub': Bathtub,
  'bell': Bell,
  'bicycle': Bicycle,
  'briefcase': Briefcase,
  'building': Building,
  'bus': Bus,
  'calendarAdd': CalendarAdd,
  'calendarCheck': CalendarCheck,
  'calendarDelete': CalendarDelete,
  'calendarRemove': CalendarRemove,
  'calendar': Calendar,
  'chair': Chair,
  'clock': Clock,
  'cloud': Cloud,
  'cloudy': Cloudy,
  'coffee': Coffee,
  'compass': Compass,
  'creditCard': CreditCard,
  'dashboard': Dashboard,
  'directionAlt': DirectionAlt,
  'direction': Direction,
  'direction1': Direction1,
  'drawer': Drawer,
  'factory': Factory,
  'fork&knife': ForkKnife,
  'globe': Globe,
  'handbag': Handbag,
  'hanger': Hanger,
  'hook': Hook,
  'house': House,
  'map': Map,
  'moon': Moon,
  'pin1': Pin,
  'pin2': Pin2,
  'pin3': Pin3,
  'rain': Rain,
  'safe': Safe,
  'sandglass': Sandglass,
  'ship': Ship,
  'shoppingBasket': ShoppingBasket,
  'shoppingCart': ShoppingCart,
  'shovel': Shovel,
  'shower': Shower,
  'soapDispenser': SoapDispenser,
  'sofa': Sofa,
  'star': Star,
  'store': Store,
  'streetSign': StreetSign,
  'suitcase': Suitcase,
  'sun': Sun,
  'train': Train,
  'wallet': Wallet,
  'world': World
}

export const Icon = ({name, text, ...params}) =>
  icons?.[name] ? (text ?
    <div className="d-flex align-items-center">
      <img {...params} src={icons[name]} alt={name}/>
      <span>{text}</span>
    </div>
  :
    <img {...params} src={icons[name]} alt={name}/>
  ) : null


export default icons