import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import { userRequests } from "../../../api/Api"
import useStrapi4Params from "../../hooks/useStrapi4Params";


const useUsers = ({ params: initialParams } = {}) => {
  const { params, changeParams, resetParams } = useStrapi4Params(initialParams)
  const [users, setUsers] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh(params).catch(() => null)
    // eslint-disable-next-line
  }, [params])

  const refresh = async params => {
    setLoading(true)
    return loadUsers(params)
      .then(users => {
        setUsers(users)
        return users
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    users,
    refresh,
    isLoading,

    params,
    changeParams,
    resetParams
  }
}

export const loadUsers = async params => {
  try {
    return await userRequests.getList(params)
  } catch (e) {
    const message = (e?.message || "Users loading failed")
    notify.error(message)
    throw new Error(message)
  }
}



export default useUsers;