import { useEffect, useState } from "react";

const useStrapi4GetOne = ({ id, params, loadItem, submitItem, deleteItem, onLoadError }) => {
  const [item, setItem] = useState()
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(id) load(id).catch(() => null)
  }, [id])

  const load = newId => {
    if(!loadItem || (!id && !newId)) return
    setLoading(true)
    return loadItem(newId || id, params)
      .then(([item]) => {
        setItem(item)
        return item
      })
      .catch(e => onLoadError?.(e))
      .finally(() => setLoading(false))
  }

  const update = newItem => {
    if (!submitItem || !item || isLoading) return;
    setLoading(true)
    return submitItem(newItem || item)
      .finally(() => setLoading(false))
  }

  const remove = () => {
    if (!deleteItem || !item || isLoading) return;
    setLoading(true)
    return deleteItem(item)
      .finally(() => setLoading(false))
  }


  return {
    item,
    setItem,
    isLoading,
    setLoading,
    load,
    update,
    remove,
  }
}


export default useStrapi4GetOne;