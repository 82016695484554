import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import {Col, Row} from "reactstrap";

import useForm from "../hooks/useForm";
import useLang from "../hooks/useLang";
import {FormDecoration} from "@general-backoffice/core/index";
import IconSelect from "../shared/IconSelect";


const UpdateAdvantageModal = ({ advantageToUpdate, setAdvantageToUpdate, close, update, isLoading }) => {
  const { id, icon, locale, localizations } = advantageToUpdate || {}

  const {
    lang,
    LangSelector,
    isLoading: isLoadingLang
  } = useLang({
    loaded: !!advantageToUpdate,
    locale,
    setLocale: locale => handleInputChange('locale', locale)
  })

  const localization = localizations?.[lang]
  const { name, description } = localization || {}

  const {
    handleInputChange,
    handleLocalizedInputEvent
  } = useForm(setAdvantageToUpdate, lang)


  const areLoading = isLoading || isLoadingLang
  return (
    <Modal.FormContainer
      title={!!id ? `Edit "${name}"` : "Create advantage"}
      isOpen={!!advantageToUpdate}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: areLoading }}
      isLoading={areLoading}
      onSubmit={update}
    >
      <Row>
        <Col className="pr-0">
          <FormInput.Input
            onChange={handleLocalizedInputEvent}
            value={name}
            name="name"
            label="Name"
            disabled={areLoading}
            required
          />
        </Col>
        <Col xs="auto" className="flex-center pt-1">
          <LangSelector />
        </Col>
      </Row>

      <FormInput.Input
        // type="textarea"
        // rows="7"
        name="description"
        label="Description"
        value={description}
        onChange={handleLocalizedInputEvent}
        disabled={areLoading}
      />

      <IconSelect
        icon={icon}
        setIcon={icon => handleInputChange('icon', icon)}
        isLoading={areLoading}
        required
      />

    </Modal.FormContainer>
  );
}

export default UpdateAdvantageModal;