export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CHANGE_HOTEL = "CHANGE_HOTEL";

export const login = (token, user) => ({
  type: LOGIN,
  payload: { token, user }
});

export const logout = () => ({
  type: LOGOUT,
  payload: null
});

export const setHotel = (hotel, hotels) => ({
  type: CHANGE_HOTEL,
  payload: { hotel, hotels }
});