import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useSearchParams } from "react-router-dom";

import useReservations from "../../../../components/reservations/hooks/useReservations";

// Components
import ReservationsTableCard from "../../../../components/reservations/ReservationsTableCard";



const Reservations = ({ hotel }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  // Parse initial filters coming from search params
  const initialParams = Object.entries(Object.fromEntries(searchParams)).reduce((r, [k, v]) => {
    if(v !== 'undefined') {
      if(v === 'true') r[k] = true
      else if(v === 'false') r[k] = false
      else if(v === 'null') r[k] = null
      else r[k] = v
    }
    return r
  }, {})


  const {
    reservations,
    refresh: refreshReservations,
    isLoading: isLoadingReservations,

    filters,
    setFilters,
    changeFilters,
    goToPage,
    changePageSize,
    pagination,
    sort,
    changeSort,
    setSort
  } = useReservations({
    filters: {
      ...(hotel && { hotel: hotel?.id }),
      ...initialParams
    },
    sort: true,
    pagination: { pageSize: 50 },
    depsToReset: [hotel]
  })

  // Sync up search params when filters change
  useEffect(() => {
    setSearchParams(Object.entries(filters)
      .filter(([key, value]) => value !== undefined)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {}))
  }, [filters])


  // const initialStatus = searchParams.get('status')




  // const {
  //   reservation: reservationToUpdate,
  //   setReservation: setReservationToUpdate,
  //   update: updateReservation,
  //   isLoading: isUpdatingReservation
  // } = useReservation()


  // const {
  //   availabilities,
  //   refresh: refreshAvailabilities,
  //   isLoading: isLoadingAvailabilities
  // } = useAvailabilities(null)


  return (
    <React.Fragment>

      {/*<UpdateReservationModal*/}
      {/*  reservationToUpdate={reservationToUpdate}*/}
      {/*  availabilities={availabilities}*/}
      {/*  setReservationToUpdate={setReservationToUpdate}*/}
      {/*  update={() =>*/}
      {/*    updateReservation()*/}
      {/*      .then(async () => {*/}
      {/*        await refreshReservations()*/}
      {/*        await refreshAvailabilities()*/}
      {/*        await setReservationToUpdate(null)*/}
      {/*      })*/}
      {/*      .catch(() => null)*/}
      {/*  }*/}
      {/*  isLoading={isLoadingReservations || isLoadingAvailabilities || isUpdatingReservation}*/}
      {/*/>*/}

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <ReservationsTableCard
              {...{
                filters,
                setFilters,
                changeFilters,
                pagination,
                goToPage,
                changePageSize,
                sort,
                changeSort
              }}
              clearFilters={() => setFilters(hotel ? { hotel: hotel?.id } : {})}
              reservations={reservations}
              // setReservationToUpdate={setReservationToUpdate}
              isLoading={isLoadingReservations}
              subtitle={hotel && reservations?.[0]?.hotel?.name}
              hiddenFields={hotel ? ['hotel'] : []}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Reservations;