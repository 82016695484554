import isoCountriesLanguages from "@hotosm/iso-countries-languages";
import allCountries from 'country-region-data/data.json';

const getCountries = lang => {
  // const parsedLanguage = lang.split("-")[0]
  let countries = isoCountriesLanguages.getCountries(lang) || isoCountriesLanguages.getCountries("en")
  countries.GN = `${countries?.GV}`
  countries.GW = `${countries.PU}`
  delete countries.GV
  delete countries.PU


  return Object.entries(countries)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map(([shortCode, name]) => {
      const country = allCountries.find(({ countryShortCode }) => countryShortCode === shortCode)

      return { ...country, shortCode, name }
    })
}

export default getCountries