import React from "react";
import { Card } from "reactstrap";
import { Table, TableCard } from "@general-backoffice/core";

import AddButtonChildren from "../shared/AddButtonChildren";
import moment from "moment/moment";


const NotificationsTableCard = ({ notifications = [], setNotificationToSend, isLoading, pagination, goToPage, setPageSize, sort, setSort, params, changeParams }) => {

  const columns = [
    {
      name: "Sender",
      formattedContent: ({ sender }) => sender && `${sender?.name} ${sender?.familyName || ''}`,
    },
    {
      name: "Receiver",
      formattedContent: ({ receiver }) => receiver && `${receiver?.name} ${receiver?.familyName || ''}`,
    },
    {
      name: "Title",
      property: 'body.notification.title',
      isTh: true,
    },
    {
      name: "Body",
      property: 'body.notification.body',
    },
    {
      name: "Date",
      formattedContent: ({ sentDate }) => moment(sentDate).format('DD-MM-YYYY'),
      sort: d => setSort('sentDate', d),
      sortStatus: sort?.['sentDate']
    }
  ]

  return (
    <Card className="overflow-hidden position-relative">
      <TableCard.Header
        title="Notifications"
        isLoading={isLoading}
        buttons={[
          {
            children: <AddButtonChildren children="Notification"/>,
            onClick: () => setNotificationToSend({}),
            color: 'primary'
          }
        ]}
      />
      <Table
        columns={columns}
        rows={notifications}
        isLoading={isLoading}
        noContentMessage="No notifications were found"
        rowHeight={81}
        rowAmount={pagination?.pageSize}
      />
      <TableCard.Footer
        amount={pagination?.pageSize}
        setAmount={setPageSize}
        totalEntries={pagination?.total}
        page={pagination?.page-1}
        onChangePage={page => goToPage(page+1)}
        isLoading={isLoading}
      />
    </Card>
  );
}

export default NotificationsTableCard;