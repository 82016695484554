import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import { categoryRequests } from "../../../api/Api"

import handleError from "../../utils/handleError";
import { getFileId } from "../../hooks/useFileInput";
import {submitLocalizations} from "../../utils/localizations";


const useCategory = id => {
  const [category, setCategory] = useState(null);
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    if(id) load(id).catch(() => null)
  }, [id])


  const load = async id => {
    setLoading(true)
    return loadCategory(id)
      .then(([category]) => {
        setCategory(category)
        return category
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = () => {
    if (!category || isLoading) return;
    setLoading(true)
    return submitCategory(category)
      .finally(() =>
        setLoading(false)
      )
  }

  const remove = async () => {
    if (!category || isLoading) return;
    setLoading(true)
    return deleteCategory(category)
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    category,
    setCategory,
    load,
    update,
    remove,
    isLoading
  }
}


export const loadCategory = async id => {
  try {
    return await categoryRequests.get(id)
  } catch (e) {
    const message = (e?.message || "Category loading failed")
    notify.error(message)
    throw new Error(message)
  }
}


export const submitCategory = async category => {
  const isNew = !category?.id

  const errMsg = `Category ${isNew ? 'creation' : 'modification'} failed`
  if (!category) throw new Error(errMsg)
  try {
    const { id, photo, localizations, ...props } = category

    // Remove fields that shouldn't be changed from here
    delete props.hotels

    const body = {
      photo: await getFileId(photo),
      ...props
    }

    const [res] = isNew ?
      await categoryRequests.create(body)
      :
      await categoryRequests.update(id, body)

    // Update or create every localization needed
    const defaultId = isNew ? res.id : id
    await submitLocalizations(localizations, defaultId, categoryRequests.update, categoryRequests.localize)

    notify.success(`Category ${isNew ? 'created' : 'modified'} correctly`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export const deleteCategory = async category => {
  const errMsg = "Error while deleting category"
  if (!category?.id) throw new Error(errMsg)
  try {
    await categoryRequests.delete(category.id)
    notify.success("Category removed correctly")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}



export default useCategory;