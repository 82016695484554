import React, { useEffect, useMemo, useState } from "react";
import { Modal, FormInput, FormDecoration } from "@general-backoffice/core";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import _ from "lodash";
import moment from "moment";

import { useMultiFileInput } from "../hooks/useFileInput";
import { updateUser } from "./hooks/useUser";
import useForm from "../hooks/useForm";

import SortableFilesPreview from "../shared/SortableFilesPreview";
import getCountries from "../utils/getCountries";
import socialNetworksIcons from "../utils/socialNetworksIcons";



const snOptions = Object.entries(socialNetworksIcons).map(([value, icon]) =>
  ({ value, label: <div className="text-center"><i className={icon}/></div>})
)

const UpdateMediakitModal = ({ isOpen, close, onMediakitUpdate, mediakit: currMediakit = {}, user, isLoading: isLoadingOutside }) => {
  const [isLoading, setLoading] = useState(false);
  const [mediakit, setMediakit] = useState({});
  const {
    name,
    surnames,
    photos,
    details,
    comments
  } = mediakit || {}
  const {
    country,
    region,
    category,
    travelCategories = [],
    socialNetworks = [],
    stats,
    demographic,
    lastUpdate
  } = details || {}
  const {
    perCountry = [],
    perGender,
    perAge
  } = demographic || {}

  const {
    handleInputEvent,
    handleInputChange
  } = useForm(setMediakit)

  useEffect(() => {
    setMediakit(currMediakit || {})
  }, [currMediakit, isOpen])

  const {
    files: photosFiles,
    isLoading: isLoadingPhotosFiles
  } = useMultiFileInput(photos)


  const countriesOptions = useMemo(() => {
    return getCountries("en").map(({ name, shortCode, ...other }) => ({ ...other, label: name, value: shortCode }))
    // eslint-disable-next-line
  }, [])
  // eslint-disable-next-line
  const currCountry = useMemo(() => countriesOptions.find(({ value }) => value === country), [country])
  // eslint-disable-next-line
  const regionOptions = useMemo(() => currCountry?.regions.map(({ name }) => ({ label: name, value: name })), [currCountry])
  // eslint-disable-next-line
  const currRegion = useMemo(() => region ? regionOptions.find(({ value }) => value === region) : null, [regionOptions, currCountry, region])

  const update = () => {
    if (!user || !mediakit || isLoading) return;
    setLoading(true)
    mediakit.details.lastUpdate = moment.utc().toISOString()
    return updateUser({ id: user?.id, mediakitInfo: mediakit })
      .then(() => {
        onMediakitUpdate(mediakit)
        close()
      })
      .catch(() => null)
      .finally(() => setLoading(false))
  }

  const areLoading = isLoadingOutside || isLoading
  return (
    <Modal.FormContainer
      title={<>
        <div>Edit mediakit</div>
        {lastUpdate && <small>Last updated {moment(lastUpdate).lang("en").fromNow()}</small>}
      </>}
      isOpen={isOpen}
      toggleOpen={close}
      closeText="Close"
      buttonSave={{ children: "Save", disabled: areLoading }}
      isLoading={areLoading}
      size="md"
      onSubmit={update}
    >
      <FormInput.Input
        onChange={handleInputEvent}
        value={name}
        name="name"
        label="Name *"
        disabled={areLoading}
        required
      />

      <FormInput.Input
        onChange={handleInputEvent}
        value={surnames}
        name="surnames"
        label="Family name"
        disabled={areLoading}
      />

      <FormInput.MultiDropzone
        label="Photos"
        accept="image/*"
        onDrop={([file]) => handleInputChange('photos', [...photosFiles, file])}
        disabled={areLoading || isLoadingPhotosFiles}
      />
      <SortableFilesPreview
        files={photosFiles}
        onSort={(oldI, newI) =>
          setMediakit(m => {
            const photosCopy = _.cloneDeep(m.photos)
            photosCopy.splice(newI, 0, photosCopy.splice(oldI, 1)[0])
            return {...m, photos: photosCopy}
          })
        }
        buttons={[
          {
            color: "primary",
            onClick: file => window.open(file?.strapiFile?.url || file?.preview,'_newtab'),
            children: <i className="fas fa-link"/>
          },
          {
            color: "danger",
            onClick: (file, i) => {
              const photosCopy = _.cloneDeep(photos)
              photosCopy.splice(i, 1)
              handleInputChange('photos', photosCopy)
            },
            children: <i className="fas fa-trash"/>
          }
        ]}
        disabled={areLoading || isLoadingPhotosFiles}
      />

      <FormInput.Select
        label="Country *"
        value={currCountry}
        options={countriesOptions}
        onChange={({ value }) => handleInputChange("details", { ...details, country: value, region: null })}
        isDisabled={areLoading}
        required
      />

      <FormInput.Select
        label="Region *"
        value={currRegion}
        options={regionOptions}
        onChange={({ value }) => handleInputChange('details.region', value)}
        isDisabled={areLoading || !regionOptions}
        required
      />

      <FormInput.Input
        onChange={handleInputEvent}
        value={category}
        name="details.category"
        label="Category"
        disabled={areLoading}
      />

      <FormInput.Select
        label="Travel categories"
        value={travelCategories.map(c => ({ value: c, label: c }))}
        onChange={c => handleInputChange('details.travelCategories', c.map(c => c.value))}
        onCreateOption={c => handleInputChange('details.travelCategories', [...travelCategories, c])}
        isCreatable
        isClearable
        isMulti
        isDisabled={areLoading}
      />

      <FormGroup>
        <Label className="form-control-label">
          Social networks
        </Label>
        <table cellSpacing="0" cellPadding="0" width="100%" className="h5">
          {!!socialNetworks?.length &&
            <tr className="pb-3">
              <th>SN *</th>
              <th className="px-2">Followers *</th>
              <th>Handle</th>
              <th/>
            </tr>
          }
          {socialNetworks.map((sn, i) =>
            <tr key={i}>
              <td width={90}>
                <FormInput.Select
                  placeholder=""
                  className="mb--3"
                  value={sn?.type && snOptions.find(snO => snO.value === sn.type)}
                  options={snOptions}
                  onChange={t => handleInputChange(`details.socialNetworks[${i}].type`, t.value)}
                  isDisabled={areLoading}
                  isSearchable={false}
                  required
                />
              </td>
              <td width={150} className="px-2">
                <FormInput.Input
                  className="mb--3"
                  type="number"
                  onChange={handleInputEvent}
                  value={sn?.followers}
                  name={`details.socialNetworks[${i}].followers`}
                  disabled={areLoading}
                  required
                />
              </td>
              <td>
                <FormInput.Input
                  className="mb--3"
                  onChange={handleInputEvent}
                  value={sn?.handle}
                  name={`details.socialNetworks[${i}].handle`}
                  disabled={areLoading}
                />
              </td>
              <td>
                <div
                  className="mt--2 text-danger cursor-pointer p-2"
                  onClick={() => {
                    socialNetworks.splice(i, 1)
                    handleInputChange(`details.socialNetworks`, socialNetworks)
                  }}
                ><i className="fas fa-times"/></div>
              </td>
            </tr>
          )}
        </table>
        <Button
          color="primary"
          className="w-100 m-0 p-2"
          outline
          onClick={() => handleInputChange('details.socialNetworks', [...socialNetworks, {}])}
        ><i className="fas fa-plus"/></Button>
      </FormGroup>


      <FormDecoration.LineTitle title="Statistics"/>

      <Row className="p-2">
        <Col xs={6} md={6} className="p-2">
          <FormInput.Input
            type="number"
            label="Total followers"
            name="details.stats.totalFollowers"
            value={stats?.totalFollowers}
            onChange={handleInputEvent}
            disabled={areLoading}
          />
        </Col>
        <Col xs={6} md={6} className="p-2">
          <FormInput.Input
            type="number"
            label="Monthly reach"
            name="details.stats.monthlyReach"
            value={stats?.monthlyReach}
            onChange={handleInputEvent}
            disabled={areLoading}
          />
        </Col>
        <Col xs={6} md={6} className="p-2">
          <FormInput.Input
            type="number"
            label="Monthly impressions"
            name="details.stats.monthlyImpressions"
            value={stats?.monthlyImpressions}
            onChange={handleInputEvent}
            disabled={areLoading}
          />
        </Col>
        <Col xs={6} md={6} className="p-2">
          <FormInput.Input
            type="number"
            step=".01"
            label="Engagement rate (%)"
            name="details.stats.engagementRate"
            value={stats?.engagementRate}
            onChange={handleInputEvent}
            disabled={areLoading}
          />
        </Col>
        <Col xs={4} className="p-2">
          <FormInput.Input
            label="Stories reach"
            name="details.stats.storiesReach"
            value={stats?.storiesReach}
            onChange={handleInputEvent}
            disabled={areLoading}
          />
        </Col>
        <Col xs={4} className="p-2">
          <FormInput.Input
            type="number"
            label="Posts reach"
            name="details.stats.postsReach"
            value={stats?.postsReach}
            onChange={handleInputEvent}
            disabled={areLoading}
          />
        </Col>
        <Col xs={4} className="p-2">
          <FormInput.Input
            type="number"
            label="Videos reach"
            name="details.stats.videosReach"
            value={stats?.videosReach}
            onChange={handleInputEvent}
            disabled={areLoading}
          />
        </Col>
      </Row>

      <FormDecoration.LineTitle title="Demographic"/>

      <FormGroup>
        <Label className="form-control-label">
          Per country
        </Label>
        <table cellSpacing="0" cellPadding="0" width="100%" className="h5">
          {!!perCountry?.length &&
            <tr className="pb-3">
              <th>Country *</th>
              <th className="px-2">% *</th>
              <th/>
            </tr>
          }
          {(perCountry || []).map(({ country, percentage }, i) =>
            <tr key={i}>
              <td style={{ width: 250, minWidth: 180}}>
                <FormInput.Select
                  placeholder=""
                  className="mb--3"
                  value={countriesOptions.find(c => c.value === country)}
                  options={countriesOptions.filter(o => !perCountry?.[o.value])}
                  onChange={({ value }) => handleInputChange(`details.demographic.perCountry[${i}].country`, value)}
                  isDisabled={areLoading}
                  required
                />
              </td>
              <td className="px-2" style={{ minWidth: 120 }}>
                <FormInput.Input
                  type="number"
                  step=".01"
                  className="mb--3"
                  onChange={handleInputEvent}
                  value={percentage}
                  name={`details.demographic.perCountry[${i}].percentage`}
                  disabled={areLoading}
                  required
                />
              </td>
              <td>
                <div
                  className="mt--2 text-danger cursor-pointer p-2"
                  onClick={() => {
                    perCountry.splice(i, 1)
                    handleInputChange(`details.demographic.perCountry`, perCountry)
                  }}
                ><i className="fas fa-times"/></div>
              </td>
            </tr>
          )}
        </table>
        <Button
          color="primary"
          className="w-100 m-0 p-2"
          outline
          onClick={() => handleInputChange('details.demographic.perCountry', [...perCountry, {}])}
        ><i className="fas fa-plus"/></Button>
      </FormGroup>


      <FormInput.Range
        label="Per gender"
        description={<div className="flex-center justify-content-between px-1">
          <div>Female <b>({perGender?.female || " -- "} %)</b></div>
          <div><b>({perGender?.male || " -- "} %)</b> Male</div>
        </div>}
        value={perGender?.female}
        onChange={e => handleInputChange(
          "details.demographic.perGender",
          { female: e.target.value, male: 100 - e.target.value}
        )}
        disabled={areLoading}
        required
      />
      {/*<FormGroup>*/}
      {/*  <Label className="form-control-label">*/}
      {/*    */}
      {/*  </Label>*/}
      {/*  <div className="flex-center justify-content-between">*/}
      {/*    <Label>Male *</Label>*/}
      {/*  </div>*/}
      {/*  <div className="flex-center justify-content-between">*/}
      {/*    <Label>Female *</Label>*/}
      {/*  </div>*/}
      {/*</FormGroup>*/}

      <FormGroup>
        <Label className="form-control-label">
          Per age (%)
        </Label>
        {["18-24", "25-34", "35-44", "45-54", "55-64"].map((age, i) =>
          <div className="w-100 d-flex align-items-center">
            <span className="h5 mx-3">Ages {age}</span>
            <FormInput.Input
              type="number"
              step=".01"
              className="mb--3 py--3"
              style={{ width: 150 }}
              onChange={handleInputEvent}
              value={perAge?.[age]}
              name={`details.demographic.perAge[${age}]`}
              disabled={areLoading}
            />
          </div>
        )}
      </FormGroup>

      <FormDecoration.Line/>

      <FormInput.Quill
        label="Comments"
        value={comments || ""}
        onChange={v => handleInputChange("comments", v)}
        disabled={areLoading}
      />

    </Modal.FormContainer>
  );
}

export default UpdateMediakitModal;