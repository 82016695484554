import { useState, useEffect } from "react";
import { notify } from "@general-backoffice/core";
import { useSelector, useDispatch } from "react-redux";

import { setHotel as setHotelState } from "../../../redux/reducers/session/actions";
import { hotelRequests } from "../../../api/Api"

import { getFileId } from "../../hooks/useFileInput";
import { submitLocalizations } from "../../utils/localizations";


const useHotel = id => {
  const dispatch = useDispatch()
  const state = useSelector(({ sessionState }) => sessionState)
  const [hotel, setHotel] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(id) load(id).catch(() => null)
  }, [id])

  const load = async id => {
    setLoading(true)
    return loadHotel(id || hotel?.id)
      .then(([hotel]) => {
        setHotel(hotel)
        return hotel
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = () => {
    if (!hotel || isLoading) return;
    setLoading(true)
    return submitHotel(hotel)
      .then(async hotel => {
        const [hotels] = await hotelRequests.getList()
        dispatch(setHotelState(hotels.find(t => t.id === state.hotel.id) || hotels[0], hotels || []))
        return hotel
      })
      .finally(() =>
        setLoading(false)
      )
  }

  return {
    hotel,
    setHotel,
    load,
    update,
    isLoading
  }
}


export const loadHotel = async id => {
  try {
    return await hotelRequests.get(id)
  } catch (e) {
    const message = (e?.message || "Hotel loading failed")
    notify.error(message)
    throw new Error(message)
  }
}

export const submitHotel = async hotel => {
  const isNew = !hotel?.id

  const errMsg = `Hotel ${isNew ? 'creation' : 'modification'} failed`
  if (!hotel) throw new Error(errMsg)
  // try {

    const {
      id,
      // rooms,
      // identifier,
      // reservations,
      // availabilities,
      latitude,
      longitude,
      photos,
      logo,
      category,
      services,
      place,
      manager,
      min_range,
      services_photos,
      facilities,
      activities,
      localizations, // info, targetAudience
      ...props // publishedAt, name, stars, latitude, longitude, website, coupon, minAge, socialNetworks
    } = hotel

  console.log('{ latitude, longitude }', { latitude, longitude })
    const body = {
      latitude: latitude && parseFloat(latitude).toFixed(5),
      longitude: longitude && parseFloat(longitude).toFixed(5),
      category: category?.id || category,
      place: place?.id || place,
      min_range: min_range?.id || min_range,
      services_photos: services_photos && services_photos.map(sf => sf?.id || sf),
      services: services && services.map(s => s?.id || s),
      facilities: facilities && facilities.map(f => f?.id || f),
      activities: activities && activities.map(a => a?.id || a),
      photos: photos && await Promise.all(photos.map(p => getFileId(p))),
      logo: await getFileId(logo),
      ...props
    }

    const [res] = isNew ?
      await hotelRequests.create(body)
      :
      await hotelRequests.update(id, body)

    // Update or create every localization needed
    const defaultId = isNew ? res.id : id
    if(localizations) await submitLocalizations(localizations, defaultId, hotelRequests.update, hotelRequests.localize)

    notify.success(`Hotel ${isNew ? 'created' : 'modified'} correctly`)
    return res
  // } catch (e) {
  //   handleError(e?.message || errMsg)
  // }
}


export default useHotel;