import React, {useEffect, useMemo, useState} from "react";
import { Header } from "@general-backoffice/core";
import { Col, Container, Row } from "reactstrap";
import { StatsCard } from "@general-backoffice/core";

import useHotels from "../../../../components/hotels/hooks/useHotels";
import useUsers from "../../../../components/users/hooks/useUsers";
import usePagination from "../../../../components/hooks/usePagination";
import useSort from "../../../../components/hooks/useSort";

import HotelsReservationsTableCard from "../../../../components/hotels/HotelsReservationsTableCard";
import UsersTableCard from "../../../../components/users/UsersTableCard";


import { filtersPerStatus, filtersPerRole } from "../../../../components/users/utils/strapi4Params"
import {useNavigate} from "react-router";

const Home = () => {
  const { hotels } = useHotels({})
  const { users } = useUsers({ params: { 'filters[role][type][$eq]': 'authenticated' }})
  const { users: influencers } = useUsers({ params: {
    ...filtersPerRole['influencer'],
  }})
  const { users: verifiedInfluencers } = useUsers({ params: {
    ...filtersPerRole['influencer'],
    ...filtersPerStatus['verified']
  }})

  const publishedHotels = useMemo(() => !!hotels && hotels.filter(h => !!h.publishedAt).length, [hotels])
  const {
    hotels: reservationsHotels,
    isLoading: isLoadingReservationsHotels,

    // filters,
    // changeFilters,
    // goToPage,
    // changePageSize,
    // pagination,
    // sort,
    // changeSort
    ...hotelsProps
  } = useHotels({
    populate: 'logo,reservations',
    // filters: {
    //   'filters[reservations][id][$gt]': 0
    // },
    filters: true,
    sort: { name: 'down' },
    pagination: true,
  })

  // This is all for influencers
  const {
    sort,
    setSort,
    strapi4Params: sortParams
  } = useSort({ createdAt: 'down' })

  const baseFilters = {
    "populate[role]": true,
    "populate[country]": true,
    ...filtersPerStatus['pending'],
    ...filtersPerRole['influencer'],
    ...sortParams
  }

  const {
    users: influencersToValidate,
    isLoading,
    params,
    changeParams,
  } = useUsers({ params: baseFilters })
  useEffect(() => changeParams(baseFilters), [sortParams])


  const {
    pageItems: pageInfluencersToValidate,
    pagination,
    goToPage,
    setPageSize
  } = usePagination(influencersToValidate)


  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}>

        <Col xs={12} md={6} xl={4}>
          <StatsCard.Number
            color="primary"
            number={hotels?.length || 0}
            suffix={` hotel${hotels?.length > 1 ? 's' : ''}`}
            title="Hotels"
            icon="fas fa-h-square"
            description="Total hotels"
          />
        </Col>

        <Col xs={12} md={6} xl={4}>
          <StatsCard.Number
            color="default"
            number={verifiedInfluencers?.length || 0}
            suffix={` / ${!!influencers ? influencers?.length : 0} influencer${influencers?.length > 1 ? 's' : ''}`}
            title="Influencers"
            icon="fas fa-user-tie"
            description="Number of verified influencers"
          />
        </Col>

        <Col xs={12} md={6} xl={4}>
          <StatsCard.Number
            color="default"
            isDark={false}
            number={users?.length || 0}
            suffix={` user${users?.length > 1 ? 's' : ''}`}
            title="Users"
            icon="fas fa-user"
            description="Number of users"
          />
        </Col>

      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <HotelsReservationsTableCard
              {...hotelsProps}
              hotels={reservationsHotels}
              isLoading={isLoadingReservationsHotels}
              publishedHotels={publishedHotels}
            />
          </Col>
          <Col>
            <UsersTableCard
              {...{
                pagination,
                goToPage,
                setPageSize,
                sort,
                setSort,
                params,
                changeParams
              }}
              title='Influencers to validate'
              role="influencer"
              users={pageInfluencersToValidate}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Home;