import React from "react";
import { Button } from "reactstrap";


const StateButton = ({ color, onClick, num, text}) => {
  return (
    <Button
      color={color}
      onClick={e => {
        e.stopPropagation()
        onClick(e)
      }}
      style={{ minWidth: 105, gap: 5 }}
      className="ml-2 px-2 flex-center"
      disabled={!num}
    >
      <span className="text-capitalize" style={{fontSize: '.8rem'}}>{text}</span>
      <div
        className={`text-${color} bg-white rounded-circle flex-center`}
        style={{ height: '1.6rem', minWidth: '1.6rem'}}
      >{num || 0}</div>
    </Button>
  )
}

export default StateButton