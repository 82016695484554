import React, {useEffect, useMemo} from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

import { advantageRequests } from "../../../../api/Api";

import useAdvantages from "../../../../components/advantages/hooks/useAdvantages";
import useAdvantage from "../../../../components/advantages/hooks/useAdvantage";
import useStrapi4Pagination from "../../../../components/hooks/useStrapi4Pagination";
import useSort from "../../../../components/hooks/useSort";

// Components
import UpdateAdvantageModal from "../../../../components/advantages/UpdateAdvantageModal";
import DeleteAdvantageModal from "../../../../components/advantages/DeleteAdvantageModal";
import AdvantagesTableCard from "../../../../components/advantages/AdvantagesTableCard"



const Advantages = ({ hotel }) => {

  const {
    pagination,
    setPagination,
    goToPage,
    setPageSize,
    strapi4Params: paginationParams,
    resetPagination
  } = useStrapi4Pagination()

  const {
    sort,
    setSort,
    strapi4Params: sortParams,
    clearSort
  } = useSort()

  const defaultFilters = useMemo(() => ({
    populate: advantageRequests.defaultPopulate,
    ...(hotel && { 'filters[hotel][id][$eq]': hotel })
  }), [hotel])

  const baseFilters = { ...defaultFilters, ...paginationParams, ...sortParams }

  const {
    advantages,
    refresh: refreshAdvantages,
    isLoading: isLoadingAdvantages,
    params,
    changeParams,
    resetParams
  } = useAdvantages({ params: baseFilters, setPagination })

  useEffect(() => {
    // This is in case the component isn't rerendered but window has changed
    resetPagination()
    clearSort()
    resetParams()
  }, [hotel])

  useEffect(() => changeParams(baseFilters), [defaultFilters, paginationParams, sortParams])


  const {
    advantage: advantageToUpdate,
    setAdvantage: setAdvantageToUpdate,
    update: updateAdvantage,
    isLoading: isUpdatingAdvantage
  } = useAdvantage()

  const {
    advantage: advantageToDelete,
    setAdvantage: setAdvantageToDelete,
    remove: deleteAdvantage,
    isLoading: isDeletingAdvantage
  } = useAdvantage()

  return (
    <React.Fragment>

      <UpdateAdvantageModal
        advantageToUpdate={advantageToUpdate}
        setAdvantageToUpdate={setAdvantageToUpdate}
        close={() => setAdvantageToUpdate(null)}
        update={() => {
          return updateAdvantage()
            .then(async () => {
              await refreshAdvantages()
              setAdvantageToUpdate(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingAdvantages || isUpdatingAdvantage}
      />

      <DeleteAdvantageModal
        advantageToDelete={advantageToDelete}
        close={() => setAdvantageToDelete(null)}
        remove={() => {
          deleteAdvantage()
            .then(async () => {
              await refreshAdvantages()
              setAdvantageToDelete(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingAdvantages || isDeletingAdvantage}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs={12}>
            <AdvantagesTableCard
              {...{
                pagination,
                goToPage,
                setPageSize,
                sort,
                setSort,
                params,
                changeParams
              }}
              advantages={advantages}
              setAdvantageToUpdate={setAdvantageToUpdate}
              setAdvantageToDelete={setAdvantageToDelete}
              isLoading={isLoadingAdvantages}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Advantages;