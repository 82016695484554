import React from "react";
import moment from "moment/moment";

import logo from "../../media/branding/logo-dark-text.png";


const ReservationObligations = ({ obligations, reservation }) => {
  const {
    // id,
    check_in,
    check_out,
    user,
    hotel,
    // state,
    companions,
    children,
    nights,
    // number,
  } = reservation || {}

  const {
    stories = 0,
    reels = 0,
    posts = 0
  } = obligations

  return (
    <div id="obligationsPdfExport" className="text-default" style={{ backgroundColor: '#ebebeb', fontFamily: 'serif', letterSpacing: '.13rem', width: 1000}}>
      <div className="pt-5 px-3 flex-center justify-content-between">
        <img src={logo} className="w-25 mr-6" alt="" />
        <div style={{fontSize: '1.7rem'}}>
          <div className="text-uppercase">HOTEL: {hotel?.name}</div>
          <div className="my-3" style={{fontSize: '1.3rem', fontFamily: 'sans-serif'}}>
            DATES: {moment(check_in).format('DD/MM/YYYY')} - {moment(check_out).format('DD/MM/YYYY')}
          </div>
          <div>
            <i className="fab fa-instagram-square mr-2 text-dark" style={{fontSize: '2.4rem', verticalAlign: 'middle'}}/>
            @{(user?.socialNetworks?.[0]?.accountName || "")?.replace("@", "")}
          </div>
        </div>
      </div>
      <div className="p-5 mx-auto" style={{ maxWidth: 800}}>
        <h2 className="text-default text-center mb-3 text-uppercase">Collaboration conditions</h2>
        <p>
          For your stay of <b>{nights} {nights > 1 ? 'nights' : 'night'}</b> at <b>{hotel?.name} hotel</b>, you must publish:
        </p>
        <ul>
          <li>A total of <b>{stories} {stories > 1 ? 'stories' : 'story'} per night</b> of accommodation.</li>
          <li><b>{reels} Reel</b> of your experience at the hotel.</li>
          <li><b>Mandatory mentions</b>: @{hotel?.socialNetworks?.[0]?.accountName} (and hidden @trip2post) </li>
          <li>A <b>minimum of 2 stories</b> out of the total must include the <b>link</b> created for this collaboration and/or <b>discount code</b> for your followers and mention the advantages.</li>
          <li>You must <b>save all the content</b> (photos and videos) you generate in raw format: without mentioning, texts, links, or tags. You'll upload them attached to your reservation at the end of the trip.</li>
        </ul>
        <p>
          Once the collaboration is finished, you must attach the <b>statistics</b> of your content in the reservation within your profile.
        </p>

        <div className="flex-center align-items-end text-center">
          {!!companions && <>
            <div style={{minWidth: 100}}>
              <div style={{fontSize: '3.5rem'}}>{companions}</div>
              <small>Companions</small>
            </div>
            <div className="mx-4 mb-2" style={{borderLeft: '1px solid black', height: 70}}></div>
          </>}
          {!!children && <>
            <div style={{minWidth: 100}}>
              <div style={{fontSize: '3.5rem'}}>{children}</div>
              <small>Children</small>
            </div>
            <div className="mx-4 mb-2" style={{borderLeft: '1px solid black', height: 70}}></div>
          </>}
          <div>
            <div style={{fontSize: '3.5rem'}}>{(stories * nights) + reels + ((posts || 0) * nights)}</div>
            <small>Total content</small>
          </div>
          <div className="mx-4 mb-2" style={{borderLeft: '1px solid black', height: 70}}></div>
          <div>
            <div style={{fontSize: '2rem'}}>15 days</div>
            <small>Publication deadline</small>
          </div>
        </div>

        <h2 className="text-default text-center mt-5 mb-3">INDICATIONS:</h2>
        <p>
          The content that you must publish can be distributed within a maximum period of <b>15 DAYS</b> from the check in, it is not necessary to publish all the content during your stay, although a part of it, we recommend dividing it into 3 parts:
        </p>
        <ol>
          <li>When you receive the booking confirmation you can let your followers know that you have booked your vacation at {hotel?.name} hotel.</li>
          <li>During your stay</li>
          <li>And later a reminder of your code or booking link.</li>
        </ol>

        <h2 className="text-default text-center mt-5 mb-3">Rules:</h2>
        <div className="pl-6">
          <div>
            <i className="fas fa-check mr-2" style={{fontSize: '1.7rem', verticalAlign: 'middle', color: "#fad02c"}}/>
            Mention <span className="text-uppercase">@{hotel?.socialNetworks?.[0]?.accountName}</span> and @TRIP2POST
          </div>
          {hotel?.coupon?.code &&
            <div>
              <i className="fas fa-check mr-2" style={{fontSize: '1.7rem', verticalAlign: 'middle', color: "#fad02c"}}/>
              Share the LINK and PROMO CODE: {hotel?.coupon?.code} {hotel?.coupon?.discount}%
            </div>
          }
        </div>

        <h2 className="text-default text-center mt-5 mb-3">TIPS:</h2>
        <div>Interesting aspects to show and/or comment on:</div>
        <div>
          Experience in the different areas of the lodging, interesting characteristics of the hotel, Room Tour or Hotel
          Tour, Gastronomy (breakfast, meals, buffet, etc.), etc. Hotel, Room Tour or Hotel Tour, Gastronomy (breakfasts,
          meals, buffet, etc), services, facilities or activities carried out during the stay.
        </div>
      </div>

      <div
        className="text-white px-5 py-2 flex-center justify-content-between"
        style={{backgroundColor: "#fad02c", fontWeight: 100}}
      >
        <div style={{fontSize: '1.7rem'}}>CONTACT</div>
        <a href="tel:+34613517800" className="text-white" style={{fontSize: '1.5rem', letterSpacing: '.1rem'}}>
          +34 613 51 78 00
        </a>
        <a href="m.monserrat@trip2post.com" className="text-white" style={{fontSize: '1.2rem'}}>
          <i className="fas fa-envelope text-default mr-2" style={{fontSize: '1.7rem', verticalAlign: 'middle'}}/>
          m.monserrat@trip2post.com
        </a>
      </div>
    </div>
  )
}

export default ReservationObligations;