import React, { useMemo } from "react";
import { FormInput } from "@general-backoffice/core/index";

import { Icon } from "../utils/icons";

const usableIcons = [
  "airplane",
  "alarm",
  "bathtub",
  "bed",
  "bell",
  "bicycle",
  "briefcase",
  "building",
  "bus",
  "calendarAdd",
  "calendarCheck",
  "calendarDelete",
  "calendarRemove",
  "calendar",
  "car",
  "chair",
  "clock",
  "cloud",
  "cloudy",
  "cocktail",
  "coffee",
  "compass",
  "creditCard",
  "crown",
  "dashboard",
  "directionAlt",
  "direction",
  "direction1",
  "drawer",
  "factory",
  "fork&knife",
  "gift",
  "globe",
  "handbag",
  "hanger",
  "hook",
  "house",
  "map",
  "moon",
  "pin1",
  "pin2",
  "pin3",
  "rain",
  "safe",
  "sandglass",
  "ship",
  "shoppingBasket",
  "shoppingCart",
  "shovel",
  "shower",
  "soapDispenser",
  "sofa",
  "store",
  "streetSign",
  "suitcase",
  "sun",
  "timer",
  "towel",
  "train",
  "wallet",
  "world"
]

const IconSelect = ({ icon, setIcon, isLoading, ...props }) => {
  const options = useMemo(() =>
    (usableIcons || []).map(iconName => ({
      value: iconName,
      label: <Icon name={iconName} text={iconName} className="mr-2" style={{height: 15}}/>
    }))
  , [])

  return (
    <FormInput.Select
      label='Icon'
      {...props}
      value={icon && options.find(({value}) => value === icon)}
      onChange={({value}) => setIcon(value || null)}
      options={options}
      isDisabled={isLoading || props.isDisabled}
    />
  )
}

export default IconSelect;